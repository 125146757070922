import React from 'react';

// CheckInEducation.js
const CheckInEducation = ({ step }) => {
    return (
        <div className="space-y-4">
            <h2 className="text-xl font-bold text-center text-gray-700">Check-Ins Are Cool! </h2>
        </div>
    );
};

export default CheckInEducation;
