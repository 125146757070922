import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, updateProfile } from "firebase/auth";
import { useAuthContext } from '../components/hooks/useAuthContext';

function FinalizeSignup() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();

  useEffect(() => {
    const completeSignUp = async () => {
      console.log("FinalizeSignup useEffect triggered");

      if (isSignInWithEmailLink(auth, window.location.href)) {
        console.log("Valid sign-up link detected");
        let email = window.localStorage.getItem('emailForSignIn');
        let displayName = window.localStorage.getItem('displayName');
        if (!email) {
          console.log("Email not found in localStorage, prompting user");
          email = window.prompt('Please provide your email for confirmation');
        }

        try {
          console.log("Attempting to sign up with email link");
          const result = await signInWithEmailLink(auth, email, window.location.href);
          console.log("Sign-up successful", result);
          
          // Update user profile with display name
          if (displayName) {
            await updateProfile(result.user, { displayName });
          }
          
          window.localStorage.removeItem('emailForSignIn');
          window.localStorage.removeItem('displayName');
          dispatch({ type: 'LOGIN', payload: result.user });
          navigate('/home');
        } catch (error) {
          console.error("Error signing up with email link", error);
          setError(error.message);
          setTimeout(() => navigate('/signup'), 10000);
        }
      } else {
        console.log("No valid sign-up link detected, redirecting to signup");
        navigate('/signup');
      }
    };

    completeSignUp();
  }, [auth, navigate, dispatch]);

  if (error) {
    return <div>Error: {error}. Redirecting to signup page...</div>;
  }

  return <div>Finalizing your signup...</div>;
}

export default FinalizeSignup;