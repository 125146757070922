import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, PlusIcon, LinkIcon } from '@heroicons/react/20/solid';

const EntryCard = ({ isCompleted, onEntryCompletion }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!isCompleted) {
      onEntryCompletion();
    }
    navigate('/new-entry');
  };

  return (
    <div
      className="bg-white border rounded-lg shadow-sm p-4 flex items-center justify-between cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex items-center">
        <button
          className={`p-2 rounded-full border-2 ${isCompleted ? 'bg-purple-500 border-purple-500 text-white' : 'bg-white border-purple-500 text-purple-500'}`}
        >
          {isCompleted ? (
            <CheckIcon className="h-5 w-5 text-white" />
          ) : (
            <PlusIcon className="h-5 w-5 text-purple-500" />
          )}
        </button>
        <div className="ml-4">
          <h2 className="text-lg font-semibold">
            {isCompleted ? "Well Done! You took one step forward." : "Add a journal entry"}
          </h2>
          <p className="text-sm text-gray-500">
            {isCompleted ? "See you tomorrow!" : "Track your wellbeing. Find what works for you."}
          </p>
        </div>
      </div>
      <LinkIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={handleClick} />
    </div>
  );
};

export default EntryCard;
