import { useState, useEffect, useCallback } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';

const useFetchUserEntries = (userId) => {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const manipulateDate = (timestamp) => {
    if (!timestamp) {
      return null;
    }
    if (timestamp instanceof Date) {
      return timestamp;
    }
    const unixTimestamp = (timestamp.seconds + timestamp.nanoseconds * 10**-9) * 1000;
    return new Date(unixTimestamp);
  };

  const fetchEntries = useCallback(async () => {
    setLoading(true);
    try {
      if (!userId) {
        throw new Error("No user ID provided");
      }
      const q = query(collection(db, `users/${userId}/entries`));
      const querySnapshot = await getDocs(q);
      const fetchedEntries = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          setAt: manipulateDate(data.setAt),
          createdAt: manipulateDate(data.createdAt),
          updatedAt: manipulateDate(data.updatedAt),
        };
      });
      setEntries(fetchedEntries);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching entries:", err.message);
      setError(err);
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchEntries();
  }, [fetchEntries]);

  const refetchEntries = useCallback(() => {
    return fetchEntries();
  }, [fetchEntries]);

  return { entries, loading, error, refetchEntries };
};

export default useFetchUserEntries;