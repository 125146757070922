import React, { useState, useEffect } from 'react';
import { CheckIcon, LinkIcon } from '@heroicons/react/20/solid';
import Confetti from 'react-confetti';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuthContext } from '../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';

const QuestCard = ({ quest, dayIndex, onQuestCompletion }) => {
  const { user } = useAuthContext();
  const [showConfetti, setShowConfetti] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (quest.schedule && quest.schedule[dayIndex]) {
      setIsCompleted(quest.schedule[dayIndex].completed);
    } else {
      console.error('Invalid dayIndex or schedule is not available:', dayIndex, quest.schedule);
    }
  }, [quest, dayIndex]);

  const handleCompletionToggle = async () => {
    if (!quest.schedule[dayIndex]) {
      console.error('No quest schedule entry found for index:', dayIndex);
      return;
    }

    setLoading(true);
    const newSchedule = [...quest.schedule];
    const currentStatus = newSchedule[dayIndex].completed;
    newSchedule[dayIndex] = { ...newSchedule[dayIndex], completed: !currentStatus };

    await updateDoc(doc(db, `users/${quest.userId}/quests`, quest.id), {
      schedule: newSchedule
    });

    setIsCompleted(!currentStatus);

    if (!currentStatus) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
    }

    setLoading(false);
    onQuestCompletion();
  };

  const handleNavigateToQuest = () => {
    navigate('/quests');
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-2 flex items-center justify-between w-full">
      <div className="flex items-center">
        <button
          onClick={handleCompletionToggle}
          className={`p-2 rounded-full border-2 ${isCompleted ? 'bg-purple-500 border-purple-500 text-white' : 'bg-white border-purple-500 text-purple-500'}`}
          disabled={loading}
        >
          <CheckIcon className={`h-5 w-5 ${isCompleted ? 'text-white' : ''}`} />
        </button>
        {showConfetti && <Confetti />}
        <div className="ml-4">
          <h2 className="text-lg font-semibold cursor-pointer" onClick={handleNavigateToQuest}>
            Did you {quest.modification} {quest.something.value}?
          </h2>
          <p className="text-sm text-gray-500">{quest.change}</p>
        </div>
      </div>
      <LinkIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={handleNavigateToQuest} />
    </div>
  );
};

export default QuestCard;
