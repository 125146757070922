import React from 'react';
import SkinActive from '../images/icons/Skin_Active';
import SkinInactive from '../images/icons/Skin_Inactive';
import MouthThroatActive from '../images/icons/MouthThroat_Active';
import MouthThroatInactive from '../images/icons/MouthThroat_Inactive';
import HeartActive from '../images/icons/Heart_Active';
import HeartInactive from '../images/icons/Heart_Inactive';
import GutActive from '../images/icons/Gut_Active';
import GutInactive from '../images/icons/Gut_Inactive';
import BrainActive from '../images/icons/Brain_Active';
import BrainInactive from '../images/icons/Brain_Inactive';
import AirwaysActive from '../images/icons/Airways_Active';
import AirwaysInactive from '../images/icons/Airways_Inactive';
import Medications from '../images/icons/Medications';
import Food from '../images/icons/Food';
import Activities from '../images/icons/Activities';
import Moods from '../images/icons/Moods';
import Quest_Active from '../images/icons/Quest_Active';


const iconSizeClasses = {
  small: "w-4 h-4",
  medium: "w-6 h-6",
  large: "w-8 h-8"
};

const SystemIcon = ({ type, system, isActive, size = 'medium' }) => {
    const IconComponent = (() => {
        if (system) {
            switch(system) {
                case 'Skin': return isActive ? SkinActive : SkinInactive;
                case 'Mouth/Throat': return isActive ? MouthThroatActive : MouthThroatInactive;
                case 'Heart': return isActive ? HeartActive : HeartInactive;
                case 'Gut': return isActive ? GutActive : GutInactive;
                case 'Brain': return isActive ? BrainActive : BrainInactive;
                case 'Airways': return isActive ? AirwaysActive : AirwaysInactive;
                default: return SkinInactive;
            }
        } else {
            switch(type) {
                case 'medications': return Medications;
                case 'foods': return Food;
                case 'activities': return Activities;
                case 'moods': return Moods;
                case 'quest': return Quest_Active; // Add quest icon case
                default: return null;
            }
        }
    })();

    const iconClass = iconSizeClasses[size] || iconSizeClasses.medium;

    return IconComponent ? <IconComponent className={`${iconClass} text-gray-500`} /> : null;
};

export default SystemIcon;
