import { useState } from 'react';
import { useRecoverPassword } from '../../components/hooks/useRecoverPassword';

export default function RecoverPassword() {
    const [email, setEmail] = useState('');
    const { error, isPending, message, recoverPassword } = useRecoverPassword();

    const handleSubmit = (e) => {
        e.preventDefault();
        recoverPassword(email);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
            <form onSubmit={handleSubmit} className="max-w-md w-full space-y-8 bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-center text-3xl font-extrabold text-gray-900">Recover Password</h2>
                {message && <p className="text-green-500">{message}</p>}
                {error && <p className="text-red-500">{error}</p>}
                <div className="mt-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                    <input
                        id="email"
                        type="email"
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    disabled={isPending}
                >
                    {isPending ? 'Loading...' : 'Recover Password'}
                </button>
            </form>
        </div>
    );
}
