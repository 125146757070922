// src/contexts/ModalContext.js
import React, { createContext, useState, useContext } from 'react';

export const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [selectedEntry, setSelectedEntry] = useState(null);

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
        setSelectedEntry(null); // Reset selected entry when closing modal
    };

    const onAddEntry = () => {
        openModal('addEntry'); // Assuming 'addEntry' is the modal content for adding entry
    };

    const onViewEntry = (entryId) => {
        setSelectedEntry(entryId);
        openModal('viewEntry'); // Assuming 'viewEntry' is the modal content for viewing entry
    };

    return (
        <ModalContext.Provider value={{ isModalOpen, modalContent, selectedEntry, openModal, closeModal, onAddEntry, onViewEntry }}>
            {children}
        </ModalContext.Provider>
    );
};
