import { useState } from 'react';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DataSharingConsent = ({ onUpdate }) => {
    const [isAgreed, setIsAgreed] = useState(true); // Default to true

    const handleAgreementChange = (agreed) => {
        setIsAgreed(agreed);
        onUpdate({ dataSharingConsent: agreed });  // Update the user data in the onboarding state
    };

    return (
        <div className="flex flex-col items-center justify-center p-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Share your Insights</h2>
            <p className="text-sm text-center text-gray-600 mb-6">
                {isAgreed ? 
                  "Thank you for contributing! Sharing your anonymized data will help us better understand where reactions come from and why." :
                  "Would you like to help us enhance our research and services by sharing your anonymized data?"
                }
            </p>
            <Switch.Group as="div" className="flex items-center">
                <Switch
                    checked={isAgreed}
                    onChange={handleAgreementChange}
                    className={classNames(
                        isAgreed ? 'bg-green-500' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
                    )}
                >
                    <span
                        aria-hidden="true"
                        className={classNames(
                            isAgreed ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                    />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                    <span className="font-medium text-gray-900">Opt-in to share anonymized data</span>
                </Switch.Label>
            </Switch.Group>
        </div>
    );
};

export default DataSharingConsent;
