import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { collection, addDoc, deleteDoc, query, doc, getDocs, Timestamp } from 'firebase/firestore';
import { useAuthContext } from '../hooks/useAuthContext';
import { useNavigate, useParams } from 'react-router-dom';

function ManageValues() {
    const { user } = useAuthContext();
    const [values, setValues] = useState([]);
    const [newValue, setNewValue] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { type } = useParams();

    const fetchValues = async () => {
        if (!type) {
            console.error('Type parameter not found in URL');
            return;
        }
        try {
            const q = query(collection(db, `users/${user.uid}/${type}`));
            const querySnapshot = await getDocs(q);
            const fetchedValues = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            console.log('Fetched Values:', fetchedValues); // Log fetched values
            setValues(fetchedValues);
        } catch (error) {
            console.error('Error fetching values:', error);
        }
    };

    useEffect(() => {
        if (user) {
            fetchValues();
        }
    }, [user, type]);

    const handleAddValue = async () => {
        if (newValue.trim() === "") {
            setError("Value cannot be blank.");
            return;
        }

        if (newValue.trim().length > 20) {
            setError("Value cannot be more than 20 characters.");
            return;
        }

        if (values.some(value => value.value.toLowerCase() === newValue.trim().toLowerCase())) {
            setError("Value already exists.");
            return;
        }

        if (!type) {
            console.error('Type parameter not found in URL');
            return;
        }

        try {
            await addDoc(collection(db, `users/${user.uid}/${type}`), { value: newValue.trim(), createdAt: Timestamp.now() });
            setNewValue("");
            setError(null); // Clear any previous error messages
            fetchValues();
        } catch (error) {
            console.error('Error adding value:', error);
            setError('An error occurred while adding the value.');
        }
    };

    const handleDeleteValue = async (id) => {
        if (!type) {
            console.error('Type parameter not found in URL');
            return;
        }
        try {
            await deleteDoc(doc(db, `users/${user.uid}/${type}`, id));
            fetchValues();
        } catch (error) {
            console.error('Error deleting value:', error);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="p-4">
            <button onClick={goBack} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Back
            </button>
            <input
                type="text"
                value={newValue}
                onChange={(e) => {
                    setNewValue(e.target.value);
                    setError(null); // Clear error message when user starts typing
                }}
                placeholder={`Add new ${type}`}
                className="w-full p-2 text-sm border rounded-md focus:ring-blue-500 focus:border-blue-500 mt-4"
            />
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            <button
                onClick={handleAddValue}
                className="mt-2 w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
                Add {type}
            </button>
            <ul className="mt-4 space-y-2">
                {values.length > 0 ? values.map(value => (
                    <li key={value.id} className="flex justify-between items-center bg-white p-2 rounded shadow">
                        <span className="text-gray-800">{value.value}</span>
                        <button
                            onClick={() => handleDeleteValue(value.id)}
                            className="text-red-500 hover:text-red-700"
                        >
                            Delete
                        </button>
                    </li>
                )) : (
                    <p className="text-center text-gray-500">No {type} added yet.</p>
                )}
            </ul>
        </div>
    );
}

export default ManageValues;
