import React, { useState } from 'react';
import ButtonSelection from './ButtonSelection';  // Ensure this is the correct import path

const SymptomSelection = ({ onUpdate }) => {
    const categories = [
        { name: "Mouth/Throat Symptoms", description: "Difficulty swallowing, gagging, hives, rash, hoarse voice, itchy/scratchy/tingly throat, numb mouth, swelling of mouth/tongue/lips, throat tightness." },
        { name: "Skin Symptoms", description: "Flushing, hives or urticaria, itching, rash, red/itchy/irritated or watery eyes, skin swelling, sweating." },
        { name: "Gut Symptoms", description: "Bloating, bloody stools, constipation, diarrhea, nausea, odd taste, reflux, cramps, vomiting." },
        { name: "Airways Symptoms", description: "Chest tightness, coughing, congestion, runny nose, sneezing, throat tightness, trouble breathing, wheezing." },
        { name: "Heart Symptoms", description: "Cardiac arrest, chest pain, irregular heart rate, dizziness, loss of consciousness, low blood pressure, pale/blue coloring, rapid heartbeat, slow heartbeat, weak pulse." },
        { name: "Brain Symptoms", description: "Anxiety, headache, irritability, sadness." }
        // { name: "None", description: "" }
    ];

    // Function to update parent component or handle side effects
    const handleUpdate = (data) => {
        onUpdate(data);
    };

    return (
        <div className="container mx-auto px-4">
            <h2 className="text-center font-bold text-lg mb-4">Select any symptom categories you've experienced</h2>
            <ButtonSelection options={categories} onUpdate={handleUpdate} />
        </div>
    );
};

export default SymptomSelection;
