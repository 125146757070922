import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useAuthContext } from '../components/hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import SymptomScatterPlot from '../components/explore/SymptomScatterPlot';
import SymptomHistogram from '../components/explore/SymptomHistogram';
import useFetchUserEntries from '../components/hooks/useFetchUserEntries';
import CombinedFilter from '../components/explore/CombinedFilter';
import Trends from '../components/explore/Trends';
import useScrollDirection from '../components/hooks/useScrollDirection';
import UASScoreChart from '../components/explore/UASScoreChart';

const Explore = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const { entries, loading, error } = useFetchUserEntries(user ? user.uid : null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const containerRef = useRef(null);

    const pageScrollDirection = useScrollDirection();
    const containerScrollDirection = useScrollDirection(containerRef);

    const combinedScrollDirection = containerScrollDirection === 'down' || pageScrollDirection === 'down' ? 'down' : 'up';

    useEffect(() => {
        if (!user) navigate('/login');
    }, [user, navigate]);

    useEffect(() => {
        const fetchPersistedValues = async () => {
            if (user) {
                const docRef = doc(db, 'users', user.uid, 'preferences', 'scatterPlotValues');
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setSelectedOptions(docSnap.data().values || []);
                }
            }
        };
        fetchPersistedValues();
    }, [user]);

    const filteredEntries = useMemo(() => {
        if (!entries || !Array.isArray(entries) || entries.length === 0) return [];
        
        const now = new Date();
        const startTime = now.getTime() - (30 * 24 * 60 * 60 * 1000); // 30 days

        return entries.filter(entry => {
            if (!entry || !entry.setAt || entry.type === 'environmental') return false;
            const entryTime = new Date(entry.setAt).getTime();
            return entryTime >= startTime && entryTime <= now.getTime();
        });
    }, [entries]);

    const trendData = useMemo(() => {
        const data = {};
        filteredEntries.forEach(entry => {
            if (!entry.values || !Array.isArray(entry.values)) return;
            entry.values.forEach(value => {
                if (!value || !value.value) return;
                if (!data[value.value]) {
                    data[value.value] = {};
                }
                const date = entry.setAt.toISOString().split('T')[0];
                if (!data[value.value][date]) {
                    data[value.value][date] = [];
                }
                data[value.value][date].push({
                    setAt: entry.setAt,
                    amount: value.amount || 0
                });
            });
        });
        return data;
    }, [filteredEntries]);

    const visibleOptions = useMemo(() => {
        const optionsSet = new Set([...Object.keys(trendData), ...selectedOptions.map(option => option.value)]);
        return Array.from(optionsSet).map(value => ({
            value,
            label: value,
            group: 'trend'
        }));
    }, [trendData, selectedOptions]);

    const handleSelectedOptionsChange = async (newOptions) => {
        setSelectedOptions(newOptions);
        if (user) {
            const docRef = doc(db, 'users', user.uid, 'preferences', 'scatterPlotValues');
            await setDoc(docRef, { values: newOptions });
        }
    };

    const handleAddFilter = (newOption) => {
        setSelectedOptions(prevOptions => {
            if (prevOptions.some(option => option.value === newOption.value)) {
                return prevOptions;
            }
            return [...prevOptions, newOption];
        });
    };

    if (loading) return <div className="flex justify-center items-center h-screen">Loading...</div>;
    if (error) return <div className="text-center text-red-500 mt-8">Error: {error.message}</div>;

    return (
        <div className="min-h-screen bg-gray-50">
            <div className="hidden sm:block">
                <Navbar />
            </div>
            <div ref={containerRef} className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 pb-24 overflow-auto">
                <h1 className="text-3xl font-bold text-gray-900 mb-8">Explore Your Data</h1>
                <Trends entries={filteredEntries} onAddFilter={handleAddFilter} />
                <div className="p-4 mb-6 bg-white shadow rounded-lg">
                    <h2 className="text-xl font-semibold mb-4">Custom Chart</h2>
                    <div className="mb-6">
                        <CombinedFilter 
                            trendData={trendData}
                            visibleOptions={visibleOptions}
                            selectedOptions={selectedOptions}
                            onChange={handleSelectedOptionsChange}
                        />
                    </div>
                    {filteredEntries.length > 0 ? (
                        <div className="space-y-8">
                            <div className="bg-white shadow rounded-lg">
                                <SymptomScatterPlot 
                                    trendData={trendData}
                                    selectedValues={selectedOptions.map(option => option.value)}
                                />
                            </div>
                            <div className="bg-white shadow rounded-lg">
                                <h2 className="text-xl font-semibold mb-4">Symptom Count</h2>
                                <SymptomHistogram entries={filteredEntries} />
                            </div>
                            <div className="bg-white shadow rounded-lg">
                                <UASScoreChart entries={filteredEntries} />
                            </div>
                        </div>
                    ) : (
                        <div className="text-center py-12 bg-white shadow rounded-lg">
                            <p className="text-xl text-gray-600 mb-4">No data available for the selected filters.</p>
                        </div>
                    )}
                </div>
            </div>
            <MobileNav scrollDirection={combinedScrollDirection} />
        </div>
    );
};

export default Explore;