import { auth } from "../../firebase/config"
import { signOut } from "firebase/auth"
import { useAuthContext } from "./useAuthContext"
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
    // console.log(useAuthContext)
    const { dispatch } = useAuthContext()
    const navigate = useNavigate();
    const logout = () => {
        signOut(auth)
            .then((res) => {
                dispatch({type: 'LOGOUT'})
                console.log('user signed out')
                navigate('/')
            })
            .catch((err) => {
                console.log(err.message)
            })
    }
    return { logout }
}