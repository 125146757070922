import React from 'react';
import { CalendarIcon, ClockIcon, SparklesIcon, CheckCircleIcon, ArchiveBoxIcon } from '@heroicons/react/24/outline';

const QuestCard = ({ quest, onClick, entries }) => {
  if (!quest) {
    console.error('Quest data is undefined');
    return null;
  }

  const getProgressColor = (progress) => {
    if (progress > 66) return 'bg-red-500';
    if (progress > 33) return 'bg-yellow-500';
    return 'bg-green-500';
  };

  const calculateDates = () => {
    if (!quest.createdAt || !quest.duration) return { startDate: 'Unknown', endDate: 'Unknown' };
    const startDate = new Date(quest.createdAt);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + quest.duration.value * (quest.duration.unit === 'week' ? 7 : 1));
    return {
      startDate: startDate.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' }),
      endDate: endDate.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })
    };
  };

  const { startDate, endDate } = calculateDates();

  const calculateProgress = () => {
    if (!quest.createdAt || !quest.duration) return 0;
    const startDate = new Date(quest.createdAt);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + quest.duration.value * (quest.duration.unit === 'week' ? 7 : 1));
    const totalDuration = endDate.getTime() - startDate.getTime();
    const elapsed = Date.now() - startDate.getTime();
    return Math.max(0, Math.min(100, (elapsed / totalDuration) * 100));
  };

  const progress = calculateProgress();

  const getEntriesCount = () => {
    if (!entries || !quest.createdAt) return 0;
    
    const questStartDate = new Date(quest.createdAt);
    const relevantEntries = entries.filter(entry => new Date(entry.date) >= questStartDate);
    return relevantEntries.length;
  };

  const totalEntries = getEntriesCount();

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden border-2 border-purple-500" onClick={onClick}>
      <div className="bg-gray-100 p-4">
        <h3 className="text-lg font-bold text-gray-800 mb-1">
          {quest.selectedSymptoms.join(', ') || 'No symptoms selected'}
        </h3>
        <p className="text-sm text-gray-600">
          {quest.selectedSymptoms.length} symptom{quest.selectedSymptoms.length !== 1 ? 's' : ''} • {totalEntries} total entries
        </p>
      </div>
      <div className="p-4">
        <div className="flex items-center mb-3">
          <SparklesIcon className="h-5 w-5 text-purple-500 mr-2" />
          <span className="text-gray-700 font-medium">{quest.goal} {quest.something?.value || ''}</span>
        </div>
        <div className="flex items-center mb-3">
          <CalendarIcon className="h-5 w-5 text-gray-400 mr-2" />
          <span className="text-sm text-gray-600">{startDate} - {endDate}</span>
        </div>
        <div className="flex items-center mb-4">
          <ClockIcon className="h-5 w-5 text-gray-400 mr-2" />
          <span className="text-sm text-gray-600">
            {quest.duration.value} {quest.duration.unit}{quest.duration.value > 1 ? 's' : ''}
          </span>
        </div>
        <div className="mb-2">
          <div className="flex justify-between mb-1">
            <span className="text-xs font-semibold inline-block text-purple-600">
              Progress
            </span>
            <span className="text-xs font-semibold inline-block text-purple-600">
              {Math.round(progress)}%
            </span>
          </div>
          <div className="bg-gray-200 rounded-full h-2 mb-4">
            <div className={`rounded-full h-2 ${getProgressColor(progress)}`} style={{ width: `${progress}%` }}></div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div>
            <span className="text-xs text-gray-500 uppercase">System</span>
            <p className="text-sm font-medium text-gray-700">{quest.selectedSystem}</p>
          </div>
          <div>
            <span className="text-xs text-gray-500 uppercase">Modification</span>
            <p className="text-sm font-medium text-gray-700">
              {quest.modification || 'None'} {quest.something?.value || ''}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestCard;