import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle, Star, Award, X, Trophy } from 'lucide-react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

const QuestCompletionModal = ({ isOpen, onClose, quests, entries }) => {
  const [completedQuests, setCompletedQuests] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);

  const handleClose = () => {
    setShowConfetti(false);
    onClose(); // This will now trigger the parent's handleModalClose function
  };


  useEffect(() => {
    if (isOpen) {
      console.log('Modal opened. Processing quests and entries:');
      console.log('Quests:', quests);
      console.log('Entries:', entries);

      const completed = quests.filter(quest => {
        if (!quest || !quest.active) {
          console.log(`Quest ${quest?.id || 'unknown'} is not active or is null. Skipping.`);
          return false;
        }

        console.log(`Processing quest:`, quest);

        const relevantEntries = entries.filter(entry => entry.type === quest.something.type);
        console.log(`Relevant entries for ${quest.something.type}:`, relevantEntries);

        let isCompleted = false;

        switch (quest.modification) {
          case 'Add':
            isCompleted = relevantEntries.some(entry => {
              const hasValue = entry.values && entry.values.some(v => 
                typeof v === 'object' ? v.value === quest.something.value : v === quest.something.value
              );
              console.log(`Checking if entry contains ${quest.something.value}:`, hasValue);
              return hasValue;
            });
            console.log(`'Add' quest completed:`, isCompleted);
            break;
          case 'Remove':
            isCompleted = !relevantEntries.some(entry => {
              const hasValue = entry.values && entry.values.some(v => 
                typeof v === 'object' ? v.value === quest.something.value : v === quest.something.value
              );
              console.log(`Checking if entry does not contain ${quest.something.value}:`, !hasValue);
              return hasValue;
            });
            console.log(`'Remove' quest completed:`, isCompleted);
            break;
          case 'None':
            isCompleted = entries.length > 0;
            console.log(`'None' quest completed:`, isCompleted);
            break;
          default:
            console.log(`Unknown modification type: ${quest.modification}`);
            isCompleted = false;
        }

        console.log(`Quest ${quest.id} completion status:`, isCompleted);
        return isCompleted;
      });

      // Group "Identify my triggers" quests
      const groupedQuests = completed.reduce((acc, quest) => {
        if (quest.goal === 'Identify my triggers') {
          if (!acc.identifyTriggers) {
            acc.identifyTriggers = { ...quest, count: 1 };
          } else {
            acc.identifyTriggers.count += 1;
          }
        } else {
          acc.others.push(quest);
        }
        return acc;
      }, { identifyTriggers: null, others: [] });

      const finalCompletedQuests = [
        ...(groupedQuests.identifyTriggers ? [groupedQuests.identifyTriggers] : []),
        ...groupedQuests.others
      ];

      console.log('Grouped completed quests:', finalCompletedQuests);

      setCompletedQuests(finalCompletedQuests);
      if (finalCompletedQuests.length > 0) {
        setShowConfetti(true);
      }
    }
  }, [isOpen, quests, entries]);

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } }
  };

  const questVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const getQuestDescription = (quest) => {
    switch (quest.modification) {
      case 'Add':
        return `Added ${quest.something.value} in ${quest.something.type}`;
      case 'Remove':
        return `Avoided ${quest.something.value} in ${quest.something.type}`;
      case 'None':
        return 'Made an entry';
      default:
        return 'Completed';
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {showConfetti && <Confetti recycle={false} numberOfPieces={200} />}
          <motion.div
            className="bg-white rounded-lg shadow-xl w-full max-w-md overflow-hidden"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="p-6 bg-gradient-to-br from-purple-600 to-indigo-600 text-white">
              <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold">Quest Progress</h2>
                <button onClick={handleClose} className="text-white hover:text-gray-200">
                  <X size={24} />
                </button>
              </div>
              <p className="mt-2">Great job on your quests today!</p>
            </div>
            <div className="p-6 max-h-[60vh] overflow-y-auto">
              {completedQuests.length > 0 ? (
                <ul className="space-y-4">
                  {completedQuests.map((quest, index) => (
                    <motion.li
                      key={quest.id || index}
                      className="flex items-center bg-gray-100 p-3 rounded-lg"
                      variants={questVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ delay: index * 0.1 }}
                    >
                      <Trophy className="text-yellow-500 mr-3" size={24} />
                      <div className="flex-grow">
                        <h3 className="font-semibold">{quest.goal || 'Quest Completed!'}</h3>
                        <p className="text-sm text-gray-600">
                          {getQuestDescription(quest)}
                        </p>
                      </div>
                      {quest.count > 1 && (
                        <span className="text-purple-600 font-bold text-lg">
                          x{quest.count}
                        </span>
                      )}
                    </motion.li>
                  ))}
                </ul>
              ) : (
                <div className="text-center py-8">
                  <Star className="mx-auto text-yellow-400 mb-4" size={48} />
                  <p className="text-gray-600">Keep going! You're making progress on your quests.</p>
                </div>
              )}
            </div>
            <div className="p-6 bg-gray-100 flex justify-between items-center">
              <div className="flex items-center">
                <CheckCircle className="text-green-500 mr-2" size={24} />
                <span className="font-semibold">Quests Activities: {completedQuests.reduce((sum, quest) => sum + (quest.count || 1), 0)}</span>
              </div>
              <Award className="text-purple-600" size={24} />
            </div>
            <button
              onClick={handleClose}
              className="w-full py-4 bg-purple-600 text-white font-semibold hover:bg-purple-700 transition-colors"
            >
              Continue Your Journey
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default QuestCompletionModal;