import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { startOfDay, endOfDay, eachDayOfInterval, format } from 'date-fns';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

const calculateScore = (value) => {
  if (value < 2) return 1;
  if (value < 4) return 2;
  return 3;
};

const getScoreDescription = (score, type) => {
  if (type === 'Hives') {
    if (score === 1) return 'Mild: <20 wheals/24 hrs';
    if (score === 2) return 'Moderate: 20-50 wheals/24 hrs';
    return 'Intense: >50 wheals/24 hrs or large confluent areas';
  } else {
    if (score === 1) return 'Mild: Present but not annoying or troublesome';
    if (score === 2) return 'Moderate: Troublesome but does not interfere with normal daily activity or sleep';
    return 'Intense: Severe, interferes with normal daily activity or sleep';
  }
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const hivesScore = payload[0].value;
    const itchingScore = payload[1].value;
    return (
      <div className="custom-tooltip bg-white p-3 border border-gray-300 rounded shadow">
        <p className="label font-semibold">{`Date: ${label}`}</p>
        <p className="text-sm">{`Hives Score: ${hivesScore}`}</p>
        <p className="text-xs text-gray-600">{getScoreDescription(hivesScore, 'Hives')}</p>
        <p className="text-sm mt-2">{`Itching Score: ${itchingScore}`}</p>
        <p className="text-xs text-gray-600">{getScoreDescription(itchingScore, 'Itching')}</p>
        <p className="text-sm font-semibold mt-2">{`Total UAS: ${hivesScore + itchingScore}`}</p>
      </div>
    );
  }
  return null;
};

const LearnMoreTooltip = () => (
  <div className="bg-white p-3 border border-gray-300 rounded shadow max-w-xs">
    <h3 className="font-semibold mb-2">About UAS Score</h3>
    <p className="text-sm mb-2">UAS measures the severity of urticaria (hives) based on two components:</p>
    <ul className="list-disc pl-4 text-sm">
      <li>Hives: Number and size of wheals</li>
      <li>Itching: Intensity of pruritus</li>
    </ul>
    <p className="text-sm mt-2">Each component is scored 0-3, with a daily maximum of 6.</p>
    <p className="text-sm mt-2">UAS7 is the sum of daily UAS scores over 7 days. A score ≥11 indicates significant disease activity.</p>
  </div>
);

const UASScoreChart = ({ entries }) => {
  const [showLearnMore, setShowLearnMore] = useState(false);

  const uasData = useMemo(() => {
    const now = new Date();
    const startDate = startOfDay(new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)); // 7 days ago
    const endDate = endOfDay(now);

    const dateRange = eachDayOfInterval({ start: startDate, end: endDate });

    return dateRange.map(date => {
      const dayStart = startOfDay(date);
      const dayEnd = endOfDay(date);

      const dayEntries = entries.filter(entry => 
        entry.type === 'symptom' && 
        entry.setAt >= dayStart && 
        entry.setAt <= dayEnd
      );

      const hivesEntry = dayEntries.find(entry => 
        entry.values.some(v => v.value === 'Hives')
      );
      const itchingEntry = dayEntries.find(entry => 
        entry.values.some(v => v.value === 'Itching')
      );

      const hivesScore = hivesEntry 
        ? calculateScore(hivesEntry.values.find(v => v.value === 'Hives').amount)
        : 0;
      const itchingScore = itchingEntry
        ? calculateScore(itchingEntry.values.find(v => v.value === 'Itching').amount)
        : 0;

      return {
        date: format(date, 'MMM dd'),
        Hives: hivesScore,
        Itching: itchingScore,
        Total: hivesScore + itchingScore,
      };
    });
  }, [entries]);

  const uas7Score = useMemo(() => {
    return uasData.reduce((sum, day) => sum + day.Total, 0);
  }, [uasData]);

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h2 className="text-xl font-semibold">Urticaria Activity Score (UAS)</h2>
          <p className="text-sm text-gray-600">Daily scores for hives and itching severity</p>
        </div>
        <div className="relative">
          <button 
            className="text-gray-500 hover:text-gray-700"
            onMouseEnter={() => setShowLearnMore(true)}
            onMouseLeave={() => setShowLearnMore(false)}
          >
            <InformationCircleIcon className="h-5 w-5" />
          </button>
          {showLearnMore && (
            <div className="absolute right-0 mt-2 z-10">
              <LearnMoreTooltip />
            </div>
          )}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={uasData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
          <XAxis dataKey="date" />
          <YAxis domain={[0, 6]} ticks={[0, 1, 2, 3, 4, 5, 6]} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Hives" stackId="a" fill="#8884d8" />
          <Bar dataKey="Itching" stackId="a" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
      <p className="mt-4 text-sm text-gray-600">
        UAS7 Score (last 7 days): <span className="font-semibold">{uas7Score}</span>
      </p>
    </div>
  );
};

export default UASScoreChart;