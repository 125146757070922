import React, { useState } from 'react';
import { useLogin } from '../components/hooks/useLogin';
import { Link } from 'react-router-dom';

export default function Login() {
    const [email, setEmail] = useState('');
    const [isLinkSent, setIsLinkSent] = useState(false);
    const { loginWithEmailLink, loginWithGoogle, error, isPending } = useLogin();

    const handleEmailLinkLogin = async (e) => {
        e.preventDefault();
        try {
            await loginWithEmailLink(email);
            setIsLinkSent(true);
        } catch (err) {
            // If there's an error, it will be handled by the useLogin hook
            // and set in the error state
        }
    };

    return (
        <div className="space-y-6">
            {!isLinkSent ? (
                <form onSubmit={handleEmailLinkLogin} className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                        <input
                            id="email"
                            type="email"
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        disabled={isPending}
                    >
                        {isPending ? 'Sending...' : 'Send Login Link'}
                    </button>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">Or continue with</span>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        onClick={loginWithGoogle}
                    >
                        <img src="/google-icon.png" alt="Google" className="h-5 w-5 mr-2" />
                        Sign In with Google
                    </button>
                </form>
            ) : (
                <div className="text-center">
                    <h3 className="text-lg font-medium text-gray-900 mb-2">Check your email</h3>
                    <p className="text-sm text-gray-600 mb-4">
                        We've sent a login link to <strong>{email}</strong>. 
                        Click the link in the email to log in.
                    </p>
                    <button
                        onClick={() => setIsLinkSent(false)}
                        className="text-sm text-purple-600 hover:text-purple-500"
                    >
                        Use a different email
                    </button>
                </div>
            )}
            <p className="mt-2 text-center text-sm text-gray-600">
                Don't have an account?{' '}
                <Link to="/signup" className="font-medium text-purple-600 hover:text-purple-500">
                    Sign up
                </Link>
            </p>
        </div>
    );
}