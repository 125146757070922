import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { useAuthContext } from './components/hooks/useAuthContext';

import Navbar from './components/Navbar';
import MobileNav from './components/MobileNav';
import ProfileIcon from './components/ProfileIcon';

import HomeScreen from './pages/HomeScreen';
import Login from './pages/Login';
import Signup from './pages/Signup';
import FormComponent from './components/form/FormComponent';
import Journal from './pages/JournalTwo';
import Landing from './components/Landing';
import UserProfile from './pages/UserProfile';
import Quests from './pages/Quests';
import ManageValues from './components/form/ManageValues';
import Onboarding from './components/onboarding/Onboarding';
import EntryPage from './components/entry/EntryPage';
import ExplanationScreen from './components/questCreator/ExplanationScreen';
import RecoverPassword from './components/profile/RecoverPassword';
import AuthTabs from './pages/AuthTabs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import QuestDetails from './components/quest/QuestDetails';
import UpgradeSuccess from './pages/UpgradeSuccess';
import TermsOfService from './pages/TermsOfService';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import Match from './pages/Match';
import Explore from './pages/Explore';
import SharableDashboard from './components/share/SharableDashboard';
import Connect from './pages/Connect';
import DashboardView from './pages/DashboardView';
import FinalizeLogin from './pages/FinalizeLogin';
import FinalizeSignup from './pages/FinalizeSignup';

const AuthRedirect = () => {
  const { user } = useAuthContext();
  return user ? <Navigate to="/home" replace /> : <Landing />;
};

function App() {
  const { user, authIsReady } = useAuthContext();

  const requireAuth = (element) => {
    return authIsReady ? (user ? element : <Navigate to="/login" replace />) : null;
  };

  const router = createBrowserRouter([
    { path: '/', element: <AuthRedirect /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/terms-of-service', element: <TermsOfService /> },
    { path: '/about', element: <About /> },
    { path: '/contact', element: <ContactUs /> },
    { path: '/recover-password', element: <RecoverPassword /> },
    { path: '/home', element: requireAuth(<HomeScreen />) },
    { path: '/onboarding', element: <Onboarding /> },
    { path: '/journal', element: requireAuth(<Journal />) },
    { path: '/match', element: requireAuth(<Match />) },
    { path: '/explore', element: requireAuth(<Explore />) },
    { path: '/quests', element: requireAuth(<Quests />) },
    { path: '/signup', element: <AuthTabs /> },
    { path: '/login', element: <AuthTabs /> },
    { path: '/new-entry', element: requireAuth(<FormComponent />) },
    { path: '/edit-entry/:entryId', element: requireAuth(<FormComponent />) },
    { path: '/entry/:entryId', element: requireAuth(<EntryPage />) },
    { path: '/profile', element: requireAuth(<UserProfile />) },
    { path: '/manage-values/:type', element: requireAuth(<ManageValues />) },
    { path: '/quest/:questId', element: requireAuth(<QuestDetails />) },
    { path: '/create-quest', element: requireAuth(<ExplanationScreen />) },
    { path: '/share/:userId', element: requireAuth(<SharableDashboard />) },
    { path: '/connect', element: requireAuth(<Connect />) },
    { path: '/dashboards/:userId', element: requireAuth(<DashboardView />) },
    { path: '/upgrade-success', element: requireAuth(<UpgradeSuccess />) },
    { path: '/finalize-login', element: <FinalizeLogin />},
    { path: '/finalize-signup', element: <FinalizeSignup/>},
    { path: '*', element: <div>404 Not Found</div> }
  ]);

  return (
    <div className="App max-w-screen-md mx-auto">
      {authIsReady ? (
        <RouterProvider router={router}>
          {user && (
            <div className="hidden sm:flex items-center justify-between px-4 sm:px-6 lg:px-8">
              <Navbar />
              <ProfileIcon />
            </div>
          )}
          <main>
            <RouterProvider router={router} />
          </main>
          {user && (
            <div className="sm:hidden fixed top-0 right-0 m-4 z-10">
              <ProfileIcon />
            </div>
          )}
          {user && <MobileNav />}
        </RouterProvider>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default App;