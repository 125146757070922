import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import PatientOverview from './PatientOverview';
import TopSymptoms from './TopSymptoms';
import SymptomTrendChart from './SymptomTrendChart';
import CorrelationInsights from './CorrelationInsights';
import DetailedCorrelationTable from './DetailedCorrelationTable';
import EnvironmentalFactors from './EnvironmentalFactors';
import MedicationsAndTreatments from './MedicationsAndTreatments';

const ShareableDashboard = () => {
  const { userId } = useParams();
  const { user } = useAuthContext();
  const [hasPermission, setHasPermission] = useState(false);
  const [userData, setUserData] = useState(null);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkPermissionAndFetchData = async () => {
      if (!user) {
        setError('You must be logged in to view this dashboard.');
        setLoading(false);
        return;
      }

      try {
        // Check if the current user has permission to view this data
        const sharesRef = collection(db, 'dataShares');
        const q = query(sharesRef, 
          where('ownerId', '==', userId),
          where('sharedWithEmail', '==', user.email)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setError('You do not have permission to view this dashboard.');
          setLoading(false);
          return;
        }

        setHasPermission(true);

        // Fetch user data
        const userDoc = await db.collection('users').doc(userId).get();
        if (!userDoc.exists) {
          throw new Error('User not found');
        }
        setUserData(userDoc.data());

        // Fetch user entries
        const entriesQuery = query(collection(db, `users/${userId}/entries`));
        const entriesSnapshot = await getDocs(entriesQuery);
        const entriesData = entriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEntries(entriesData);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    checkPermissionAndFetchData();
  }, [userId, user]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!hasPermission) return <div>You do not have permission to view this dashboard.</div>;

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6">Patient Dashboard</h1>
      <PatientOverview userData={userData} />
      <TopSymptoms entries={entries} />
      <SymptomTrendChart entries={entries} />
      <CorrelationInsights entries={entries} />
      <DetailedCorrelationTable entries={entries} />
      <EnvironmentalFactors entries={entries} />
      <MedicationsAndTreatments entries={entries} />
    </div>
  );
};

export default ShareableDashboard;