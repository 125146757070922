import React from 'react';
import MyResponsiveLine from './MyResponsiveLine';

const data = [
  {name: 'Spring', uv: 400, pv: 400},
  {name: 'Summer', uv: 300, pv: 200},
  {name: 'Fall', uv: 200, pv: 120},
  {name: 'Winter', uv: 278, pv: 150},
  {name: 'Day 5', uv: 189, },
];

const OnboardingChart = ({ step }) => {
  const lines = [
    { dataKey: 'uv', color: '#8884d8', width: 2 }, // Always visible
    ...(step >= 2 ? [{ dataKey: 'pv', color: '#a855f7', width: 3 }] : []), // Visible from step 2
  ];

  return <MyResponsiveLine data={data} lines={lines} step={step} />;
};

export default OnboardingChart;
