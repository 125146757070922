import { useEffect, useState } from 'react';

const useQuestSummaryData = (quests, entries) => {
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    const processData = () => {
      const data = quests.map((quest) => {
        const questEntriesCount = entries.filter(entry => entry.type === 'quest' && entry.questId === quest.id).length;
        const symptomEntriesCount = entries.filter(entry => 
          entry.type === 'symptoms' && 
          quest.selectedSymptoms && 
          quest.selectedSymptoms.some(symptom => entry.values.includes(symptom))
        ).length;

        return {
          ...quest,
          questEntriesCount,
          symptomEntriesCount
        };
      });
      setSummaryData(data);
    };

    if (quests.length > 0) {
      processData();
    } else {
      setSummaryData([]);
    }
  }, [quests, entries]);

  return summaryData;
};

export default useQuestSummaryData;