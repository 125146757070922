import { useState } from 'react';
import { auth } from '../../firebase/config';
import { sendSignInLinkToEmail } from 'firebase/auth';

export const useSignup = () => {
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);

    const signupWithEmailLink = async (email, displayName) => {
        setError(null);
        setIsPending(true);

        const actionCodeSettings = {
            url: `${window.location.origin}/finalize-signup`,
            handleCodeInApp: true
        };
        try {
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            window.localStorage.setItem('emailForSignIn', email);
            window.localStorage.setItem('displayName', displayName);
            console.log('Signup - Email link sent successfully');
            setIsPending(false);
            return { success: true, message: 'Check your email to complete signup' };
        } catch (err) {
            console.error('Email link signup error:', err);
            setError(err.message);
            setIsPending(false);
            return { success: false, error: err.message };
        }
    };

    return { signupWithEmailLink, error, isPending };
};