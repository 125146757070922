import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../components/hooks/useAuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import ProfileIcon from '../components/ProfileIcon';
import { SparklesIcon, PlusIcon } from '@heroicons/react/24/outline';
import QuestSummary from '../components/quests/QuestSummary';
import useFetchUserQuests from '../components/hooks/useFetchUserQuests';
import AddEntryButton from '../components/journal/AddEntryButton';
import UpgradeModal from '../components/quests/UpgradeModal';
import { getFunctions, httpsCallable } from 'firebase/functions';


const Quests = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { quests, loading, error } = useFetchUserQuests(user?.uid);
    const [highlightAddEntry, setHighlightAddEntry] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [isPremium, setIsPremium] = useState(false);

    useEffect(() => {
        if (!user) navigate('/login');
        
        const fetchPremiumStatus = async () => {
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    setIsPremium(userDoc.data().isPremium || false);
                }
            }
        };

        fetchPremiumStatus();
    }, [user, navigate]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const newQuestId = searchParams.get('newQuest');
        if (newQuestId) {
            setHighlightAddEntry(true);
            navigate('/quests', { replace: true });
        }
    }, [location, navigate]);

    const handleCreateQuest = () => {
        if (isPremium) {
            navigate('/create-quest');
        } else {
            setShowUpgradeModal(true);
        }
    };

    const handleUpgrade = async () => {
        try {
          console.log('Initiating upgrade process for user:', user.uid);
          const response = await fetch('https://us-central1-reebe-69943.cloudfunctions.net/createCheckoutSession', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              successUrl: `${window.location.origin}/upgrade-success`,
              cancelUrl: `${window.location.origin}/quests`,
              uid: user.uid,
            }),
          });
    
          console.log('Response status:', response.status);
          
          if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
          }
    
          const result = await response.json();
          console.log('Checkout session created:', result);
          window.location.href = result.url;
        } catch (error) {
          console.error('Error creating checkout session:', error);
          // Handle error (e.g., show an error message to the user)
        }
      };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <div>
            <div className="hidden sm:block">
                <Navbar />
            </div>
            <div className="sm:hidden fixed top-0 right-0 m-4 z-10">
                <ProfileIcon />
            </div>
            <div className="p-4 pb-24">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">Your Quests</h2>
                    <button
                        onClick={handleCreateQuest}
                        className="bg-purple-600 text-white px-4 py-2 rounded-md flex items-center"
                    >
                        <PlusIcon className="h-5 w-5 mr-2" />
                        New Quest
                    </button>
                </div>
                {quests.length === 0 ? (
                    <div className="text-center mt-10">
                        <SparklesIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">No quests yet</h3>
                        <p className="mt-1 text-sm text-gray-500">Get started by creating a new quest!</p>
                        <div className="mt-6">
                            <button
                                type="button"
                                onClick={handleCreateQuest}
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                New Quest
                            </button>
                        </div>
                    </div>
                ) : (
                    <QuestSummary quests={quests} />
                )}
            </div>
            <AddEntryButton highlight={highlightAddEntry} />
            <MobileNav />
            <UpgradeModal
                isOpen={showUpgradeModal}
                onClose={() => setShowUpgradeModal(false)}
                onUpgrade={handleUpgrade}
            />
        </div>
    );
};

export default Quests;