import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, serverTimestamp, getDoc, setDoc } from 'firebase/firestore';  // Updated imports
import { db } from '../../firebase/config';
import { useAuthContext } from '../hooks/useAuthContext';

import CardContainer from './CardContainer';
import GoalSelection from './GoalSelection';
import StreakSelection from './StreakSelection';
import SymptomEducation from './SymptomEducation';
import ManagementStrategiesSelection from './ManagementStrategiesSelection';
import DataSharingConsent from './DataSharingConsent';
import NotificationConsent from './NotificationConsent';
import SymptomSelection from './SymptomSelection';
import WelcomeScreen from './WelcomeScreen';
import CheckInEducation from './CheckInEducation';

const Onboarding = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [userData, setUserData] = useState({});
    const totalSteps = 10;

    useEffect(() => {
        console.log(userData);
    }, [userData]);

    const handleNext = () => {
        if (step < totalSteps - 1) {
            setStep(step + 1);
        } else {
            saveOnboardingData();
        }
    };

    const handleUpdate = (newData) => {
        setUserData(prev => ({ ...prev, ...newData }));
    };

    const saveOnboardingData = async () => {
        if (!user) {
            console.error("User is not authenticated.");
            return;
        }
        const docRef = doc(db, 'users', user.uid);
        try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                await updateDoc(docRef, {
                    ...userData,
                    onboardingComplete: true,
                    timestamp: serverTimestamp()
                });
            } else {
                await setDoc(docRef, {
                    ...userData,
                    onboardingComplete: true,
                    timestamp: serverTimestamp()
                });
            }
            navigate('/home');
        } catch (error) {
            console.error("Error saving onboarding data:", error);
        }
    };

    return (
        <div className="h-Education bg-gradient-to-br from-blue-400 to-green-300 text-white">
            <CardContainer
                step={step}
                totalSteps={totalSteps}
                onNext={handleNext}
                onBack={() => setStep(step - 1)}
                isLastStep={step === totalSteps - 1}
            >
                {step === 0 && <WelcomeScreen/>}
                {step >= 1 && step <= 2 && <SymptomEducation step={step} onUpdate={handleUpdate}/>}
                {step === 3 && <GoalSelection onUpdate={handleUpdate} />}
                {step === 4 && <SymptomSelection onUpdate={handleUpdate} />}
                {step === 5 && <ManagementStrategiesSelection onUpdate={handleUpdate} />}
                {step === 6 && <div></div>}
                {step === 7 && <CheckInEducation onUpdate={handleUpdate} />}
                {step === 8 && <NotificationConsent onUpdate={handleUpdate} />}
                {step === 9 && <DataSharingConsent onUpdate={handleUpdate} />}
            </CardContainer>
        </div>
    );
};

export default Onboarding;
