import React from 'react';
import { useNavigate } from 'react-router-dom';
import homeImage from '../../../src/images/home.png';

export default function HeroSection() {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
      <div>
        <h1 className="text-4xl sm:text-5xl font-bold text-slate-900">
          Every Symptom Tells a Story
        </h1>
        <p className="mt-4 text-xl text-slate-600">
          Track your symptoms, identify correlations, and gain insights into your reactions.
        </p>
        <div className="mt-8 flex flex-col sm:flex-row gap-4">
          <button
            onClick={() => navigate('/signup')}
            className="w-full sm:w-auto rounded-md bg-purple-500 px-8 py-3 text-lg font-semibold text-white shadow hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
          >
            Sign Up
          </button>
          <button
            onClick={() => navigate('/login')}
            className="w-full sm:w-auto rounded-md border border-purple-500 bg-transparent px-8 py-3 text-lg font-semibold text-purple-500 hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
          >
            Log In
          </button>
        </div>
      </div>
      <div className="mt-8 lg:mt-0">
        <img src={homeImage} alt="Reebee App" className="w-full max-w-md mx-auto rounded-xl shadow-lg" />
      </div>
    </div>
  );
}