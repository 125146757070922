import React from 'react';

// WelcomeScreen.js
const WelcomeScreen = ({ step }) => {
    return (
        <div className="space-y-4">
            <h2 className="text-xl font-bold text-center text-gray-700">Welcome</h2>
        </div>
    );
};

export default WelcomeScreen;
