import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { HomeIcon, PencilIcon, BeakerIcon, ShareIcon, ChartBarIcon } from '@heroicons/react/24/outline';

const navigation = [
    { name: 'Home', href: '/home', icon: HomeIcon },
    { name: 'Journal', href: '/journal', icon: PencilIcon },
    // { name: 'Match', href: '/match', icon: BeakerIcon },
    { name: 'Explore', href: '/explore', icon: ChartBarIcon },
    { name: 'Connect', href: '/connect', icon: ShareIcon },
];

export default function MobileNav({ scrollDirection }) {
    const location = useLocation();
    const useScrollBehavior = ['/explore', '/match'].includes(location.pathname);

    const navClasses = `sm:hidden ${useScrollBehavior
        ? `fixed inset-x-0 bottom-0 z-10 bg-white transition-transform duration-300 ${
            scrollDirection === 'down' ? 'translate-y-full' : 'translate-y-0'
          }`
        : 'fixed inset-x-0 bottom-0 z-10 bg-white'}`;

    return (
        <nav className={navClasses}>
            <div className="flex justify-between items-center p-4">
                {navigation.map((item) => (
                    <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                            `flex flex-col items-center justify-center text-sm ${isActive ? 'text-purple-600' : 'text-purple-400'}`
                        }
                        style={{
                            minHeight: '48px'
                        }}
                    >
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                        <span className="block text-xs">{item.name}</span>
                    </NavLink>
                ))}
            </div>
        </nav>
    );
}