import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import { format, startOfWeek, addDays, isSameDay, isToday, subWeeks, addWeeks } from 'date-fns';

function WeeklyCalendar({ selectedDate, onDateChange, entries = [] }) {
    const startDate = startOfWeek(selectedDate);

    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const today = new Date();

    const goToPreviousWeek = () => {
        onDateChange(subWeeks(selectedDate, 1));
    };

    const goToNextWeek = () => {
        onDateChange(addWeeks(selectedDate, 1));
    };

    const hasEntries = (date) => {
        if (!Array.isArray(entries) || entries.length === 0) return false;
        return entries.some(entry => {
            const entryDate = entry.setAt instanceof Date ? entry.setAt : new Date(entry.setAt);
            return isSameDay(entryDate, date);
        });
    };

    const hasSymptoms = (date) => {
        if (!Array.isArray(entries) || entries.length === 0) return false;
        return entries.some(entry => {
            const entryDate = entry.setAt instanceof Date ? entry.setAt : new Date(entry.setAt);
            return isSameDay(entryDate, date) && entry.type === 'symptom';
        });
    };

    return (
        <div className="my-4 bg-white shadow rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
                <button 
                    onClick={goToPreviousWeek}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    aria-label="Previous week"
                >
                    <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
                </button>
                <h2 className="text-lg font-semibold text-gray-800">
                    {format(startDate, 'MMMM yyyy')}
                </h2>
                <button 
                    onClick={goToNextWeek}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    aria-label="Next week"
                >
                    <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                </button>
            </div>
            <div className="grid grid-cols-7 gap-2">
                {days.map((day, index) => {
                    const date = addDays(startDate, index);
                    const isSelected = isSameDay(date, selectedDate);
                    const isTodayDate = isToday(date);
                    const hasEntriesForDay = hasEntries(date);
                    const hasSymptomsForDay = hasSymptoms(date);

                    return (
                        <div 
                            key={index} 
                            className={`relative flex flex-col items-center p-2 rounded-lg cursor-pointer transition-all duration-200 ${
                                isSelected ? 'bg-purple-100 shadow-md transform scale-105' : 
                                isTodayDate ? 'bg-blue-50' : ''
                            } hover:bg-gray-100`}
                            onClick={() => onDateChange(date)}
                        >
                            {hasEntriesForDay && (
                                <CheckCircleIcon className="absolute top-0 right-0 h-4 w-4 text-purple-500 m-1" />
                            )}
                            <div className={`text-sm font-medium ${isTodayDate ? 'text-blue-600' : 'text-gray-600'}`}>
                                {day}
                            </div>
                            <div className={`text-lg ${isSelected ? 'font-bold text-purple-800' : 'text-gray-800'}`}>
                                {format(date, 'd')}
                            </div>
                            {hasSymptomsForDay && (
                                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-red-500 rounded-full"></div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default WeeklyCalendar;