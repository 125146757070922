import React from 'react';

const Moods = () => (


<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#F0F0F0"/>
<g clipPath="url(#clip0_1036_13)">
<path d="M32.0789 21.4299L29.6889 16.0099C29.4689 10.5299 24.9589 6.1499 19.4089 6.1499C13.7289 6.1499 9.12891 10.7499 9.12891 16.4199C9.12891 19.3999 10.3989 22.0799 12.4189 23.9499V31.7399H22.6989V29.1599H26.1989C28.1289 29.1599 29.6989 27.5999 29.6989 25.6699V22.5899H31.3289C31.9189 22.5799 32.3189 21.9699 32.0789 21.4299ZM21.1239 20.7499L18.8289 25.8039C17.2889 18.2249 15.9869 24.9199 12.4189 21.4899C12.4189 21.4399 12.4189 21.5399 12.4189 21.4899C11.3789 20.0699 10.7689 18.3099 10.7689 16.4199C10.7689 11.6499 14.6389 7.7899 19.4089 7.7899C21.6989 7.7899 23.4289 8.53415 25.2349 10.2099C29.8793 14.5194 26.4889 20.0049 21.1239 20.7499ZM26.671 18.2299C25.991 18.2299 25.441 17.6799 25.441 16.9999C25.441 16.3199 25.991 15.7699 26.671 15.7699C27.351 15.7699 27.901 16.3199 27.901 16.9999C27.901 17.6799 27.351 18.2299 26.671 18.2299ZM21.9289 16.6699C22.7989 15.8999 22.9089 14.5599 22.1389 13.6799C21.3689 12.8099 20.0289 12.6999 19.1489 13.4699L18.8489 13.7299L18.5489 13.4699C17.6789 12.6999 16.3189 12.8099 15.5589 13.6799C14.7889 14.5599 14.8989 15.9099 15.7689 16.6699L18.8089 19.7799L21.9289 16.6699Z" fill="#737373"/>
</g>
<defs>
<clipPath id="clip0_1036_13">
<rect width="25.6" height="25.6" fill="white" transform="translate(7.84961 6.1499)"/>
</clipPath>
</defs>
</svg>


)

export default Moods