import { useState } from 'react';
import { auth } from '../../firebase/config';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signInWithRedirect, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useAuthContext } from './useAuthContext';
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useAuthContext();
    const navigate = useNavigate();

    const login = async (email, password) => {
        setError(null);
        setIsPending(true);

        try {
            const res = await signInWithEmailAndPassword(auth, email, password);
            dispatch({ type: 'LOGIN', payload: res.user });
            navigate('/home');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsPending(false);
        }
    };

    const loginWithGoogle = async () => {
        setError(null);
        setIsPending(true);

        const provider = new GoogleAuthProvider();

        try {
            await signInWithPopup(auth, provider);
            navigate('/home');
        } catch (err) {
            if (err.code === 'auth/popup-blocked') {
                try {
                    await signInWithRedirect(auth, provider);
                } catch (redirectError) {
                    setError(redirectError.message);
                }
            } else {
                setError(err.message);
            }
        } finally {
            setIsPending(false);
        }
    };


    const loginWithEmailLink = async (email) => {
        setError(null);
        setIsPending(true);

        const actionCodeSettings = {
            url: `${window.location.origin}/finalize-login`,
            handleCodeInApp: true
        };

        console.log('Login - Continue URL:', actionCodeSettings.url);
        console.log('Login - Current origin:', window.location.origin);

        try {
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            window.localStorage.setItem('emailForSignIn', email);
            console.log('Login - Email link sent successfully');
            setError('Check your email to complete login');
        } catch (err) {
            console.error('Email link login error:', err);
            setError(err.message);
        } finally {
            setIsPending(false);
        }
    };

    const completeSignInWithEmailLink = async () => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            try {
                const result = await signInWithEmailLink(auth, email, window.location.href);
                window.localStorage.removeItem('emailForSignIn');
                dispatch({ type: 'LOGIN', payload: result.user });
                navigate('/home');
            } catch (error) {
                console.error('Error completing sign-in with email link:', error);
                setError(error.message);
            }
        }
    };

    return { login, loginWithGoogle, loginWithEmailLink, completeSignInWithEmailLink, error, isPending };
};