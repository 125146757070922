import { useState } from 'react';
import { auth } from '../../firebase/config';
import { sendPasswordResetEmail } from 'firebase/auth';

export const useRecoverPassword = () => {
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [message, setMessage] = useState('');

    const recoverPassword = async (email) => {
        setError(null);
        setIsPending(true);

        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent. Check your inbox.');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsPending(false);
        }
    };

    return { error, isPending, message, recoverPassword };
};
