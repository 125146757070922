
import React from 'react';

const HeartActive = () => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#F0F0F0"/>
<path d="M25.5938 11.9285C29.043 9.51025 30.9966 14.8427 30.9966 14.8427" stroke="#835FA5" strokeWidth="2" strokeLinecap="round"/>
<path d="M28.9524 21.3909H27.7538C26.5128 21.3909 25.8924 21.3909 25.378 21.7016C24.8638 22.0123 24.5444 22.5798 23.9061 23.715L23.8598 23.7971C23.2652 24.8546 22.9677 25.3834 22.5351 25.3746C22.1024 25.366 21.8237 24.8257 21.2667 23.7451L18.743 18.85C18.2236 17.8425 17.9639 17.3387 17.549 17.3148C17.1342 17.2909 16.8252 17.7619 16.2071 18.7039L15.7838 19.3492C15.1273 20.3496 14.799 20.8498 14.312 21.1203C13.825 21.3909 13.2526 21.3909 12.1078 21.3909H11.0039" stroke="#835FA5" strokeWidth="2" strokeLinecap="round"/>
<path d="M19.9779 10.9789L19.1578 11.796C19.3697 12.0399 19.6668 12.1784 19.9779 12.1784C20.289 12.1784 20.5861 12.0399 20.798 11.796L19.9779 10.9789ZM16.1479 32.2348C13.9824 30.344 11.4539 27.8644 9.47622 25.1504C7.47727 22.4071 6.16225 19.5885 6.16225 16.995H3.92188C3.92188 20.3947 5.60852 23.7422 7.7099 26.6261C9.83252 29.5391 12.503 32.1475 14.7321 34.094L16.1479 32.2348ZM6.16225 16.995C6.16225 12.6291 8.04103 9.70871 10.4199 8.64896C12.7683 7.60281 16.0241 8.18977 19.1578 11.796L20.798 10.1618C17.2107 6.03362 12.9987 4.90192 9.55936 6.43405C6.15057 7.95259 3.92188 11.9075 3.92188 16.995H6.16225ZM25.2238 34.094C27.4528 32.1477 30.1232 29.5391 32.2459 26.6261C34.3472 23.7424 36.0339 20.3947 36.0339 16.9949H33.7935C33.7935 19.5885 32.4784 22.4071 30.4796 25.1504C28.5019 27.8644 25.9734 30.344 23.8079 32.2348L25.2238 34.094ZM36.0339 16.9949C36.0339 11.9073 33.8052 7.95243 30.3964 6.43392C26.9571 4.90184 22.745 6.03363 19.1578 10.1618L20.798 11.796C23.9317 8.18977 27.1874 7.60274 29.5359 8.64885C31.9147 9.70855 33.7935 12.6289 33.7935 16.9949H36.0339ZM14.7321 34.094C16.6303 35.7515 17.9481 36.9696 19.9779 36.9696V34.5706C18.9003 34.5706 18.2285 34.0515 16.1479 32.2348L14.7321 34.094ZM23.8079 32.2348C21.7273 34.0515 21.0557 34.5706 19.9779 34.5706V36.9696C22.0077 36.9696 23.3254 35.7515 25.2238 34.094L23.8079 32.2348Z" fill="#835FA5"/>
<ellipse cx="11.0337" cy="11.5783" rx="0.885217" ry="0.837075" fill="#835FA5"/>
<ellipse cx="9.26412" cy="14.8027" rx="0.885217" ry="0.837075" fill="#835FA5"/>
</svg>

)

export default HeartActive
