import React from 'react';
import JournalEntryCard from './JournalEntryCard'; // Ensure the path is correct

const dayParts = [
    { label: 'Morning', start: 0, end: 12, icon: 'sunrise.svg' },
    { label: 'Afternoon', start: 12, end: 17, icon: 'sunny.svg' },
    { label: 'Evening', start: 17, end: 21, icon: 'sunset.svg' },
    { label: 'Night', start: 21, end: 0, icon: 'night.svg' }
];

const DayCalendar = ({ entries }) => {
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const entriesByDayPart = dayParts.map(part => ({ ...part, entries: [] }));
    
    entries.forEach(entry => {
        const hour = entry.setAt.getHours();
        const partIndex = entriesByDayPart.findIndex(part => (hour >= part.start && hour < part.end) || (part.start > part.end && (hour >= part.start || hour < part.end)));
        if (partIndex !== -1) {
            entriesByDayPart[partIndex].entries.push(entry);
        }
    });

    return (
        <div className="flex flex-col flex-1 overflow-y-auto pb-24"> {/* Added bottom padding */}
            {entriesByDayPart.map(part => (
                <div key={part.label} className="flex flex-col">
                    <div className="flex items-center p-2 bg-gray-100">
                        <span className="ml-2 text-lg font-semibold">{part.label}</span>
                    </div>
                    <div className="flex-1">
                        {part.entries.length > 0 ? part.entries.map(entry => (
                            <JournalEntryCard key={entry.id} entry={entry} />
                        )) : <div className="text-center text-gray-400">No entries</div>}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DayCalendar;
