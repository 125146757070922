import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. License</h2>
          <p className="mb-4">Subject to your compliance with the Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and utilize the Service. In order to activate your license, you must register for a user account with the Service (an "Account"). Your license and your Account may be terminated at any time without notice for any breach of this Agreement.</p>
          
          <h3 className="text-xl font-semibold text-gray-900 mb-2">a. By registering for an account, you agree to the following:</h3>
          <ul className="list-disc pl-6 mb-4">
            <li>You are at least 18 years of age, or between 16 and 17 years of age with parental consent to use the Service;</li>
            <li>All information you submit to or share with the Service will at all times be truthful, accurate, and complete;</li>
            <li>You will not use the Service to share any uploads, information, messages, or other data (collectively, "User Content") that are illegal, defamatory, inappropriate, or abusive to other users;</li>
            <li>You will not misuse or misrepresent the ownership of intellectual property, including, but not limited to, copyrights, trademarks or patents;</li>
            <li>You will not disrupt, or attempt to disrupt, the Service's security, connectivity, data, or accessibility; and</li>
            <li>You will not make, or facilitate any third party in making, fraudulent purchases.</li>
          </ul>
          
          <p className="mb-4">We may refuse to offer the Service to anyone at any time.</p>
          
          <p>You may elect to purchase certain services, subscriptions, or application enhancements through the Service (each an "In App Purchase"). When you make an In App Purchase, you are doing so through Apple iTunes service and you are agreeing to Apple's iTunes' Terms and Conditions.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Intellectual Property</h2>
          <p>We and our licensors own the rights to all intellectual property found throughout the Service, including copyrights, trademarks, and patents. No license, assignment, or sale of such intellectual property has been made to you. We reserve all rights to the intellectual property on the Service.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Premium Accounts</h2>
          <p className="mb-4">You may upgrade to a premium account. Premium Accounts are subject to a subscription charge which may be purchased through the Apple or Android store (the "Coach Subscription") or Stripe via a Web application or site.</p>
          <p>You acknowledge that if your Coach or Pro Subscription ends without being renewed, all data in your account, including data received from other users, may be lost.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. User Content</h2>
          <ul className="list-disc pl-6">
            <li>The Service may, through use of your phone or a peripheral device, collect health and personal data about you ("User Content"). User Content shall also include any information, photographs, videos, or other data uploaded to the Service in any way. You are solely responsible for your User Content.</li>
            <li>Your User Content is used in the Service, to provide you with features and insights based on such data.</li>
            <li>You have access to your User Content via the Service and can export your data anytime through the Service using the export function ('individual access request').</li>
            <li>You may ask us to delete all your User Content from the Service ('right to erasure').</li>
            <li>You should backup your User Content to guard against loss. We shall in no way be responsible for any loss of User Content or other data.</li>
            <li>Your User Content might be anonymized and used for research purposes.</li>
            <li>Your data is not shared, distributed, used by or sold to third party unless you explicitly authorize transferring data to a third party service within the app (for example if you authorize sending your data to TrainingPeaks or other service).</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Third Party Information</h2>
          <p>The Service may contain links to third party websites or applications. Additionally, users may provide you with links to third party websites or applications. We have no control over the content of these third party links and you agree to visit or access these links at your own risk. Please use caution when accessing any link unaffiliated with the Service.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Limitation of Warranties and Damages</h2>
          <p className="mb-4">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED ON "AS-IS," "WHERE-IS" AND "AS AVAILABLE" BASIS WITH ALL FAULTS AND WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, AND NON-INFRINGEMENT. WE (AND OUR SUPPLIERS AND LICENSORS) MAKE NO WARRANTY THAT THE SERVICE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE TO YOU ON A TIMELY, UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, COMPLETE, TRUTHFUL, RELIABLE, OR FREE FROM VIRUSES, WORMS, OR OTHER HARMFUL CODE.</p>

          <p className="mb-4">IN NO EVENT SHALL WE OR OUR AFFILIATES, SUPPLIERS, OR LICENSORS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE, OR OTHER DAMAGES OF ANY KIND ARISING OUT OF, OR IN ANY WAY RELATED TO, YOUR USE OF THE SERVICE, YOUR RELIANCE ON INFORMATION FOUND ON THE SERVICE, OR YOUR INTERACTION WITH ANY USER ON THE SERVICE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, FRAUD, STRICT LIABILITY OR OTHERWISE, EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL OUR TOTAL AGGREGATE LIABILITY TO YOU EXCEED FIVE HUNDRED U.S. DOLLARS ($500 USD).</p>

          <p className="mb-4">THE PARTIES AGREE TO WAIVE THEIR RIGHTS TO PARTICIPATE IN ANY CLASS ACTION OR GROUP ARBITRATION AS IT RELATES TO THE SERVICE OR THE TERMS.</p>

          <p>YOU MAY USE CERTAIN PERIPHERAL DEVICES IN CONJUNCTION WITH THE SERVICE. WE DO NOT ENDORSE OR WARRANT THE USE OF ANY OF THESE DEVICES. YOU USE THESE DEVICES AT YOUR OWN SOLE RISK.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Terms Specific to Apple Devices</h2>
          <p className="mb-4">By accessing the Service on any Apple, Inc. ("Apple") device, the following additional terms shall apply:</p>
          <ol className="list-decimal pl-6">
            <li>This agreement is made solely between you and us, and not with Apple. Apple is not responsible for the Service and Apple has no responsibility to furnish maintenance or support for the Service;</li>
            <li>The Service is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Service for your private, personal, non-commercial use, subject to all the terms and conditions of the Terms as they are applicable to the Service;</li>
            <li>You will not use the Service on any Apple device that you do not own or control;</li>
            <li>In the event of any failure of the Service to conform to any applicable warranty, Apple's sole warranty obligation to you will be to refund to you the purchase price (if any) of the Service;</li>
            <li>You acknowledge and agree that we, and not Apple, are responsible for addressing any claims you or any third party may have in relation to the Service;</li>
            <li>You acknowledge and agree that, in the event of any third party claim that the Service or your possession and use of the Service infringes that third party's intellectual property rights, we, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;</li>
            <li>You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country, and that you are not listed on any U.S. Government list of prohibited or restricted parties;</li>
            <li>Both you and we acknowledge and agree that, in your use of the Service, you will comply with any applicable third party terms of agreement which may affect or be affected by such use; and</li>
            <li>Both you and we acknowledge and agree that Apple and Apple's subsidiaries are third party beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third party beneficiary hereof.</li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Additional Terms</h2>
          <ol className="list-decimal pl-6">
            <li>If any provision of the Terms is found to be unenforceable or invalid, that remainder of the Terms shall be enforced to the fullest extent possible.</li>
            <li>The Terms shall be interpreted and construed by the laws of Virginia and the United States. Any dispute arising from or related to your use of the Service or these Terms shall be brought exclusively in the state or federal courts of Virginia competent to hear such dispute.</li>
            <li>You may not assign your license or the Terms. We may assign the Terms as a condition of any sale, merger, or reorganization.</li>
            <li>The Terms represents the full, final and complete understanding of the parties as it relates to the subject matter hereof.</li>
          </ol>
        </section>

        <p className="mt-8 text-gray-600">
          By using our service, you agree to these Terms of Service. If you have any questions, please <Link to="/contact" className="text-blue-600 hover:underline">contact us</Link>.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;