import React, { useMemo } from 'react';
import Select from 'react-select';

const generateColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 1) * 16777215).toString(16);
  return '#' + '0'.repeat(6 - color.length) + color;
};

const CombinedFilter = ({ trendData, visibleOptions, selectedOptions, onChange }) => {
  const groupedOptions = useMemo(() => {
    const groups = visibleOptions.reduce((acc, option) => {
      if (!acc[option.group]) {
        acc[option.group] = [];
      }
      acc[option.group].push(option);
      return acc;
    }, {});

    // Add trend values to the options
    if (!groups['trend']) {
      groups['trend'] = [];
    }
    Object.keys(trendData).forEach(trendValue => {
      if (!groups['trend'].some(option => option.value === trendValue)) {
        groups['trend'].push({ value: trendValue, label: trendValue, group: 'trend' });
      }
    });

    return Object.entries(groups)
      .map(([group, options]) => ({
        label: group.charAt(0).toUpperCase() + group.slice(1),
        options: options.map(option => ({
          ...option,
          color: generateColor(option.value)
        }))
      }));
  }, [visibleOptions, trendData]);

  const handleChange = (newSelection) => {
    onChange(newSelection || []);
  };

  const customOption = ({ data, label, innerProps }) => (
    <div {...innerProps} className="flex items-center p-2 hover:bg-gray-100">
      <span className="w-4 h-4 mr-2 rounded-full" style={{ backgroundColor: data.color }}></span>
      <span>{label}</span>
    </div>
  );

  const customMultiValue = ({ data, removeProps }) => (
    <div className="flex items-center bg-gray-100 rounded mr-1 my-1">
      <span className="w-3 h-3 ml-1 mr-1 rounded-full" style={{ backgroundColor: data.color }}></span>
      <span className="text-sm px-1">{data.label}</span>
      <button
        {...removeProps}
        className="text-gray-500 hover:text-gray-700 px-1"
      >
        ×
      </button>
    </div>
  );

  return (
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Select Values to Display
      </label>
      <Select
        isMulti
        options={groupedOptions}
        value={selectedOptions}
        onChange={handleChange}
        placeholder="Filter by value..."
        className="basic-multi-select"
        classNamePrefix="select"
        components={{ Option: customOption, MultiValue: customMultiValue }}
      />
    </div>
  );
};

export default CombinedFilter;