import React from 'react';
import { Container } from './ContainerComponent';
import { CheckIcon } from './CheckIconComponent';
import profilePicture from '../../images/profile.jpg';

const founder = {
  name: 'Grey Weissend',
  role: 'Creator',
  imageUrl: profilePicture,
};


export default function AboutSection() {
  return (
    <section id="introduction" aria-label="Introduction" className="pb-16 pt-20 sm:pb-20 md:pt-36 lg:py-32">
      <Container className="text-lg tracking-tight text-slate-700">
        <p className="font-display text-4xl font-bold tracking-tight text-slate-900">
          Reebee helps you track and understand your symptoms.
        </p>
        <ul role="list" className="mt-8 space-y-3">
          {[
            'Track your allergy, asthma, COPD, and eczema symptoms',
            'Record your diet, activities, medications, and environment',
            'Identify and track correlations between datapoints and your symptoms',
            'Gain insights to discuss with your healthcare provider',
          ].map((feature) => (
            <li key={feature} className="flex">
              <CheckIcon className="h-8 w-8 flex-none fill-purple-500" />
              <span className="ml-4">{feature}</span>
            </li>
          ))}
        </ul>

       

      

        <p className="mt-4">
          When hives would appear, I would struggle to know why. 
        </p>
        <p className="mt-4">
          I created Reebee to clearly track what I was doing and find clarity in what was working to relieve my symptoms. 
        </p>
        <p className="mt-4">
        Over time, structuring this information produced clear patterns giving insight into when and why my reactions were likely to occur.
        </p>
        
        <p className="mt-4">
          Was it the processed gluten? The pollen? How effective were my allergy shots?
        </p>
        <p className="mt-4">
        By recording your symptoms and their context, we can help bring clarity to these questions and many more.
        </p>
  
        <div className="flex items-center gap-x-6 mt-8 mb-8">
            {/* <img alt="" src={founder.imageUrl} className="h-16 w-16 rounded-full" /> */}
            <div>
              <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{founder.name}</h3>
              <p className="text-sm font-semibold leading-6 text-gray-900">{founder.role}</p>
            </div>
          </div>


        <p className="mt-10">
          <a
            href="#free-trial"
            className="text-base font-medium text-purple-600 hover:text-purple-800"
          >
           Join our community. {' '}
            <span aria-hidden="true">&rarr;</span>
          </a>
        </p>
      </Container>
    </section>
  );
}