import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../components/hooks/useAuthContext';
import { db } from '../firebase/config';
import { collection, query, where, getDocs, getDoc, doc as firestoreDoc } from 'firebase/firestore';
import { formatDistanceToNow, format, parseISO, isValid } from 'date-fns';
import { ResponsiveContainer, LineChart, Line } from 'recharts';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';

const Connect = () => {
  const { user } = useAuthContext();
  const [sharedDashboards, setSharedDashboards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSharedDashboards = async () => {
      if (!user) return;
      try {
        const q = query(
          collection(db, 'dataShares'),
          where('sharedWithEmail', '==', user.email)
        );
        const querySnapshot = await getDocs(q);
        const dashboardsPromises = querySnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          let userData = { displayName: 'Paul Grey Weissend' };
          try {
            const userDoc = await getDoc(firestoreDoc(db, 'users', data.ownerId));
            if (userDoc.exists()) {
              userData = userDoc.data();
            }
          } catch (userError) {
            console.error('Error fetching user data:', userError);
          }

          let entries = [];
          try {
            const entriesQuery = query(collection(db, `users/${data.ownerId}/entries`));
            const entriesSnapshot = await getDocs(entriesQuery);
            entries = entriesSnapshot.docs.map(entryDoc => ({ id: entryDoc.id, ...entryDoc.data() }));
          } catch (entriesError) {
            console.error('Error fetching entries:', entriesError);
          }
          
          const lastUpdated = entries.length > 0 
            ? entries.reduce((latest, entry) => {
                const entryDate = entry.createdAt?.toDate() || new Date(0);
                return entryDate > latest ? entryDate : latest;
              }, new Date(0))
            : null;

          return {
            id: docSnapshot.id,
            ...data,
            displayName: userData.displayName || 'Unknown User',
            lastUpdated,
            entries: entries,
            entryCount: entries.length,
          };
        });
        const dashboards = await Promise.all(dashboardsPromises);
        setSharedDashboards(dashboards);
      } catch (err) {
        console.error('Error fetching shared dashboards:', err);
        setError('Failed to load dashboards. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSharedDashboards();
  }, [user]);

  const generateTrendData = useMemo(() => (entries) => {
    const trendData = {};
    entries.forEach(entry => {
      if (entry.type === 'symptom' && entry.setAt) {
        const entryDate = new Date(entry.setAt);
        if (isValid(entryDate)) {
          const date = format(entryDate, 'yyyy-MM-dd');
          entry.values?.forEach(symptom => {
            trendData[date] = (trendData[date] || 0) + (symptom.amount || 0);
          });
        } else {
          console.warn('Invalid date encountered:', entry.setAt);
        }
      }
    });

    return Object.entries(trendData)
      .map(([date, value]) => ({ date, value }))
      .sort((a, b) => {
        const dateA = parseISO(a.date);
        const dateB = parseISO(b.date);
        return isValid(dateA) && isValid(dateB) ? dateA - dateB : 0;
      });
  }, []);
  if (isLoading) return <div className="flex justify-center items-center h-screen">Loading dashboards...</div>;
  if (error) return <div className="text-red-500 p-4">{error}</div>;

  return (
    <div className="min-h-screen flex flex-col">
            <div className="hidden sm:block">
                <Navbar />
            </div>
      <div className="container mx-auto p-4 pt-20 pb-24">
        <h1 className="text-2xl font-bold mb-6 text-gray-800">Shared with You</h1>
        {sharedDashboards.length === 0 ? (
          <p className="text-gray-600">No dashboards have been shared with you yet.</p>
        ) : (
          <ul className="space-y-4">
            {sharedDashboards.map(dashboard => {
              const trendData = generateTrendData(dashboard.entries);
              return (
                <li key={dashboard.id} className="bg-white shadow rounded-lg p-4 hover:shadow-md transition-shadow duration-200">
                  <Link to={`/dashboards/${dashboard.ownerId}`} className="block">
                    <div className="flex justify-between items-center mb-2">
                      <h2 className="text-lg font-semibold text-purple-600">{dashboard.displayName}</h2>
                      <span className="text-sm text-gray-500">
                        {dashboard.lastUpdated
                          ? `Updated ${formatDistanceToNow(dashboard.lastUpdated, { addSuffix: true })}`
                          : 'No recent updates'}
                      </span>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div className="flex-grow h-10">
                        <ResponsiveContainer width="100%" height={40}>
                          <LineChart data={trendData}>
                            <Line type="monotone" dataKey="value" stroke="#8b5cf6" strokeWidth={2} dot={false} />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="text-sm text-gray-600">
                        {dashboard.entryCount} entries
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <MobileNav />
    </div>
  );
};

export default Connect;