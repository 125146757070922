import React from 'react';

// EmailConsent.js
const EmailConsent = ({ step }) => {
    return (
        <div className="space-y-4">
            <h2 className="text-xl font-bold text-center text-gray-700">Yes! Email me :)</h2>
        </div>
    );
};

export default EmailConsent;
