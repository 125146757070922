import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { differenceInDays, format } from 'date-fns';
import { db } from '../../firebase/config';
import { doc, updateDoc } from 'firebase/firestore';
import { ArrowLeft, Target, Activity, Calendar, CheckCircle, Archive, Trash2 } from 'lucide-react';
import ActionCount from './ActionCount';
import SymptomCount from './SymptomCount';
import QuestSymptomChart from './QuestSymptomChart';
import QuestEntries from './QuestEntries';
import useFetchUserQuests from '../hooks/useFetchUserQuests';
import useFetchUserEntries from '../hooks/useFetchUserEntries';
import useQuestActionCount from '../hooks/useQuestActionCount';

const QuestDetails = () => {
  const { questId } = useParams();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [quest, setQuest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const { quests, loading: questsLoading, error: questsError } = useFetchUserQuests(user?.uid);
  const { entries, loading: entriesLoading, error: entriesError } = useFetchUserEntries(user?.uid);

  const { dailyActions, totalActionCount, symptomOccurrences } = useQuestActionCount(quest, entries);

  useEffect(() => {
    if (!questsLoading && !entriesLoading) {
      const currentQuest = quests.find(q => q.id === questId);
      if (currentQuest) {
        setQuest(currentQuest);
      } else {
        setError('Quest not found');
      }
      setLoading(false);
    }
  }, [questId, quests, entries, questsLoading, entriesLoading]);

  const handleArchive = async () => {
    if (!user || !questId || processing) return;
    setProcessing(true);
    try {
      const questRef = doc(db, `users/${user.uid}/quests/${questId}`);
      await updateDoc(questRef, { isArchived: true, archivedAt: new Date() });
      navigate('/quests');
    } catch (err) {
      setError('Failed to archive quest. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  const handleDelete = async () => {
    if (!user || !questId || processing) return;
    setProcessing(true);
    try {
      const questRef = doc(db, `users/${user.uid}/quests/${questId}`);
      await updateDoc(questRef, { isDeleted: true, deletedAt: new Date() });
      navigate('/quests');
    } catch (err) {
      setError('Failed to delete quest. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  if (loading || questsLoading || entriesLoading) return <div className="flex justify-center items-center h-screen">Loading quest details...</div>;
  if (error || questsError || entriesError) return <div className="text-red-500 text-center p-4">Error: {error || questsError || entriesError}</div>;
  if (!quest) return <div className="text-center p-4">Quest not found</div>;

  const daysRemaining = differenceInDays(new Date(quest.createdAt).setDate(new Date(quest.createdAt).getDate() + quest.duration.value * (quest.duration.unit === 'week' ? 7 : quest.duration.unit === 'month' ? 30 : 1)), new Date());

  return (
    <div className="bg-gray-50 min-h-screen pb-20">
      {/* Header */}
      <div className="bg-white text-gray-800 p-4 sticky top-0 z-10 shadow-sm">
        <button onClick={() => navigate('/quests')} className="mb-2 text-gray-600">
          <ArrowLeft size={24} />
        </button>
        <h1 className="text-2xl font-semibold text-gray-700">{quest.goal}</h1>
        <p className="text-sm text-gray-500">{format(new Date(quest.createdAt), 'MMMM d, yyyy')}</p>
      </div>

      {/* Main Content */}
      <div className="p-4 space-y-6">
        {/* Quest Info Card */}
        <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-100">
          <div className="flex items-center mb-2">
            <Target className="text-blue-500 mr-2" size={20} />
            <h2 className="text-lg font-semibold text-gray-700">Quest Details</h2>
          </div>
          <p className="text-gray-600"><span className="font-medium">System:</span> {quest.selectedSystem}</p>
          <p className="text-gray-600"><span className="font-medium">Action:</span> {quest.modification} {quest.something.value} in {quest.something.type}</p>
          <p className="text-gray-600"><span className="font-medium">Symptoms:</span> {quest.selectedSymptoms.join(', ')}</p>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-2 gap-4">
          <ActionCount totalCount={totalActionCount} />
          <SymptomCount totalCount={symptomOccurrences} />
        </div>

        {/* Symptom Chart */}
        <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-100">
          <div className="flex items-center mb-2">
            <Activity className="text-blue-500 mr-2" size={20} />
            <h2 className="text-lg font-semibold text-gray-700">Symptom Tracking</h2>
          </div>
          <QuestSymptomChart quest={quest} entries={entries} />
        </div>

        {/* Quest Progress */}
        <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-100">
          <div className="flex items-center mb-2">
            <Calendar className="text-blue-500 mr-2" size={20} />
            <h2 className="text-lg font-semibold text-gray-700">Quest Progress</h2>
          </div>
          <p className="text-3xl font-bold text-blue-600 mb-2">{daysRemaining} days</p>
          <p className="text-sm text-gray-600">remaining in your quest</p>
        </div>

        {/* Recent Entries */}
        <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-100">
          <div className="flex items-center mb-2">
            <CheckCircle className="text-blue-500 mr-2" size={20} />
            <h2 className="text-lg font-semibold text-gray-700">Recent Entries</h2>
          </div>
          <QuestEntries 
            entries={entries.filter(entry => 
              entry.type === quest.something?.type &&
              entry.values.some(value => value.value === quest.something?.value)
            ).slice(0, 5)} 
          />
        </div>
      </div>

      {/* Footer Actions */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 flex justify-between">
        <button 
          onClick={handleArchive} 
          className="flex items-center justify-center w-1/2 bg-gray-200 text-gray-700 py-2 px-4 rounded-lg mr-2 hover:bg-gray-300 transition-colors"
          disabled={processing}
        >
          <Archive size={20} className="mr-2" />
          Archive
        </button>
        <button 
          onClick={handleDelete} 
          className="flex items-center justify-center w-1/2 bg-red-100 text-red-600 py-2 px-4 rounded-lg ml-2 hover:bg-red-200 transition-colors"
          disabled={processing}
        >
          <Trash2 size={20} className="mr-2" />
          Delete
        </button>
      </div>
    </div>
  );
};

export default QuestDetails;