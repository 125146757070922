import React, { useMemo } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';

const Metrics = ({ entries }) => {
  const { dailyStreak, avgSymptomIntensity } = useMemo(() => {
    let streak = 0;
    let totalSymptomIntensity = 0;
    let symptomCount = 0;

    // Sort entries by date in descending order
    const sortedEntries = entries.sort((a, b) => new Date(b.setAt) - new Date(a.setAt));

    // Get unique dates of entries
    const uniqueDates = [...new Set(sortedEntries.map(entry => 
      new Date(entry.setAt).toDateString()
    ))];

    // Calculate streak
    for (let i = 0; i < uniqueDates.length; i++) {
      const currentDate = new Date(uniqueDates[i]);
      const previousDate = i > 0 ? new Date(uniqueDates[i - 1]) : new Date();

      if (i === 0 || (previousDate - currentDate) / (1000 * 60 * 60 * 24) === 1) {
        streak++;
      } else {
        break;
      }
    }

    // Calculate average symptom intensity
    sortedEntries.forEach(entry => {
      if (entry.type === 'symptom') {
        entry.values.forEach(value => {
          totalSymptomIntensity += value.amount || 0;
          symptomCount++;
        });
      }
    });

    const avgSymptomIntensity = symptomCount > 0 ? totalSymptomIntensity / symptomCount : 0;

    return { dailyStreak: streak, avgSymptomIntensity };
  }, [entries]);

  const metrics = [
    {
      name: 'Daily Streak',
      stat: `${dailyStreak} days`,
      previousStat: `${dailyStreak - 1} days`,
      change: '1 day',
      changeType: 'increase'
    },
    {
      name: 'Avg Symptom Intensity',
      stat: avgSymptomIntensity.toFixed(1),
      previousStat: (avgSymptomIntensity + 0.1).toFixed(1), // Mock previous value
      change: '0.1',
      changeType: 'decrease'
    }
  ];

  return (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <dl className="grid grid-cols-2 gap-4">
        {metrics.map((item) => (
          <div key={item.name} className="px-4 py-3 bg-gray-50 rounded-lg">
            <dt className="text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 flex justify-between items-baseline">
              <div className="text-xl font-semibold text-indigo-600">{item.stat}</div>
              <div
                className={`inline-flex items-baseline px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-green-500" />
                ) : (
                  <ArrowDownIcon className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-red-500" />
                )}
                {item.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default Metrics;