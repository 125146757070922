import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const EntryVisualization = ({ entries, symptom, trigger, correlationInfo }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const getLabel = (value) => {
    const labels = ['', 'Very Little', 'Little', 'Some', 'A Lot', 'Very Much'];
    return labels[value] || '';
  };

  useEffect(() => {
    if (!entries || !Array.isArray(entries) || entries.length === 0) {
      console.log('No entries data available');
      return;
    }

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    const data = entries
      .filter(entry => entry && entry.type === 'symptom' && Array.isArray(entry.values) && entry.values.some(v => v && v.value === symptom))
      .map(entry => {
        const triggerEntry = entries.find(e => e && e.type !== 'symptom' && Array.isArray(e.values) && e.values.some(v => v && v.value === trigger));
        return {
          x: triggerEntry?.values.find(v => v && v.value === trigger)?.amount || 0,
          y: entry.values.find(v => v && v.value === symptom)?.amount || 0,
        };
      });

    chartInstance.current = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [{
          label: `${symptom} vs ${trigger}`,
          data: data,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'linear',
            min: 0,
            max: 5,
            title: {
              display: true,
              text: trigger,
            },
            ticks: {
              stepSize: 1,
              callback: function(value) {
                return value % 1 === 0 ? `${value}\n(${getLabel(value)})` : value;
              }
            }
          },
          y: {
            type: 'linear',
            min: 0,
            max: 5,
            title: {
              display: true,
              text: symptom,
            },
            ticks: {
              stepSize: 1,
              callback: function(value) {
                return value % 1 === 0 ? `${value}\n(${getLabel(value)})` : value;
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                const xValue = context.parsed.x;
                const yValue = context.parsed.y;
                return `${trigger}: ${xValue} (${getLabel(Math.round(xValue))}), ${symptom}: ${yValue} (${getLabel(Math.round(yValue))})`;
              }
            }
          }
        }
      }
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [entries, symptom, trigger]);

  if (!entries || !Array.isArray(entries) || entries.length === 0) {
    return <div className="text-center text-gray-500">No data available for visualization</div>;
  }

  return (
    <div className="w-full max-w-md mx-auto p-4">
      <h3 className="text-lg font-semibold mb-2 text-center">
        Correlation: {correlationInfo?.correlation?.toFixed(2) || 'N/A'}
      </h3>
      <p className="text-sm text-center mb-4">
        (Lag: {correlationInfo?.effectiveLag || 'N/A'} hours, p-value: {correlationInfo?.pValue?.toFixed(4) || 'N/A'})
      </p>
      <div className="aspect-square">
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default EntryVisualization;