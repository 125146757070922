import React from 'react';
import ButtonSelection from './ButtonSelection';  // Ensure this is the correct import path

const ManagementStrategiesSelection = ({ onUpdate }) => {
    const options = [
        { 
            name: "Medication", 
            description: "Use quick-relief and long-term medications to manage symptoms effectively." 
        },
        { 
            name: "Lifestyle Adjustments", 
            description: "Implement environmental controls and dietary changes to reduce allergen exposure." 
        },
        { 
            name: "Physical Treatments", 
            description: "Manage symptoms with physical interventions like skin care routines and Neti-Pots for nasal irrigation." 
        },
        { 
            name: "Emergency Preparedness", 
            description: "Prepare for severe allergic reactions with personalized action plans and emergency kits." 
        },
        { 
            name: "Psychological Support & Education", 
            description: "Access stress management resources, support groups, and the latest treatment information." 
        }
    ];

    // Function to update parent component or handle side effects
    const handleUpdate = (data) => {
        onUpdate(data);
    };

    return (
        <div className="container mx-auto px-4">
            <h2 className="text-center font-bold text-lg mb-4">How do you manage your symptoms?</h2>
            <ButtonSelection options={options} onUpdate={handleUpdate} />
        </div>
    );
};

export default ManagementStrategiesSelection;
