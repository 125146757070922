import React from 'react';
import { useNavigate } from 'react-router-dom';
import useQuestSummaryData from '../hooks/useQuestSummaryData';
import useFetchUserEntries from '../hooks/useFetchUserEntries';
import { useAuthContext } from '../hooks/useAuthContext';
import QuestCard from './QuestCard';

const QuestSummary = ({ quests }) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { entries, loading: entriesLoading } = useFetchUserEntries(user?.uid);
  console.log(quests)

  const summaryData = useQuestSummaryData(quests, entries);

  const handleQuestClick = (questId) => {
    navigate(`/quest/${questId}`);
  };

  // Group quests by their 'goal'
  const groupedQuests = summaryData.reduce((acc, quest) => {
    if (!acc[quest.goal]) {
      acc[quest.goal] = [];
    }
    acc[quest.goal].push(quest);
    return acc;
  }, {});

  if (entriesLoading) return <p>Loading...</p>;

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      {Object.entries(groupedQuests).map(([goal, questGroup]) => (
        <div key={goal} className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-purple-600">{goal}</h2>
          <div className="space-y-4">
            {questGroup.map((quest) => (
              <QuestCard
                key={quest.id}
                quest={quest}
                entries={entries}
                onClick={() => handleQuestClick(quest.id)}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestSummary;