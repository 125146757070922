import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../components/hooks/useAuthContext';
import { db } from '../firebase/config';
import { doc, getDoc, collection, query, getDocs } from 'firebase/firestore';
import PatientOverview from '../components/share/PatientOverview';
import TopSymptoms from '../components/share/TopSymptoms';
import SymptomTrendChart from '../components/share/SymptomTrendChart';
import CorrelationInsights from '../components/share/CorrelationInsights';
import MedicationsAndTreatments from '../components/share/MedicationsAndTreatments';
import EnvironmentalFactors from '../components/share/EnvironmentalFactors';
import DetailedCorrelationTable from '../components/share/DetailedCorrelationTable';
import TimeRangePicker from '../components/patterns/TimeRangePicker';
import UASChart from '../components/share/UASChart';

const DashboardView = () => {
  const { userId } = useParams();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [allEntries, setAllEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('1m'); // Default to 30 days

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (!user || !userId) {
        console.log('No user or userId');
        setError('Invalid user or dashboard ID');
        setIsLoading(false);
        return;
      }

      try {
        console.log('Checking share permission for:', `${userId}_${user.email}`);
        const shareRef = doc(db, 'dataShares', `${userId}_${user.email}`);
        const shareDoc = await getDoc(shareRef);

        if (!shareDoc.exists()) {
          console.log('Share document not found');
          throw new Error('You do not have permission to view this dashboard.');
        }

        console.log('Share document found, fetching user data');
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const fetchedUserData = userDoc.data();
          console.log('User data fetched:', fetchedUserData);
          setUserData(fetchedUserData);
        } else {
          console.log('User document not found');
        }

        console.log('Fetching entries');
        const entriesQuery = query(collection(db, `users/${userId}/entries`));
        const entriesSnapshot = await getDocs(entriesQuery);
        const entriesData = entriesSnapshot.docs.map(doc => {
          const data = doc.data();
          return { 
            id: doc.id, 
            ...data,
            setAt: data.setAt?.toDate() || new Date() // Convert Firestore Timestamp to JavaScript Date, use current date if undefined
          };
        });
        
        console.log('Entries fetched:', entriesData.length);
        setAllEntries(entriesData);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError(err.message);
        setIsLoading(false);
        setTimeout(() => navigate('/dashboards'), 3000);
      }
    };

    fetchDashboardData();
  }, [user, userId, navigate]);

  const filteredEntries = useMemo(() => {
    const now = new Date();
    let startDate;
    switch (timeRange) {
      case '1d':
        startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case '1w':
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case '1m':
        startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      case '6m':
        startDate = new Date(now.getTime() - 180 * 24 * 60 * 60 * 1000);
        break;
      case '1y':
        startDate = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
        break;
      default:
        startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    }
    return allEntries.filter(entry => entry.setAt >= startDate && entry.setAt <= now);
  }, [allEntries, timeRange]);

  const handleTimeRangeChange = (newRange) => {
    setTimeRange(newRange);
  };

  if (isLoading) return <div className="container mx-auto p-4">Loading dashboard...</div>;
  if (error) return <div className="container mx-auto p-4 text-red-500">{error} Redirecting...</div>;

  const symptomEntries = filteredEntries.filter(entry => entry.type === 'symptom');

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Shared Dashboard</h1>
      <TimeRangePicker selectedRange={timeRange} onRangeChange={handleTimeRangeChange} />
      <div className="space-y-8">
        {userData ? (
          <>
            <PatientOverview userData={userData} />
            <p className="text-sm text-gray-500">Debug: userData is available - {JSON.stringify(userData)}</p>
          </>
        ) : (
          <p className="text-sm text-gray-500">Paul Grey Weissend  userId: {userId}</p>
        )}
        {filteredEntries.length > 0 ? (
          <>
            <TopSymptoms entries={filteredEntries} />
            <SymptomTrendChart entries={filteredEntries} />
            {/* <CorrelationInsights entries={filteredEntries} /> */}
            <UASChart entries={filteredEntries} timeRange={timeRange} /> {/* Add the UASChart component here */}

            <MedicationsAndTreatments entries={filteredEntries} />
            <EnvironmentalFactors entries={filteredEntries} timeRange={timeRange} />
            {/* <DetailedCorrelationTable entries={filteredEntries} /> */}
          </>
        ) : (
          <p>No entries found for this user in the selected time range.</p>
        )}
      </div>
    </div>
  );
};

export default DashboardView;