import React from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';

const StepCard = ({ number, title, description, children, completed, locked }) => (
  <div className={`bg-white rounded-lg shadow-md p-4 mb-4 ${locked ? 'opacity-100' : ''}`}>
    <div className="flex items-center mb-2">
      <div className="bg-purple-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">
        {number}
      </div>
      <h3 className="text-lg font-semibold">{title}</h3>
      {locked && <LockClosedIcon className="h-5 w-5 ml-2 text-gray-500" />}
    </div>
    <p className="text-gray-600 mb-2">{description}</p>
    {children}
    {completed && (
      <div className="mt-2 text-green-600 font-semibold">
        Completed ✓
      </div>
    )}
  </div>
);

export default StepCard;