import React, { useMemo } from 'react';
import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { calculateCorrelations, prepareScatterData } from '../utils/correlationAnalysis';

const CorrelationInsights = ({ entries }) => {
  const correlations = useMemo(() => calculateCorrelations(entries), [entries]);

  const topCorrelations = useMemo(() => {
    const flatCorrelations = Object.entries(correlations).flatMap(([symptom, triggers]) =>
      Object.entries(triggers).map(([trigger, value]) => ({ symptom, trigger, value }))
    );
    return flatCorrelations
      .sort((a, b) => Math.abs(b.value) - Math.abs(a.value))
      .slice(0, 6);
  }, [correlations]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 border border-gray-300 rounded shadow">
          <p>{`${payload[0].name}: ${payload[0].value}`}</p>
          <p>{`${payload[1].name}: ${payload[1].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Top Correlations</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {topCorrelations.map((correlation) => {
          const scatterData = prepareScatterData(entries, correlation.symptom, correlation.trigger);
          return (
            <div key={`${correlation.symptom}-${correlation.trigger}`} className="border p-4 rounded-lg">
              <h3 className="text-lg font-medium">{correlation.symptom} vs {correlation.trigger}</h3>
              <p>Correlation: {correlation.value.toFixed(2)}</p>
              <ResponsiveContainer width="100%" height={200}>
                <ScatterChart margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" dataKey="x" name={correlation.trigger} />
                  <YAxis type="number" dataKey="y" name={correlation.symptom} />
                  <Tooltip content={<CustomTooltip />} />
                  <Scatter 
                    name={`${correlation.symptom} vs ${correlation.trigger}`} 
                    data={scatterData.datasets[0].data} 
                    fill="#8884d8" 
                  />
                </ScatterChart>
              </ResponsiveContainer>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CorrelationInsights;