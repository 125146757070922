const ProgressBar = ({ step, totalSteps }) => {
    const progress = ((step / totalSteps) * 100).toFixed(0);
  
    return (
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div
          className="bg-green-600 h-2.5 rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    );
  };

  export default ProgressBar;