import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { startOfDay, format, eachDayOfInterval } from 'date-fns';

const calculateUASScore = (amount) => {
  if (amount < 2) return 1;
  if (amount >= 2 && amount < 4) return 2;
  return 3;
};

const UASChart = ({ entries, timeRange }) => {
  const uasData = useMemo(() => {
    const now = new Date();
    let startDate;
    switch (timeRange) {
      case '1d': startDate = startOfDay(now); break;
      case '1w': startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); break;
      case '1m': startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); break;
      case '6m': startDate = new Date(now.getTime() - 180 * 24 * 60 * 60 * 1000); break;
      case '1y': startDate = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000); break;
      default: startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    }

    const dateRange = eachDayOfInterval({ start: startDate, end: now });

    const dailyScores = dateRange.reduce((acc, date) => {
      acc[format(date, 'yyyy-MM-dd')] = { date: format(date, 'yyyy-MM-dd'), hives: 0, itching: 0 };
      return acc;
    }, {});

    entries.forEach(entry => {
      if (entry.type === 'symptom' && (entry.values.some(v => v.value === 'Hives') || entry.values.some(v => v.value === 'Itching'))) {
        const date = format(entry.setAt, 'yyyy-MM-dd');
        if (dailyScores[date]) {
          entry.values.forEach(symptom => {
            if (symptom.value === 'Hives') {
              dailyScores[date].hives = Math.max(dailyScores[date].hives, calculateUASScore(symptom.amount));
            } else if (symptom.value === 'Itching') {
              dailyScores[date].itching = Math.max(dailyScores[date].itching, calculateUASScore(symptom.amount));
            }
          });
        }
      }
    });

    return Object.values(dailyScores);
  }, [entries, timeRange]);

  const formatXAxis = (dateStr) => {
    const date = new Date(dateStr);
    switch (timeRange) {
      case '1d': return format(date, 'HH:mm');
      case '1w': return format(date, 'EEE');
      case '1m': return format(date, 'dd MMM');
      case '6m':
      case '1y': return format(date, 'MMM yyyy');
      default: return format(date, 'dd MMM');
    }
  };

  return (
    <section className="mb-8 bg-white p-4 rounded-lg shadow">
      <h2 className="text-2xl font-semibold mb-4">Urticaria Activity Score (UAS)</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={uasData}>
          <XAxis dataKey="date" tickFormatter={formatXAxis} />
          <YAxis domain={[0, 6]} ticks={[0, 1, 2, 3, 4, 5, 6]} />
          <Tooltip
            labelFormatter={(label) => format(new Date(label), 'PPP')}
            formatter={(value, name) => [`Score: ${value}`, name]}
          />
          <Legend />
          <Bar dataKey="hives" stackId="a" fill="#8884d8" name="Hives" />
          <Bar dataKey="itching" stackId="a" fill="#82ca9d" name="Itching" />
        </BarChart>
      </ResponsiveContainer>
      <div className="mt-4 text-sm text-gray-600">
        <p>Hives Scoring: Mild (1) &lt;20 wheals/24 hrs, Moderate (2) 20-50 wheals/24 hrs, Intense (3) &gt;50 wheals/24 hrs</p>
        <p>Itching Scoring: Mild (1) Present but not annoying, Moderate (2) Troublesome but doesn't interfere with daily life, Intense (3) Severe, interferes with daily life</p>
        <p>Total daily UAS is the sum of hives and itching scores (max 6). UAS7 is the sum of 7 consecutive daily UAS scores.</p>
      </div>
    </section>
  );
};

export default UASChart;