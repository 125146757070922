import React, { useMemo } from 'react';
import { Chart as ChartJS, TimeSeriesScale, LinearScale, PointElement, LineElement, Tooltip, Legend, LineController } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { subDays, startOfDay, endOfDay, eachDayOfInterval } from 'date-fns';

ChartJS.register(TimeSeriesScale, LinearScale, PointElement, LineElement, Tooltip, Legend, LineController);

const generateColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 1) * 16777215).toString(16);
  return '#' + '0'.repeat(6 - color.length) + color;
};

const calculateMovingAverage = (data, windowSize = 3) => {
  if (data.length === 0) {
    console.log('No data points for moving average calculation');
    return [];
  }

  const sortedData = data.sort((a, b) => a.x - b.x);
  const result = [];
  const allDays = eachDayOfInterval({
    start: startOfDay(sortedData[0].x),
    end: endOfDay(sortedData[sortedData.length - 1].x)
  });

  allDays.forEach((day, index) => {
    const windowStart = Math.max(0, index - Math.floor(windowSize / 2));
    const windowEnd = Math.min(allDays.length - 1, index + Math.floor(windowSize / 2));
    const windowData = sortedData.filter(
      point => point.x >= allDays[windowStart] && point.x <= allDays[windowEnd]
    );

    if (windowData.length > 0) {
      const avgY = windowData.reduce((sum, point) => sum + point.y, 0) / windowData.length;
      result.push({ x: day, y: avgY });
    } else {
      result.push({ x: day, y: null });
    }
  });

  return result;
};

const SymptomScatterPlot = ({ trendData, selectedValues }) => {
  const endDate = useMemo(() => endOfDay(new Date()), []);
  const startDate = useMemo(() => startOfDay(subDays(endDate, 29)), [endDate]);

  const datasets = useMemo(() => {
    return selectedValues.flatMap(value => {
      const color = generateColor(value);
      const data = Object.entries(trendData[value] || {}).flatMap(([dateStr, entries]) => {
        return entries.map(entry => ({
          x: new Date(entry.setAt),
          y: entry.amount
        }));
      }).filter(point => point.x >= startDate && point.x <= endDate);

      const movingAverageData = calculateMovingAverage(data);

      return [
        {
          label: value,
          data: data,
          backgroundColor: color,
          pointRadius: 6,
          pointHoverRadius: 8,
          showLine: false,
        },
        {
          label: `${value} (Trend)`,
          data: movingAverageData,
          borderColor: color,
          backgroundColor: 'transparent',
          pointRadius: 0,
          borderDash: [5, 5],
          showLine: true,
          spanGaps: true,
        }
      ];
    });
  }, [selectedValues, trendData, startDate, endDate]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM d'
          }
        },
        title: {
          display: true,
          text: 'Date and Time'
        },
        min: startDate,
        max: endDate
      },
      y: {
        beginAtZero: true,
        max: 5,
        title: {
          display: true,
          text: 'Amount'
        },
        ticks: {
          stepSize: 1
        }
      }
    },
    animation: {
      duration: 200 // Set to 0 to disable animations, or a low value like 200 for quick transitions
    },
    transitions: {
      active: {
        animation: {
          duration: 0 // Disable transitions when hovering or selecting points
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => context[0].raw.x.toLocaleString(),
          label: (context) => `${context.dataset.label}: ${context.parsed.y?.toFixed(2) || 'No data'}`
        }
      },

    }
  };

  return (
    <div className="h-96 w-full mb-8">
      <Scatter data={{ datasets }} options={options} />
    </div>
  );
};

export default SymptomScatterPlot;