import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { getGeohash } from './geohash';

const useFetchWeatherData = (latitude, longitude) => {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const geohash = getGeohash(latitude, longitude);
      const now = new Date();
      const date = now.toISOString().split('T')[0]; // YYYY-MM-DD format
      const hour = now.getUTCHours(); // Get current hour in UTC
      const docRef = doc(db, `geo_data/${geohash}/dates/${date}/hours/${hour}`);

      try {
        console.log(`Attempting to get document from Firestore: geo_data/${geohash}/dates/${date}/hours/${hour}`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data().weatherData) {
          console.log('Document found in Firestore:', docSnap.data());
          setWeatherData(docSnap.data().weatherData);
        } else {
          console.log('Document not found in Firestore, making API request...');
          const response = await fetch(`https://us-central1-reebe-69943.cloudfunctions.net/fetchWeatherData?latitude=${latitude}&longitude=${longitude}`);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          console.log('API request successful, data received:', data);

          await setDoc(docRef, { weatherData: data, createdAt: serverTimestamp() }, { merge: true });
          console.log('Data successfully written to Firestore');
          setWeatherData(data);
        }
      } catch (err) {
        console.error('Error occurred while fetching weather data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (latitude && longitude) {
      fetchData();
    }
  }, [latitude, longitude]);

  return { weatherData, loading, error };
};

export default useFetchWeatherData;
