import React, { useState } from 'react';
import { useSignup } from '../components/hooks/useSignup';
import { Link } from 'react-router-dom';

export default function Signup() {
    const [email, setEmail] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [isLinkSent, setIsLinkSent] = useState(false);
    const { signupWithEmailLink, isPending, error } = useSignup();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!agreeToTerms) {
            return;
        }
        
        try {
            await signupWithEmailLink(email, displayName);
            setIsLinkSent(true);
        } catch (err) {
            // If there's an error, it will be handled by the useSignup hook
            // and set in the error state
        }
    };

    return (
        <div className="space-y-6">
            {!isLinkSent ? (
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name:</label>
                        <input
                            id="name"
                            type="text"
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                        <input
                            id="email"
                            type="email"
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="terms" className="flex items-center">
                            <input
                                id="terms"
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-purple-600"
                                checked={agreeToTerms}
                                onChange={(e) => setAgreeToTerms(e.target.checked)}
                            />
                            <span className="ml-2 text-sm text-gray-600">
                                I agree to the <Link to="/terms-of-service" className="text-purple-600 hover:underline">Terms of Service</Link> and <Link to="/privacy-policy" className="text-purple-600 hover:underline">Privacy Policy</Link>.
                            </span>
                        </label>
                    </div>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        disabled={isPending || !agreeToTerms}
                    >
                        {isPending ? 'Sending...' : 'Sign Up with Email Link'}
                    </button>
                </form>
            ) : (
                <div className="text-center">
                    <h3 className="text-lg font-medium text-gray-900 mb-2">Check your email</h3>
                    <p className="text-sm text-gray-600 mb-4">
                        We've sent a signup link to <strong>{email}</strong>. 
                        Click the link in the email to complete your registration.
                    </p>
                    <button
                        onClick={() => setIsLinkSent(false)}
                        className="text-sm text-purple-600 hover:text-purple-500"
                    >
                        Use a different email
                    </button>
                </div>
            )}
            <p className="mt-2 text-center text-sm text-gray-600">
                Already have an account?{' '}
                <Link to="/login" className="font-medium text-purple-600 hover:text-purple-500">
                    Log in
                </Link>
            </p>
        </div>
    );
}