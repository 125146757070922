// windowedCorrelationAnalysis.js

import { addHours, isWithinInterval } from 'date-fns';

const calculateCorrelationWithStats = (x, y) => {
  console.log('Calculating correlation with stats:');
  console.log('x:', x);
  console.log('y:', y);

  if (x.length === 0 || y.length === 0) {
    console.log('One or both arrays are empty. Returning null correlation.');
    return { correlation: null, pValue: null, sampleSize: 0 };
  }

  const n = x.length;
  let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0, sumY2 = 0;

  for (let i = 0; i < n; i++) {
    sumX += x[i];
    sumY += y[i];
    sumXY += x[i] * y[i];
    sumX2 += x[i] * x[i];
    sumY2 += y[i] * y[i];
  }

  console.log('Sums calculated:');
  console.log('sumX:', sumX, 'sumY:', sumY, 'sumXY:', sumXY, 'sumX2:', sumX2, 'sumY2:', sumY2);

  const numerator = n * sumXY - sumX * sumY;
  const denominator = Math.sqrt((n * sumX2 - sumX * sumX) * (n * sumY2 - sumY * sumY));

  const correlation = denominator === 0 ? 0 : numerator / denominator;

  console.log('Correlation calculated:', correlation);

  // Calculate t-statistic
  const t = correlation * Math.sqrt((n - 2) / (1 - correlation * correlation));

  // Calculate p-value (two-tailed test)
  const pValue = 2 * (1 - tDistribution(Math.abs(t), n - 2));

  console.log('t-statistic:', t, 'p-value:', pValue);

  return { correlation, pValue, sampleSize: n };
};

const tDistribution = (t, df) => {
  const x = df / (t * t + df);
  let result = 1;
  for (let j = 3; j <= df; j += 2) {
    result += (1 - x) ** ((j - 1) / 2);
  }
  result *= Math.sqrt(x) / 2;
  return 1 - result;
};

const aggregateDataByWindow = (entries, windowSizeHours = 24) => {
  console.log('Aggregating data by window:');
  console.log('Window Size:', windowSizeHours, 'hours');

  if (entries.length === 0) {
    console.log('No entries provided. Returning empty array.');
    return [];
  }

  entries.sort((a, b) => a.setAt - b.setAt);
  const start = entries[0].setAt;
  const end = entries[entries.length - 1].setAt;

  console.log('Date range:', start, 'to', end);

  const windowedData = [];
  let currentWindowStart = start;

  while (currentWindowStart < end) {
    const currentWindowEnd = addHours(currentWindowStart, windowSizeHours);
    
    const windowEntries = entries.filter(entry => 
      isWithinInterval(entry.setAt, { start: currentWindowStart, end: currentWindowEnd })
    );

    console.log(`Window ${currentWindowStart.toISOString()} to ${currentWindowEnd.toISOString()}:`);
    console.log('Entries in window:', windowEntries.length);

    const aggregatedWindow = {
      startDate: currentWindowStart,
      endDate: currentWindowEnd,
      symptoms: {},
      triggers: {},
      environmental: {}
    };

    windowEntries.forEach(entry => {
      if (entry.type === 'symptom') {
        entry.values.forEach(value => {
          if (!aggregatedWindow.symptoms[value.value]) {
            aggregatedWindow.symptoms[value.value] = [];
          }
          aggregatedWindow.symptoms[value.value].push(value.amount);
        });
      } else if (entry.type === 'environmental') {
        if (entry.weather && entry.weather.main) {
          aggregatedWindow.environmental['temperature'] = (aggregatedWindow.environmental['temperature'] || []).concat(entry.weather.main.temp);
          aggregatedWindow.environmental['humidity'] = (aggregatedWindow.environmental['humidity'] || []).concat(entry.weather.main.humidity);
        }
        if (entry.aqi && entry.aqi.indexes && entry.aqi.indexes.length > 0) {
          const aqiIndex = entry.aqi.indexes.find(index => index.code === 'uaqi');
          if (aqiIndex) {
            aggregatedWindow.environmental['aqi'] = (aggregatedWindow.environmental['aqi'] || []).concat(aqiIndex.aqi);
          }
        }
      } else {
        entry.values.forEach(value => {
          if (!aggregatedWindow.triggers[value.value]) {
            aggregatedWindow.triggers[value.value] = [];
          }
          aggregatedWindow.triggers[value.value].push(value.amount);
        });
      }
    });

    // Average the values in each window
    Object.keys(aggregatedWindow.symptoms).forEach(symptom => {
      aggregatedWindow.symptoms[symptom] = aggregatedWindow.symptoms[symptom].reduce((sum, val) => sum + val, 0) / aggregatedWindow.symptoms[symptom].length;
    });
    Object.keys(aggregatedWindow.triggers).forEach(trigger => {
      aggregatedWindow.triggers[trigger] = aggregatedWindow.triggers[trigger].reduce((sum, val) => sum + val, 0) / aggregatedWindow.triggers[trigger].length;
    });
    Object.keys(aggregatedWindow.environmental).forEach(factor => {
      aggregatedWindow.environmental[factor] = aggregatedWindow.environmental[factor].reduce((sum, val) => sum + val, 0) / aggregatedWindow.environmental[factor].length;
    });

    windowedData.push(aggregatedWindow);
    currentWindowStart = currentWindowEnd;
  }

  console.log('Total windows created:', windowedData.length);

  return windowedData;
};

export const calculateWindowedCorrelations = (entries, maxLag = 7, windowSizeHours = 24) => {
  console.log('Calculating windowed correlations:');
  console.log('Total entries:', entries.length);
  console.log('Max lag:', maxLag);
  console.log('Window size:', windowSizeHours, 'hours');

  if (entries.length === 0) {
    console.log('No entries provided. Returning empty correlations.');
    return {};
  }

  const symptoms = [...new Set(entries.filter(e => e.type === 'symptom').flatMap(e => e.values.map(v => v.value)))];
  const triggers = [...new Set(entries.filter(e => e.type !== 'symptom' && e.type !== 'environmental').flatMap(e => e.values.map(v => v.value)))];
  const envFactors = ['temperature', 'humidity', 'aqi'];

  console.log('Symptoms found:', symptoms);
  console.log('Triggers found:', triggers);
  console.log('Environmental factors:', envFactors);

  const windowedData = aggregateDataByWindow(entries, windowSizeHours);

  const correlations = {};
  symptoms.forEach(symptom => {
    correlations[symptom] = {};
    [...triggers, ...envFactors].forEach(factor => {
      console.log(`Calculating correlation for ${symptom} vs ${factor}`);

      const symptomData = windowedData.map(window => window.symptoms[symptom] || 0);
      const factorData = windowedData.map(window => window.triggers[factor] || window.environmental[factor] || 0);

      console.log('Symptom data:', symptomData);
      console.log('Factor data:', factorData);

      // Calculate correlations for different lags
      for (let lag = 0; lag <= maxLag; lag++) {
        console.log(`Calculating for lag ${lag}`);

        const { correlation, pValue, sampleSize } = calculateCorrelationWithStats(
          symptomData.slice(lag),
          factorData.slice(0, factorData.length - lag)
        );

        if (correlation !== null) {
          if (!correlations[symptom][factor]) {
            correlations[symptom][factor] = [];
          }
          correlations[symptom][factor].push({ 
            lag, 
            correlation, 
            pValue, 
            sampleSize,
            windowSizeHours,
            effectiveLag: lag * windowSizeHours // The actual lag in hours
          });

          console.log(`Lag ${lag} result:`, correlations[symptom][factor][correlations[symptom][factor].length - 1]);
        } else {
          console.log(`Lag ${lag}: No valid correlation calculated.`);
        }
      }
    });
  });

  console.log('Final correlation data:', correlations);

  return correlations;
};