import React from 'react';
import BeeIcon from '../images/ReeBee';
import profilePicture from '../images/profile.jpg';

const About = () => {
  const teamMembers = [
    {
      name: 'Your Name',
      role: 'Founder',
      imageUrl: profilePicture,
    },
    // Add more team members as needed
  ];

  const values = [
    { name: 'User-Centric Design', description: 'We prioritize our users needs in every feature we develop.' },
    { name: 'Data Privacy', description: 'We ensure the highest standards of data protection and user privacy.' },
    { name: 'Continuous Improvement', description: 'We constantly iterate and improve based on user feedback and new research.' },
    { name: 'Empowerment', description: 'We aim to empower individuals to take control of their health journey.' },
  ];

  return (
    <div className="bg-white">
     
      <div className="mx-auto mt-20 max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About Reebee</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Reebee was born from a deeply personal journey with allergies.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
          <div className="relative lg:order-last lg:col-span-5">
            <figure className="border-l border-indigo-600 pl-8">
              <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                <p>"Reebee aims to solve this uniquely paralyzing fear. By enabling easy documentation of symptoms and their contexts including the treatments they undergo, patients can take back control from their reactions and quickly recognize symptom changes."</p>
              </blockquote>
            </figure>
          </div>
          <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
            <p>
              Allergies have no clear genesis or conclusion. In the search for effective therapies, this means there is no single marker for long-term success. As someone who has lived with severe allergies, anaphylaxis, asthma, and eczema, I understand firsthand the challenges and fears that come with managing these conditions.
            </p>
            <p className="mt-8">
              Reebee creates an evidence-based assessment of the body's immune state. We begin with recording the golden source of truth – the symptoms. We extend our data collection to the context of the symptoms including diet, activities, medications, stress, and location metadata to generate an individualized picture of when reactions tend to occur.
            </p>
            <p className="mt-8">
              Our goal is not to provide a solution, but to help give patients the ability to understand and care for their symptoms. We want to empower individuals to verify what really works for them, backed by the best source of information — their own health data.
            </p>
            <h3 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Our Vision</h3>
            <p className="mt-6">
              As we build our user base, Reebee will be able to aggregate data (anonymously, of course) to paint a clear picture of how reactions are affected in different contexts and for different people. Our ultimate goal is to help direct clinical research in new directions that show effectiveness based on the evidence we generate.
            </p>
            <p className="mt-8">
              The journey to understanding reaction severity is just beginning, and we invite you to join us in this transformative approach to allergy management.
            </p>
          </div>
        </div>
      </div>

      {/* Team section */}
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our team</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Reebee is developed and maintained by a dedicated team of health technology experts, data scientists, and individuals who have personal experience with allergies and chronic conditions.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
        >
          {teamMembers.map((person) => (
            <li key={person.name}>
              <img className="mx-auto h-24 w-24 rounded-full" src={person.imageUrl} alt="" />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
              <p className="text-sm leading-6 text-gray-600">{person.role}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* Values section */}
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our values</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            These core values guide our decision-making and shape the development of Reebee.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {values.map((value) => (
            <div key={value.name}>
              <dt className="font-semibold text-gray-900">{value.name}</dt>
              <dd className="mt-1 text-gray-600">{value.description}</dd>
            </div>
          ))}
        </dl>
      </div>

      {/* CTA section */}
      <div className="relative isolate -z-10 mt-32 sm:mt-48">
        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Ready to take control of your health journey?
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Join Reebee today and start tracking your symptoms to gain valuable insights into your health.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href="/signup"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started
            </a>
            <a href="/contact" className="text-sm font-semibold leading-6 text-gray-900">
              Contact us <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;