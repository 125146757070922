// TimeRangePicker.js
import React from 'react';

const TimeRangePicker = ({ selectedRange, onRangeChange }) => {
  const ranges = [
    { value: '1d', label: 'D' },
    { value: '1w', label: 'W' },
    { value: '1m', label: 'M' },
    { value: '6m', label: '6M' },
    { value: '1y', label: 'Y' },
  ];

  return (
    <div className="flex justify-center bg-gray-100 rounded-full p-1 mb-4">
      {ranges.map((range) => (
        <button
          key={range.value}
          className={`px-4 py-2 rounded-full text-sm font-medium ${
            selectedRange === range.value
              ? 'bg-gray-600 text-white'
              : 'text-gray-400 hover:text-white'
          }`}
          onClick={() => onRangeChange(range.value)}
        >
          {range.label}
        </button>
      ))}
    </div>
  );
};

export default TimeRangePicker;
