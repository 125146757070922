import React from 'react';

const AmountIndicator = ({ amount }) => {
  return (
    <div className="flex items-end h-3 ml-2">
      {[1, 2, 3, 4, 5].map((value) => (
        <div 
          key={value} 
          className={`w-1 bg-purple-500 rounded-sm ${value <= amount ? `h-${value}` : 'h-1'} ${value > 1 ? 'ml-px' : ''}`}
        />
      ))}
    </div>
  );
};

export default AmountIndicator;