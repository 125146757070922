import React, { useMemo, useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import useScrollDirection from '../hooks/useScrollDirection';

const CorrelationAnalysis = ({ correlationData, onCellClick, onScrollDirectionChange }) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCol, setHoveredCol] = useState(null);
  const containerRef = useRef(null);

  const scrollDirection = useScrollDirection(containerRef);

  useEffect(() => {
    onScrollDirectionChange(scrollDirection);
  }, [scrollDirection, onScrollDirectionChange]);

  const symptoms = useMemo(() => Object.keys(correlationData), [correlationData]);
  const triggers = useMemo(() => {
    const allTriggers = new Set();
    Object.values(correlationData).forEach(symptomData => {
      Object.keys(symptomData).forEach(trigger => allTriggers.add(trigger));
    });
    return Array.from(allTriggers);
  }, [correlationData]);

  const getStrongestCorrelation = (correlations) => {
    if (!correlations || correlations.length === 0) return null;
    return correlations.reduce((strongest, current) => 
      Math.abs(current.correlation) > Math.abs(strongest.correlation) ? current : strongest
    );
  };

  const getColorIntensity = (correlation, sampleSize) => {
    if (sampleSize < 3) return 'rgba(200, 200, 200, 0.3)'; // Light gray for insufficient data
    const absCorrelation = Math.abs(correlation);
    return correlation > 0
      ? `rgba(220, 20, 60, ${absCorrelation * 0.7})`  // Red for positive
      : `rgba(70, 130, 180, ${absCorrelation * 0.7})`; // Blue for negative
  };

  const getCellContent = (strongest) => {
    if (!strongest || strongest.sampleSize < 3) {
      return <span className="text-gray-400 text-xs">Not enough data</span>;
    }
    return <span className="font-semibold">{strongest.correlation.toFixed(2)}</span>;
  };

  return (
    <div ref={containerRef} className="overflow-auto max-w-full max-h-[80vh] relative">
      <table className="w-full border-collapse">
        <thead className="sticky top-0 z-10 bg-white">
          <tr>
            <th className="sticky left-0 z-20 bg-white p-2 text-xs font-semibold text-gray-700 min-w-[100px]">
              Trigger / Symptom
            </th>
            {symptoms.map(symptom => (
              <th key={symptom} className="p-2 text-xs font-semibold text-gray-700 whitespace-nowrap min-w-[100px]">
                {symptom}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {triggers.map((trigger, rowIndex) => (
            <tr key={trigger} onMouseEnter={() => setHoveredRow(rowIndex)} onMouseLeave={() => setHoveredRow(null)}>
              <td className="sticky left-0 z-10 bg-white p-2 text-xs font-medium text-gray-700 whitespace-nowrap min-w-[100px]">
                {trigger}
              </td>
              {symptoms.map((symptom, colIndex) => {
                const correlations = correlationData[symptom][trigger];
                const strongest = getStrongestCorrelation(correlations);
                
                return (
                  <motion.td 
                    key={symptom}
                    whileHover={{ scale: 1.05 }}
                    onClick={() => onCellClick(symptom, trigger, strongest)}
                    onMouseEnter={() => setHoveredCol(colIndex)}
                    onMouseLeave={() => setHoveredCol(null)}
                    className={`p-2 text-center cursor-pointer transition-colors duration-300 ease-in-out min-w-[100px] h-[100px]
                      ${hoveredRow === rowIndex || hoveredCol === colIndex ? 'bg-gray-100' : ''}`}
                    style={{
                      backgroundColor: getColorIntensity(strongest?.correlation || 0, strongest?.sampleSize),
                    }}
                  >
                    {getCellContent(strongest)}
                  </motion.td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CorrelationAnalysis;