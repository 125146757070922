import React, { useState } from 'react';

const goals = [
    { id: 'triggers', text: 'Understand My Triggers', description: 'Gain insights into what triggers your symptoms.', emoji: '🔍' },
    { id: 'reduce', text: 'Reduce Symptom Severity', description: 'Actively reduce the severity of your symptoms.', emoji: '📉' },
    { id: 'predict', text: 'Predict Flare-Ups', description: 'Use app analytics to anticipate and manage flare-ups.', emoji: '🔮' }
];

const GoalSelection = ({ onUpdate, currentSelection }) => {
    const [selectedGoal, setSelectedGoal] = useState(currentSelection || null);
    const [isValid, setIsValid] = useState(false);


    const handleSelection = (goal) => {
        setSelectedGoal(goal);
        onUpdate({ selectedGoals: goal }); // Update the parent state with the new selection
    };

    return (
        <div className="flex flex-col items-center p-4">
            <h2 className="text-white text-xl font-semibold mb-4">Set Your Goals</h2>
            <div className="w-full">
                {goals.map((goal) => (
                    <button
                        key={goal.id}
                        className={`flex items-center justify-between w-full p-4 mb-2 rounded-lg shadow-md transition-colors duration-300
                                    ${selectedGoal === goal.text ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                        onClick={() => handleSelection(goal.text)}
                    >
                        <span className="text-2xl">{goal.emoji}</span>
                        <div className="flex-grow">
                            <h3 className="text-lg font-semibold">{goal.text}</h3>                      
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default GoalSelection;
