// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDgWd-GWbsLBT2yYcUV4dT7AwZN4Qt7FzI",
  authDomain: "reebe-69943.firebaseapp.com",
  projectId: "reebe-69943",
  storageBucket: "reebe-69943.appspot.com",
  messagingSenderId: "421629201198",
  appId: "1:421629201198:web:fdd20213005bfc080c88bf"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Auth
const auth = getAuth();

// Initialize Functions
const functions = getFunctions(app);

export { db, auth, functions };