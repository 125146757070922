import React from 'react';

const PatientOverview = ({ userData }) => {
  console.log('USER DATA',userData)
    if (!userData) {
    return null;
  }

  const dataCollectionStart = userData.dataCollectionStart 
    ? new Date(userData.dataCollectionStart.seconds * 1000) 
    : new Date();
  
  return (
    <section className="mb-8 bg-white p-4 rounded-lg shadow">
      <h2 className="text-2xl font-semibold mb-4">Patient Overview</h2>
      <p><strong>Name:</strong> {userData.displayName || 'N/A'}</p>
      <p><strong>Email:</strong> {userData.email || 'N/A'}</p>
      <p><strong>Data collection started:</strong> {dataCollectionStart.toLocaleDateString()}</p>
      <p><strong>Total entries:</strong> {userData.totalEntries || 'N/A'}</p>
    </section>
  );
};

export default PatientOverview;