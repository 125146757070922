import React from 'react';

const EnvironmentalDataDisplay = ({ 
    location, 
    weatherData, 
    aqiData, 
    pollenData, 
    locationLoading, 
    weatherLoading, 
    aqiLoading, 
    pollenLoading,
    locationError,
    weatherError,
    aqiError,
    pollenError
}) => {
    if (locationLoading || weatherLoading || aqiLoading || pollenLoading) {
        return <p>Loading environmental data...</p>;
    }

    return (
        <div className="bg-white p-4 rounded-md shadow-sm">
            <h3 className="text-lg font-semibold mb-2">Current Environmental Data</h3>
            {locationError ? (
                <p>Error loading location: {locationError}</p>
            ) : location ? (
                <div>
                    <p>Location: {location.latitude.toFixed(4)}, {location.longitude.toFixed(4)}</p>
                </div>
            ) : null}
            
            {weatherError ? (
                <p>Error loading weather data: {weatherError}</p>
            ) : weatherData ? (
                <div>
                    <p>Temperature: {weatherData.main?.temp}°C</p>
                    <p>Humidity: {weatherData.main?.humidity}%</p>
                    <p>Conditions: {weatherData.weather?.[0]?.description}</p>
                </div>
            ) : null}
            
            {aqiError ? (
                <p>Error loading AQI data: {aqiError}</p>
            ) : aqiData?.indexes ? (
                <div>
                    <p>AQI: {aqiData.indexes.find(index => index.code === 'uaqi')?.aqi || 'N/A'}</p>
                </div>
            ) : null}
            
            {pollenError ? (
                <p>Error loading pollen data: {pollenError}</p>
            ) : pollenData?.dailyInfo?.[0]?.pollenTypeInfo ? (
                <div>
                    <p>Pollen Levels:</p>
                    <ul>
                        {pollenData.dailyInfo[0].pollenTypeInfo.map(pollen => (
                            <li key={pollen.code}>
                                {pollen.displayName}: {pollen.indexInfo?.category || 'N/A'}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}

            {!location && !weatherData && !aqiData && !pollenData && (
                <p>No environmental data available.</p>
            )}
        </div>
    );
};

export default EnvironmentalDataDisplay;