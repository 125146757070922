import { useState, useEffect } from 'react';
import { startOfDay, endOfDay, eachDayOfInterval, isWithinInterval, addDays, addWeeks, addMonths } from 'date-fns';

const useQuestActionCount = (quest, entries) => {
  const [actionData, setActionData] = useState({
    dailyActions: [],
    totalActionCount: 0,
    symptomOccurrences: 0
  });

  useEffect(() => {
    if (!quest || !entries.length) {
      console.log('Quest or entries not available, skipping action count');
      return;
    }

    console.log('Counting actions for quest:', quest);
    console.log('Total entries:', entries.length);

    const countActions = () => {
      const startDate = new Date(quest.createdAt);
      let endDate;

      // Calculate end date based on duration
      switch (quest.duration.unit) {
        case 'day':
          endDate = addDays(startDate, quest.duration.value);
          break;
        case 'week':
          endDate = addWeeks(startDate, quest.duration.value);
          break;
        case 'month':
          endDate = addMonths(startDate, quest.duration.value);
          break;
        default:
          console.error('Unknown duration unit:', quest.duration.unit);
          endDate = new Date(); // Fallback to current date
      }

      // If the calculated end date is in the future, use current date instead
      const currentDate = new Date();
      if (endDate > currentDate) {
        endDate = currentDate;
      }

      const days = eachDayOfInterval({ start: startDate, end: endDate });

      console.log('Quest date range:', startDate, 'to', endDate);
      console.log('Total days to check:', days.length);

      let totalSymptomOccurrences = 0;

      const dailyActions = days.map(day => {
        const dayStart = startOfDay(day);
        const dayEnd = endOfDay(day);
        const dayEntries = entries.filter(entry => 
          isWithinInterval(new Date(entry.createdAt), { start: dayStart, end: dayEnd })
        );

        let actionCount = 0;
        let symptomCount = 0;

        dayEntries.forEach(entry => {
          if (entry.type === 'symptom') {
            const matchingSymptoms = entry.values.filter(value => 
              quest.selectedSymptoms.includes(value.value)
            );
            symptomCount += matchingSymptoms.length;
          } else if (entry.type === quest.something.type) {
            const matchingValues = entry.values.filter(value => 
              (typeof value === 'object' ? value.value : value).toLowerCase() === quest.something.value.toLowerCase()
            );
            actionCount += matchingValues.length;
          }
        });

        totalSymptomOccurrences += symptomCount;

        console.log(`Actions for ${day.toISOString().split('T')[0]}:`, actionCount);
        console.log(`Symptoms for ${day.toISOString().split('T')[0]}:`, symptomCount);

        return { date: day, actionCount, symptomCount };
      });

      const totalActionCount = dailyActions.reduce((total, day) => total + day.actionCount, 0);

      console.log('Total action count:', totalActionCount);
      console.log('Total symptom occurrences:', totalSymptomOccurrences);

      return { dailyActions, totalActionCount, symptomOccurrences: totalSymptomOccurrences };
    };

    setActionData(countActions());
  }, [quest, entries]);

  return actionData;
};

export default useQuestActionCount;