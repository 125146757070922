import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Introduction</h2>
          <p className="mb-4">
            This Privacy Policy explains how Reebee collects, stores, uses, transfers, and shares personal data from our users ("you") in connection with the Reebee mobile application and related services.
          </p>
          <p className="mb-4">
            We may change this Privacy Policy from time to time. If the changes are material, we will notify you by email or through the app. Your continued use of the Services after this policy has been updated indicates your acceptance of the changes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Personal Data We Collect from You</h2>
          <p className="mb-4">We collect personal data about you when you interact with the Services. This can be directly from you or from other sources and third parties.</p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>General information:</strong> Name, email address, birth date, password, place of residence, and location information.</li>
            <li><strong>Health and Well-being:</strong> Information about your allergies, symptoms, reactions, medications, diet, activities, and other health-related data.</li>
            <li><strong>Third-party services:</strong> With your consent, we may connect to third-party services, such as Apple HealthKit and Google Health Connect, to import health and activity data.</li>
          </ul>
          <p className="mb-4"><strong>Personal data we collect automatically:</strong></p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Device information:</strong> Device model, operating system, unique device identifiers, mobile operator, and network information.</li>
            <li><strong>Location information:</strong> IP address for approximate location, time zone, and mobile service provider.</li>
            <li><strong>Usage data:</strong> Frequency of use, areas and features accessed, payment transaction information, and engagement with features.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">How We Use Your Personal Data</h2>
          <p className="mb-4">We process your personal data based on one or more of the following legal bases:</p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Your consent:</strong> For health data to provide the Services.</li>
            <li><strong>Contractual obligations:</strong> To manage your Reebee account and other administrative purposes.</li>
            <li><strong>Legitimate interest:</strong> To improve our Services, fix bugs, and enhance user experience.</li>
            <li><strong>Legal obligation:</strong> To comply with applicable laws and regulations.</li>
          </ul>
          <p className="mb-4">We use your data to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Provide personalized allergy management insights and recommendations.</li>
            <li>Improve the accuracy of predictions and app content.</li>
            <li>Send relevant app content, product offers, and promotional communications.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Sharing Anonymous Data</h2>
          <p className="mb-4">
            For users who opt in, we may share anonymized data with broader communities, including academic institutions and research organizations, to contribute to scientific research and community insights.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Your Privacy Rights</h2>
          <p className="mb-4">
            You have the right to access, modify, correct, erase, and update your personal data. You can also withdraw your consent for data processing. To exercise your privacy rights, email us at grey@epigenesis.us.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Third Parties Processing Your Personal Data</h2>
          <p className="mb-4">We will not share your personal data with third parties except as specified in this Privacy Policy.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Anonymous Mode</h2>
          <p className="mb-4">Reebee offers Anonymous Mode, allowing you to use the app without your name, email address, or technical identifiers being associated with your data.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Retention of Your Personal Data</h2>
          <p className="mb-4">We retain your personal data as long as needed to provide the Services. You can deactivate your account or request data deletion by contacting us. We may retain certain data for legal compliance or legitimate business purposes.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Security of Your Personal Data</h2>
          <p className="mb-4">We implement technical and organizational measures to protect your personal data from loss, theft, misuse, and unauthorized access.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Children's Privacy</h2>
          <p className="mb-4">The Services are not intended for children under 13 years old. We do not knowingly collect personal data from children under 13. If you are aware of anyone under 13 using the Services, please contact us at grey@epigenesis.us.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Communication with You</h2>
          <p className="mb-4">We may contact you via email or push notifications to communicate about Services, offers, promotions, and events. You can opt out of marketing communications by unsubscribing via the link in the email.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Storage and International Personal Data Transfers</h2>
          <p className="mb-4">Personal data we collect is transferred to and processed in the US and other countries. We apply appropriate safeguards to ensure your personal data is protected during these transfers.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Us</h2>
          <p className="mb-4">If you have any questions or concerns about your privacy, contact us at:</p>
          <address className="not-italic">
            <p><strong>Epigenesis LLC</strong></p>
            <p>Richmond, Virginia, USA</p>
            <p>Email: <a href="mailto:grey@epigenesis.us" className="text-purple-600 underline">grey@epigenesis.us</a></p>
          </address>
        </section>

        <p className="mt-8 text-gray-600">
          By using our service, you agree to this Privacy Policy. If you have any questions, please <Link to="/contact" className="text-blue-600 hover:underline">contact us</Link>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;