// SymptomEntry.js
import React from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import entryData from '../../../entry-inputs-two.json';
import SystemSymptoms from './SystemSymptoms';
import SystemIcon from '../../SystemIcon';

function SymptomEntry({ onOpenModal, customSymptoms, selectedSystem, setSelectedSystem, selectedSymptoms, setSelectedSymptoms }) {
    const { user } = useAuthContext();

    const toggleSystemVisibility = systemName => {
        if (selectedSystem === systemName) {
            setSelectedSystem(null);
            setSelectedSymptoms([]);
        } else {
            setSelectedSystem(systemName);
            setSelectedSymptoms([]);  // Clear symptoms when changing system
        }
    };

    const systems = [...entryData.systems, { name: 'Custom', symptoms: customSymptoms }];

    return (
        <div className="space-y-3">
            <div className="overflow-hidden bg-white px-4 py-4 sm:rounded-md sm:px-6">
                <div className="flex justify-between items-center">

                </div>
                <div className="grid grid-cols-3 gap-3 mt-2">
                    {systems.map(system => (
                        <button key={system.name}
                            onClick={() => toggleSystemVisibility(system.name)}
                            type="button"
                            className={`flex items-center space-x-1 rounded-lg border p-3 ${
                                selectedSystem === system.name ? 'border-purple-500 bg-purple-100' : 'border-gray-300 bg-white'
                            }`}>
                            <SystemIcon system={system.name} isActive={selectedSystem === system.name} size="small" />
                            <span className="text-sm font-medium">{system.name}</span>
                        </button>
                    ))}
                </div>
            </div>
            {systems.map(system => (
                selectedSystem === system.name && 
                <SystemSymptoms 
                    key={system.name} 
                    system={system} 
                    visible={true} 
                    onOpenModal={system.name === 'Custom' ? onOpenModal : undefined}
                    selectedSymptoms={selectedSymptoms}
                    setSelectedSymptoms={setSelectedSymptoms}
                />
            ))}
        </div>
    );
}

export default SymptomEntry;