import React from 'react';
import OnboardingChart from './OnboardingChart';

// SymptomEducation.js
const SymptomEducation = ({ step }) => {
    return (
        <div className="space-y-4">
            <h2 className="text-xl font-bold text-center text-gray-700">Symptoms</h2>
            <OnboardingChart step={step} />
        </div>
    );
};

export default SymptomEducation;
