import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';
import { HomeIcon, PencilIcon, BeakerIcon, ShareIcon, ChartBarIcon } from '@heroicons/react/24/outline';

export default function Navbar() {
    const { user } = useAuthContext();
    const { logout } = useLogout();

    const navigation = [
        { name: 'Home', href: '/home', icon: HomeIcon },
        { name: 'Journal', href: '/journal', icon: PencilIcon },
        // { name: 'Match', href: '/match', icon: BeakerIcon },
        { name: 'Explore', href: '/explore', icon: ChartBarIcon },
        { name: 'Connect', href: '/connect', icon: ShareIcon },
    ];

    return (
        <nav className="hidden sm:block bg-purple-500 text-white shadow w-full">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <h1 className="text-xl font-bold">Reebee</h1>
                        </div>
                        <div className="ml-10 flex items-baseline space-x-4">
                            {navigation.map((item) => (
                                <NavLink
                                    key={item.name}
                                    to={item.href}
                                    className={({ isActive }) =>
                                        isActive ? 'bg-purple-700 text-white px-3 py-2 rounded-md text-sm font-medium' : 'text-white hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                                    }
                                >
                                    <item.icon className="h-5 w-5 inline-block mr-2" />
                                    {item.name}
                                </NavLink>
                            ))}
                        </div>
                    </div>
                    <div className="ml-6 flex items-center">
                        {user && (
                            <NavLink to="/profile" className="flex items-center">
                                <img
                                    src={user.photoURL || "https://via.placeholder.com/40"}
                                    alt="Profile"
                                    className="h-10 w-10 rounded-full"
                                />
                            </NavLink>
                        )}
                        <button
                            onClick={logout}
                            className="bg-red-600 hover:bg-red-700 text-white px-3 py-2 rounded-md text-sm font-medium ml-4"
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}