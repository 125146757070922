import { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import BeeIcon from '../images/ReeBee'; // Adjusted path to BeeIcon

function AuthTabs() {
  const location = useLocation(); // Hook to access the current URL location
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Adjusting the tab based on URL path
  useEffect(() => {
    setSelectedIndex(location.pathname.includes('/signup') ? 1 : 0);
  }, [location.pathname]);

  return (
    <div className="min-h-screen flex items-start justify-center px-4 pt-10  bg-gray-50">
      <div className="max-w-md w-full space-y-8 bg-white p-6 rounded-lg border" style={{ minHeight: '640px' }}>
        <div className="flex justify-center items-center space-x-2">
          <BeeIcon />
          <h2 className="text-3xl font-bold text-gray-900">Welcome!</h2>
        </div>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex space-x-1 rounded-xl p-1">
            <Tab
              className={({ selected }) =>
                `w-full py-2 text-3xl font-extrabold leading-8 ${
                  selected
                    ? 'text-gray-900 border-b-2 border-purple-600'
                    : 'font-normal text-gray-400 hover:text-gray-600'
                }`
              }
            >
              Log In
            </Tab>
            <Tab
              className={({ selected }) =>
                `w-full py-2 text-3xl font-extrabold leading-8 ${
                  selected
                    ? 'text-gray-900 border-b-2 border-purple-600'
                    : 'font-normal text-gray-400 hover:text-gray-600'
                }`
              }
            >
              Sign Up
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-4 overflow-hidden">
            <Tab.Panel className="p-4">
              <Login />
            </Tab.Panel>
            <Tab.Panel className="p-4">
              <Signup />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default AuthTabs;
