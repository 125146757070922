import React, { useMemo, useState } from 'react';
import { calculateCorrelations } from '../utils/correlationAnalysis';

const DetailedCorrelationTable = ({ entries }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const correlations = useMemo(() => calculateCorrelations(entries), [entries]);

  const symptoms = Object.keys(correlations);
  const triggers = Object.keys(correlations[symptoms[0]] || {});

  const getColorForCorrelation = (correlation) => {
    const absCorrelation = Math.abs(correlation);
    const hue = correlation > 0 ? 0 : 240; // Red for positive, blue for negative
    const saturation = 70;
    const lightness = 100 - (absCorrelation * 80);
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  return (
    <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Detailed Correlation Table</h2>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="mb-4 bg-blue-500 text-white px-4 py-2 rounded"
      >
        {isExpanded ? 'Collapse' : 'Expand'}
      </button>
      {isExpanded && (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border p-2">Symptom / Trigger</th>
                {symptoms.map((symptom) => (
                  <th key={symptom} className="border p-2">{symptom}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {triggers.map((trigger) => (
                <tr key={trigger}>
                  <td className="border p-2 font-medium">{trigger}</td>
                  {symptoms.map((symptom) => {
                    const correlation = correlations[symptom][trigger];
                    return (
                      <td
                        key={`${trigger}-${symptom}`}
                        className="border p-2 text-center"
                        style={{ backgroundColor: getColorForCorrelation(correlation) }}
                      >
                        {correlation.toFixed(2)}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
};

export default DetailedCorrelationTable;