
import React from 'react';

const HeartInactive = () => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#F0F0F0"/>
<path d="M28.9485 21.077H27.7499C26.5089 21.077 25.8885 21.077 25.3741 21.3876C24.8599 21.6983 24.5405 22.2659 23.9022 23.401L23.8559 23.4832C23.2612 24.5406 22.9638 25.0694 22.5312 25.0606C22.0985 25.052 21.8198 24.5117 21.2628 23.4312L18.7391 18.5361C18.2197 17.5285 17.96 17.0248 17.5451 17.0009C17.1303 16.977 16.8213 17.448 16.2032 18.3899L15.7799 19.0352C15.1234 20.0356 14.7951 20.5359 14.3081 20.8063C13.8211 21.077 13.2487 21.077 12.1039 21.077H11" stroke="#737373" strokeWidth="2" strokeLinecap="round"/>
<path d="M19.9779 10.9789L19.1578 11.796C19.3697 12.0399 19.6668 12.1784 19.9779 12.1784C20.289 12.1784 20.5861 12.0399 20.798 11.796L19.9779 10.9789ZM16.1479 32.2348C13.9824 30.344 11.4539 27.8644 9.47622 25.1504C7.47727 22.4071 6.16225 19.5885 6.16225 16.995H3.92188C3.92188 20.3947 5.60852 23.7422 7.7099 26.6261C9.83252 29.5391 12.503 32.1475 14.7321 34.094L16.1479 32.2348ZM6.16225 16.995C6.16225 12.6291 8.04103 9.70871 10.4199 8.64896C12.7683 7.60281 16.0241 8.18977 19.1578 11.796L20.798 10.1618C17.2107 6.03362 12.9987 4.90192 9.55936 6.43405C6.15057 7.95259 3.92188 11.9075 3.92188 16.995H6.16225ZM25.2238 34.094C27.4528 32.1477 30.1232 29.5391 32.2459 26.6261C34.3472 23.7424 36.0339 20.3947 36.0339 16.9949H33.7935C33.7935 19.5885 32.4784 22.4071 30.4796 25.1504C28.5019 27.8644 25.9734 30.344 23.8079 32.2348L25.2238 34.094ZM36.0339 16.9949C36.0339 11.9073 33.8052 7.95243 30.3964 6.43392C26.9571 4.90184 22.745 6.03363 19.1578 10.1618L20.798 11.796C23.9317 8.18977 27.1874 7.60274 29.5359 8.64885C31.9147 9.70855 33.7935 12.6289 33.7935 16.9949H36.0339ZM14.7321 34.094C16.6303 35.7515 17.9481 36.9696 19.9779 36.9696V34.5706C18.9003 34.5706 18.2285 34.0515 16.1479 32.2348L14.7321 34.094ZM23.8079 32.2348C21.7273 34.0515 21.0557 34.5706 19.9779 34.5706V36.9696C22.0077 36.9696 23.3254 35.7515 25.2238 34.094L23.8079 32.2348Z" fill="#737373"/>
</svg>

)

export default HeartInactive
