import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';

const useFetchSingleEntry = (userId, entryId) => {
  const [entry, setEntry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const manipulateDate = (timestamp) => {
    if (!timestamp) {
      return null;
    }
    if (timestamp instanceof Date) {
      return timestamp;
    }
    const unixTimestamp = (timestamp.seconds + timestamp.nanoseconds * 10**-9) * 1000;
    return new Date(unixTimestamp);
  };

  useEffect(() => {
    const fetchEntry = async () => {
      try {
        if (!userId || !entryId) {
          throw new Error("User ID and Entry ID are required");
        }
        const docRef = doc(db, `users/${userId}/entries`, entryId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          const manipulatedEntry = {
            id: docSnap.id,
            ...data,
            setAt: manipulateDate(data.setAt),
            createdAt: manipulateDate(data.createdAt),
            updatedAt: manipulateDate(data.updatedAt),
          };
          setEntry(manipulatedEntry);
        } else {
          throw new Error("Entry not found");
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching entry:", err.message);
        setError(err);
        setLoading(false);
      }
    };

    fetchEntry();
  }, [userId, entryId]);

  return { entry, loading, error };
};

export default useFetchSingleEntry;