import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import entryData from '../../entry-inputs-two.json';
import SystemIcon from '../SystemIcon';
import ManageValuesModal from '../form/ManageValuesModal';
import useFetchCustomItems from '../hooks/useFetchCustomItems';
import { Switch } from '@headlessui/react';

const SomethingComponent = ({ something, setSomething, isLocked, goal }) => {
  const { user } = useAuthContext();
  const [selectedCategory, setSelectedCategory] = useState(something.type || null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(true);  // Default to Add
  
  const { customItems, loading, error, fetchCustomItems } = useFetchCustomItems(selectedCategory);

  const getCategories = () => {
    const baseCategories = [
      { name: "foods", icon: "Food" },
      { name: "activities", icon: "Activities" },
      { name: "medications", icon: "Medications" },
    ];
    
    if (goal === "Identify my triggers" || goal === "Track a possible trigger") {
      baseCategories.push({ name: "moods", icon: "Mood" });
    }
    
    return baseCategories;
  };

  const categories = getCategories();

  useEffect(() => {
    if (selectedCategory && !isLocked) {
      fetchCustomItems(selectedCategory);
    }
  }, [selectedCategory, fetchCustomItems, isLocked]);

  const handleCategoryClick = (category) => {
    if (!isLocked) {
      setSelectedCategory(category);
      setSomething(prev => ({ ...prev, type: category, value: null }));
    }
  };

  const handleItemClick = (item) => {
    if (!isLocked) {
      setSomething(prev => ({ ...prev, value: item.value, modification: isAdding ? "Add" : "Remove" }));
    }
  };

  const handleModificationToggle = () => {
    if (!isLocked && goal === "Monitor a therapeutic approach") {
      setIsAdding(!isAdding);
      setSomething(prev => ({ ...prev, modification: !isAdding ? "Add" : "Remove" }));
    }
  };

  const handleOpenModal = () => {
    if (!isLocked) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleValueAdded = (newValue) => {
    if (!isLocked) {
      fetchCustomItems(selectedCategory);
    }
  };

  const handleValueArchived = (archivedValue) => {
    if (!isLocked) {
      fetchCustomItems(selectedCategory);
      if (something.value === archivedValue) {
        setSomething(prev => ({ ...prev, value: null }));
      }
    }
  };

  const getPillClass = (isSelected) => {
    return `py-2 px-4 rounded-full text-sm transition duration-150 ease-in-out border
            ${isSelected ? 'bg-purple-300 text-black border-purple-700' : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'}
            ${isLocked ? 'pointer-events-none' : ''}`;
  };


  if (isLocked) {
    return (
      <div className="bg-purple-100 border border-purple-300 rounded-lg p-4 shadow-sm">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <svg className="h-6 w-6 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-lg font-medium text-purple-900">Identifying Triggers: Observation Phase</h3>
            <div className="mt-2 text-sm text-purple-800">
              <p>
                In this phase, focus on observing and recording your symptoms, activities, foods, medications, and moods without making changes. This will help you:
              </p>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>Establish a baseline of your current state</li>
                <li>Identify patterns in your symptoms and potential triggers</li>
                <li>Gather data to make informed decisions about future actions</li>
                <li>Prepare for more targeted approaches in future quests</li>
              </ul>
              <p className="mt-2">
                Use the app to log your daily experiences. This information will be valuable for identifying trends and potential triggers.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
        <h2 className="text-lg font-semibold mb-2">Select a category:</h2>
        <div className={`grid grid-cols-2 ${categories.length > 3 ? 'sm:grid-cols-4' : 'sm:grid-cols-3'} gap-3`}>
          {categories.map((category) => (
            <button
              key={category.name}
              onClick={() => handleCategoryClick(category.name)}
              className={`flex items-center justify-center space-x-2 rounded-lg border p-3 ${
                selectedCategory === category.name ? 'border-purple-500 bg-purple-100' : 'border-gray-300 bg-white'
              }`}
            >
              <SystemIcon type={category.name} size="small" />
              <span className="text-sm font-medium">{category.name}</span>
            </button>
          ))}
        </div>
      </div>
      
      {selectedCategory && (
        <div className="bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Select a specific item:</h2>
            {goal === "Monitor a therapeutic approach" && (
              <div className="flex items-center">
                <span className={`mr-2 text-sm font-medium ${!isAdding ? 'text-purple-700' : 'text-gray-500'}`}>Remove</span>
                <Switch
                  checked={isAdding}
                  onChange={handleModificationToggle}
                  className={`${isAdding ? 'bg-purple-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2`}
                >
                  <span className="sr-only">Toggle modification</span>
                  <span
                    className={`${
                      isAdding ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
                <span className={`ml-2 text-sm font-medium ${isAdding ? 'text-purple-700' : 'text-gray-500'}`}>Add</span>
              </div>
            )}
          </div>
          <div className="flex flex-wrap gap-2">
            {loading ? (
              <p>Loading items...</p>
            ) : error ? (
              <p>Error loading items. Please try again.</p>
            ) : (
              <>
                {[...entryData[selectedCategory], ...(customItems[selectedCategory] || [])].map((item, index) => (
                  <button
                    key={index}
                    onClick={() => handleItemClick(item)}
                    className={getPillClass(something.value === item.value)}
                  >
                    {item.value}
                  </button>
                ))}
                <button
                  type="button"
                  onClick={handleOpenModal}
                  className="py-2 px-4 rounded-full border border-purple-500 text-purple-500 text-sm font-medium flex items-center"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                  </svg>
                  Add Custom Item
                </button>
              </>
            )}
          </div>
        </div>
      )}
      
      <ManageValuesModal
        type={selectedCategory}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onValueAdded={handleValueAdded}
        onValueArchived={handleValueArchived}
      />
    </div>  );
};

export default SomethingComponent;