import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { db } from '../../firebase/config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useAuthContext } from '../hooks/useAuthContext';
import AmountSlider from './AmountSlider';
import SystemIcon from '../SystemIcon';
import ManageValuesModal from './ManageValuesModal';
import entryData from '../../entry-inputs-two.json';

function ItemEntry({ type, setIsFormDirty }) {
    const { setValue, watch, getValues } = useFormContext();
    const { user } = useAuthContext();
    const items = require('../../entry-inputs-two.json')[type];
    const watchedItems = watch(type) || [];
    const [userItems, setUserItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (!user) return;
        fetchUserItems();
    }, [user, type]);

    
    const fetchUserItems = async () => {
        const q = query(
            collection(db, `users/${user.uid}/${type}`),
            where("isArchived", "==", false)
        );
        const querySnapshot = await getDocs(q);
        const fetchedItems = querySnapshot.docs.map(doc => ({
            value: doc.data().value,
            icon: doc.data().icon || ''
        }));
        setUserItems(fetchedItems);
    };

    const toggleItem = item => {
        const currentValues = getValues(type) || [];
        const index = currentValues.findIndex(v => v.value === item.value);
        if (index > -1) {
            currentValues.splice(index, 1);
            setSelectedItem(currentValues.length > 0 ? currentValues[currentValues.length - 1] : null);
        } else {
            const newItem = { value: item.value, amount: 1 };
            currentValues.push(newItem);
            setSelectedItem(newItem);
        }
        setValue(type, currentValues, { shouldDirty: true });
        setIsFormDirty(true);
    };

    const handleAmountChange = (amount) => {
        if (selectedItem) {
            const currentValues = getValues(type) || [];
            const updatedValues = currentValues.map(item => 
                item.value === selectedItem.value ? { ...item, amount } : item
            );
            setValue(type, updatedValues, { shouldDirty: true });
            setSelectedItem({ ...selectedItem, amount });
            setIsFormDirty(true);
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleValueAdded = (newValue) => {
        setUserItems([...userItems, newValue]);
    };

    const handleValueArchived = (archivedValue) => {
        setUserItems(userItems.filter(item => item.value !== archivedValue));
        const currentValues = getValues(type) || [];
        const updatedValues = currentValues.filter(item => item.value !== archivedValue);
        setValue(type, updatedValues, { shouldDirty: true });
        setIsFormDirty(true);
        if (selectedItem && selectedItem.value === archivedValue) {
            setSelectedItem(updatedValues.length > 0 ? updatedValues[updatedValues.length - 1] : null);
        }
    };

    const combinedItems = Array.from(new Set([...items, ...userItems]));

    const getAmountSignal = (amount) => {
        return (
            <div className="flex items-end h-3 ml-2">
                {[1, 2, 3, 4, 5].map((value) => (
                    <div key={value} className={`w-1 bg-purple-500 rounded-sm ${value <= amount ? `h-${value}` : 'h-1'} ${value > 1 ? 'ml-px' : ''}`}></div>
                ))}
            </div>
        );
    };

    const getPillClass = (item) => {
        const isSelected = watchedItems.some(v => v.value === item.value);
        return `py-2 px-4 rounded-full text-sm transition duration-150 ease-in-out border flex items-center justify-between
                ${isSelected ? 'bg-purple-100 text-purple-800 border-purple-300' : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'}`;
    };

    const getValueSelector = (itemValue) => {
        const item = entryData[type].find(i => i.value === itemValue);
        return item ? item.valueSelector : null;
    };

    return (
        <div className="space-y-3">
            <div className="overflow-hidden bg-white px-2 py-4 shadow sm:rounded-md sm:px-4">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-bold flex items-center space-x-2">
                        <SystemIcon type={type} size="medium" />
                        <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                    </h2>
                    {selectedItem && (
                        <div className="w-40"> {/* Adjusted width for mobile */}
                            <AmountSlider
                                value={selectedItem.amount}
                                onChange={handleAmountChange}
                                valueSelector={getValueSelector(selectedItem.value)}
                            />
                        </div>
                    )}
                </div>
                <div className="flex flex-wrap gap-2 mt-4">
                    {combinedItems.map((item, index) => {
                        const isSelected = watchedItems.some(v => v.value === item.value);
                        const itemData = watchedItems.find(v => v.value === item.value);
                        return (
                            <button
                                key={index}
                                type="button"
                                className={getPillClass(item)}
                                onClick={() => toggleItem(item)}
                            >
                                <span>{item.value}</span>
                                {isSelected && getAmountSignal(itemData.amount)}
                            </button>
                        );
                    })}
                    <button
                        type="button"
                        onClick={handleOpenModal}
                        className="py-2 px-4 rounded-full border border-purple-500 text-purple-500 text-sm font-medium flex items-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                        Add
                    </button>
                </div>
            </div>
            <ManageValuesModal
                type={type}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onValueAdded={handleValueAdded}
                onValueArchived={handleValueArchived}
            />
        </div>
    );
}

export default ItemEntry;