import React from 'react';

const Food = () => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#F0F0F0"/>
<path d="M8.86523 19.5535L8.89004 20.3224C8.94461 22.0017 9.53992 23.6363 10.6164 25.0452C11.6483 26.3971 13.087 27.5059 14.7812 28.2525C15.1582 28.4187 15.5972 28.2475 15.7609 27.8705C15.9271 27.4934 15.756 27.0544 15.3789 26.8907C13.9155 26.2458 12.6777 25.2958 11.7971 24.1423C11.0778 23.1998 10.6189 22.1406 10.4478 21.0417H29.3638C29.1902 22.153 28.7239 23.2221 27.9896 24.1746C27.0942 25.3354 25.8366 26.2879 24.3483 26.9254C23.9713 27.0867 23.7977 27.5257 23.9589 27.9027C24.0804 28.1855 24.3533 28.3542 24.6435 28.3542C24.7427 28.3542 24.8419 28.3343 24.9362 28.2946C28.5205 26.7518 30.8149 23.6983 30.9216 20.3224L30.9464 19.5535H8.86523Z" fill="#737373"/>
<path d="M21.8816 27.1462H17.9376C17.0645 27.1462 16.3477 27.8606 16.3477 28.7362C16.3477 29.6118 17.062 30.3262 17.9376 30.3262H21.8816C22.7547 30.3262 23.4716 29.6118 23.4716 28.7362C23.4716 27.8606 22.7547 27.1462 21.8816 27.1462ZM21.8816 28.8354H17.9376C17.8855 28.8354 17.8359 28.7858 17.8359 28.7337C17.8359 28.6817 17.8855 28.632 17.9376 28.632H21.8816C21.9337 28.632 21.9833 28.6817 21.9833 28.7337C21.9833 28.7858 21.9337 28.8354 21.8816 28.8354Z" fill="#737373"/>
<path d="M20.8261 14.114C19.6677 12.9829 18.1546 12.3578 16.5646 12.3578C14.9747 12.3578 13.4616 12.9804 12.3032 14.114C11.1597 15.2326 10.4676 16.7432 10.3535 18.3655C10.3238 18.7748 10.6338 19.1319 11.0431 19.1592C11.4524 19.1865 11.8096 18.8789 11.8369 18.4697C11.9237 17.2096 12.4595 16.0413 13.3425 15.1781C14.2206 14.3198 15.3641 13.8461 16.5646 13.8461C17.7627 13.8461 18.9087 14.3198 19.7868 15.1781C20.6698 16.0413 21.2031 17.2096 21.2924 18.4697C21.3197 18.8616 21.6471 19.1617 22.0341 19.1617C22.0514 19.1617 22.0688 19.1617 22.0862 19.1592C22.4954 19.1295 22.8055 18.7748 22.7757 18.3655C22.6616 16.7432 21.9696 15.2326 20.8261 14.114ZM29.4011 16.8722C29.1158 16.391 28.714 15.9644 28.208 15.6022L29.5449 14.1561C29.8227 13.8535 29.8054 13.3822 29.5028 13.1044C29.2001 12.8266 28.7289 12.8439 28.451 13.1466L26.8387 14.8928C26.7147 14.8482 26.5882 14.806 26.4592 14.7688L28.5031 10.8199C28.6916 10.4553 28.5503 10.0063 28.1831 9.81778C27.8185 9.62926 27.3696 9.77065 27.181 10.1378L24.9189 14.5108C24.7824 14.5034 24.6435 14.4984 24.5046 14.4984C23.9614 14.4984 23.4256 14.558 22.9097 14.677C22.5103 14.7688 22.2598 15.1681 22.3516 15.57C22.4433 15.9693 22.8427 16.2199 23.2445 16.1281C23.6489 16.0338 24.073 15.9867 24.5021 15.9867C25.5836 15.9867 26.5907 16.2794 27.3373 16.8127C28.0095 17.2914 28.3791 17.9041 28.3791 18.5366C28.3791 18.9484 28.7115 19.2808 29.1232 19.2808C29.535 19.2808 29.8674 18.9484 29.8674 18.5366C29.8674 17.9537 29.7111 17.3931 29.4011 16.8722Z" fill="#737373"/>
</svg>


)

export default Food