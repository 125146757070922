// hooks/useFetchCustomItems.js
import { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuthContext } from './useAuthContext';

export const useFetchCustomItems = (initialCategory) => {
  const [customItems, setCustomItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuthContext();

  const fetchCustomItems = useCallback(async (category) => {
    if (!user || !category) {
      console.log('No user or category, skipping fetch');
      return;
    }
    setLoading(true);
    setError(null);
    console.log(`Fetching custom items for category: ${category}`);
    try {
      const q = query(
        collection(db, `users/${user.uid}/${category}`),
        where("isArchived", "==", false)
      );
      const querySnapshot = await getDocs(q);
      const fetchedItems = querySnapshot.docs.map(doc => ({
        id: doc.id,
        value: doc.data().value
      }));
      console.log(`Fetched ${fetchedItems.length} items for ${category}`);
      setCustomItems(prev => ({ ...prev, [category]: fetchedItems }));
    } catch (err) {
      console.error('Error fetching custom items:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (initialCategory) {
      fetchCustomItems(initialCategory);
    }
  }, [initialCategory, fetchCustomItems]);

  return { customItems, loading, error, fetchCustomItems };
};

export default useFetchCustomItems;