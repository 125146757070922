import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { collection, doc, setDoc, deleteDoc, getDocs, query, where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const DataSharingManagement = ({ userId }) => {
  const { user } = useAuthContext();
  const [sharedEmails, setSharedEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSharedEmails = async () => {
      if (user && user.uid === userId) {
        try {
          setIsLoading(true);
          const sharesRef = collection(db, 'dataShares');
          const q = query(sharesRef, where('ownerId', '==', userId));
          const querySnapshot = await getDocs(q);
          setSharedEmails(querySnapshot.docs.map(doc => ({ id: doc.id, email: doc.data().sharedWithEmail })));
        } catch (err) {
          console.error('Error fetching shared emails:', err);
          setError('Failed to load shared emails. Please try again.');
        } finally {
          setIsLoading(false);
        }
      } else {
        setError('Unauthorized access');
        setIsLoading(false);
      }
    };

    fetchSharedEmails();
  }, [user, userId]);

  const addShareEmail = async (e) => {
    e.preventDefault();
    setError('');

    if (!newEmail) {
      setError('Please enter an email address');
      return;
    }

    try {
      const shareId = `${userId}_${newEmail}`;
      await setDoc(doc(db, 'dataShares', shareId), {
        ownerId: userId,
        sharedWithEmail: newEmail,
        createdAt: new Date()
      });

      // Send email notification
      const functions = getFunctions();
      const sendShareRequestEmail = httpsCallable(functions, 'sendShareRequestEmail');
      await sendShareRequestEmail({ recipientEmail: newEmail });

      setSharedEmails([...sharedEmails, { id: shareId, email: newEmail }]);
      setNewEmail('');
    } catch (error) {
      console.error('Error adding share email:', error);
      setError('Failed to add email. Please try again.');
    }
  };

  const removeShareEmail = async (shareId) => {
    try {
      await deleteDoc(doc(db, 'dataShares', shareId));
      setSharedEmails(sharedEmails.filter(share => share.id !== shareId));
    } catch (error) {
      console.error('Error removing share email:', error);
      setError('Failed to remove email. Please try again.');
    }
  };

  if (isLoading) {
    return <div>Loading shared emails...</div>;
  }

  return (
    <div className="mt-4">
      <h2 className="text-xl font-semibold mb-4">Manage Data Sharing</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={addShareEmail} className="mb-4">
        <input
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          placeholder="Enter email to share data"
          className="p-2 border rounded mr-2"
        />
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Add
        </button>
      </form>
      {sharedEmails.length > 0 ? (
        <ul className="space-y-2">
          {sharedEmails.map(share => (
            <li key={share.id} className="flex justify-between items-center bg-gray-100 p-2 rounded">
              <span>{share.email}</span>
              <button
                onClick={() => removeShareEmail(share.id)}
                className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No emails shared yet.</p>
      )}
    </div>
  );
};

export default DataSharingManagement;