import React, { useMemo, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

const Trends = ({ entries, onAddFilter, selectedFilters }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  console.log('Trends component render. selectedFilters:', selectedFilters);

  const topTrends = useMemo(() => {
    console.log('Calculating topTrends. Entries:', entries);
    const trendData = {};
    
    entries.forEach(entry => {
      if (!entry) return;

      let values = entry.type === 'environmental' 
        ? [
            { value: 'Temperature', amount: entry.weather?.main?.temp },
            { value: 'Humidity', amount: entry.weather?.main?.humidity },
            { value: 'AQI', amount: entry.aqi?.indexes?.[0]?.aqi }
          ].filter(v => v.amount !== undefined)
        : entry.values || [];

      values.forEach(value => {
        if (!value || !value.value) return;

        const valueKey = value.value;
        const amount = value.amount || 0;

        if (!trendData[valueKey]) {
          trendData[valueKey] = [];
        }
        trendData[valueKey].push({ date: entry.setAt, amount });
      });
    });

    // Calculate changes and sort
    const changes = Object.entries(trendData).map(([key, data]) => {
      data.sort((a, b) => new Date(b.date) - new Date(a.date));
      const lastTwo = data.slice(0, 2);
      let change = 0;
      let trend = 'up';
      if (lastTwo.length === 2) {
        change = lastTwo[0].amount - lastTwo[1].amount;
        trend = change >= 0 ? 'up' : 'down';
      } else if (lastTwo.length === 1) {
        change = lastTwo[0].amount;
      }
      return { key, change: Math.abs(change), trend };
    });

    const topTrends = changes
      .sort((a, b) => b.change - a.change)
      .slice(0, 5);  // Show top 5 trends

    console.log('Calculated topTrends:', topTrends);
    return topTrends;
  }, [entries]);

  const handleAddFilter = (value) => {
    console.log('handleAddFilter called with value:', value);
    console.log('Current selectedFilters:', selectedFilters);

    if (!selectedFilters) {
      console.error('selectedFilters is undefined');
      return;
    }

    const existingFilter = selectedFilters.find(filter => filter.value === value);
    console.log('Existing filter found:', existingFilter);

    if (existingFilter) {
      console.log('Calling onAddFilter with:', existingFilter);
      onAddFilter(existingFilter);
    } else {
      console.log('No matching filter found for value:', value);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Recent Trends</h2>
        <button
          onClick={toggleExpand}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          {isExpanded ? (
            <ChevronUpIcon className="h-6 w-6" />
          ) : (
            <ChevronDownIcon className="h-6 w-6" />
          )}
        </button>
      </div>
      {isExpanded && (
        <div className="flex flex-wrap gap-2">
          {topTrends.map(({ key, change, trend }) => (
            <button
              key={key}
              onClick={() => handleAddFilter(key)}
              className="bg-gray-100 text-gray-800 rounded-full px-3 py-1 text-sm font-medium flex items-center hover:bg-gray-200 transition-colors duration-200"
            >
              {key}: {change.toFixed(2)}
              {trend === 'up' ? (
                <ArrowUpIcon className="h-4 w-4 ml-1 text-green-500" />
              ) : (
                <ArrowDownIcon className="h-4 w-4 ml-1 text-red-500" />
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Trends;