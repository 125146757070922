import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuthContext } from '../components/hooks/useAuthContext';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import { getFunctions, httpsCallable } from 'firebase/functions';

const UpgradeSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthContext();
  const [status, setStatus] = useState('Verifying payment...');

  useEffect(() => {
    const verifyPaymentAndUpgrade = async () => {
      if (user) {
        try {
          // Extract the session_id from the URL
          const params = new URLSearchParams(location.search);
          const sessionId = params.get('session_id');

          if (!sessionId) {
            setStatus('Error: No session ID found.');
            return;
          }

          // Call a Cloud Function to verify the payment with Stripe
          const functions = getFunctions();
          const verifyPayment = httpsCallable(functions, 'verifyPayment');
          const result = await verifyPayment({ sessionId });

          if (result.data.success) {
            // Update user's premium status in Firestore
            const userRef = doc(db, 'users', user.uid);
            await updateDoc(userRef, {
              isPremium: true,
              premiumSince: new Date()
            });
            setStatus('Upgrade successful! Redirecting...');
            setTimeout(() => navigate('/quests'), 3000);
          } else {
            setStatus('Error: Payment verification failed. Please contact support.');
          }
        } catch (error) {
          console.error('Error verifying payment:', error);
          setStatus('An error occurred. Please try again or contact support.');
        }
      }
    };

    verifyPaymentAndUpgrade();
  }, [user, navigate, location]);

  return (
    <div className="min-h-screen bg-gray-50 pb-10">
      <div className="hidden sm:block">
        <Navbar />
      </div>
      <div className="p-4">
        <div className="flex flex-col items-center justify-center min-h-[80vh]">
          <h1 className="text-4xl font-bold text-purple-600 mb-4">Upgrade Status</h1>
          <p className="text-xl text-gray-700 mb-8">{status}</p>
        </div>
      </div>
      <MobileNav />
    </div>
  );
};

export default UpgradeSuccess;