import React from 'react';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

function FormHeader({ selectedDate, onClose, onDateChange }) {
    const changeDate = (days) => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + days);
        onDateChange(newDate);
    };

    const formatDate = (date) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (date.toDateString() === today.toDateString()) {
            return 'Today';
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        } else if (date.toDateString() === tomorrow.toDateString()) {
            return 'Tomorrow';
        } else {
            return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
        }
    };

    return (
        <div className="bg-white border-b border-gray-200">
            <div className="max-w-3xl mx-auto px-4 py-4">
                <div className="flex justify-between items-center">
                    <h1 className="text-lg font-semibold text-gray-800">Track Your Day</h1>
                    <button 
                        onClick={onClose}
                        className="text-gray-600 hover:bg-gray-100 rounded-full p-1 transition-colors duration-200"
                        type="button"
                    >
                        <XMarkIcon className="h-5 w-5" />
                    </button>
                </div>
                <div className="flex items-center justify-between mt-2">
                    <button onClick={() => changeDate(-1)} className="text-gray-600 hover:bg-gray-100 rounded-full p-1 transition-colors duration-200" type="button">
                        <ChevronLeftIcon className="h-5 w-5" />
                    </button>
                    <h2 className="text-sm font-medium text-gray-700">
                        {selectedDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </h2>
                    <button onClick={() => changeDate(1)} className="text-gray-600 hover:bg-gray-100 rounded-full p-1 transition-colors duration-200" type="button">
                        <ChevronRightIcon className="h-5 w-5" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FormHeader;