import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAuthContext } from '../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import entryData from '../../entry-inputs-two.json';
import SystemSymptoms from './SystemSymptoms';
import SystemIcon from '../SystemIcon';
import ManageValuesModal from './ManageValuesModal';

function SymptomEntry({ setIsFormDirty }) {
    const { setValue, getValues } = useFormContext();
    const { user } = useAuthContext();

    const [visibleSystems, setVisibleSystems] = useState(() => {
        const initialVisibility = {};
        entryData.systems.forEach(system => {
            initialVisibility[system.name] = false;
        });
        return initialVisibility;
    });

    const [customSymptoms, setCustomSymptoms] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchCustomSymptoms = async () => {
        if (!user) return;
        const q = query(
            collection(db, `users/${user.uid}/symptoms`),
            where("isArchived", "==", false)
        );
        const querySnapshot = await getDocs(q);
        const fetchedSymptoms = querySnapshot.docs.map(doc => ({
            id: doc.id,
            value: doc.data().value
        }));
        setCustomSymptoms(fetchedSymptoms);
    };

    useEffect(() => {
        fetchCustomSymptoms();
    }, [user]);

    const toggleSystemVisibility = systemName => {
        setVisibleSystems(prev => ({
            ...prev,
            [systemName]: !prev[systemName]
        }));
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleValueAdded = (newValue) => {
        setCustomSymptoms(prev => [...prev, newValue]);
    };

    const handleValueArchived = (archivedValue) => {
        setCustomSymptoms(customSymptoms.filter(symptom => symptom.value !== archivedValue));
        
        const currentSymptoms = getValues('symptoms.Other') || [];
        const updatedSymptoms = currentSymptoms.filter(symptom => symptom.value !== archivedValue);
        setValue('symptoms.Other', updatedSymptoms, { shouldDirty: true });
        setIsFormDirty(true);
    };

    // Combine Other system symptoms with custom symptoms
    const systems = entryData.systems.map(system => {
        if (system.name === 'Other') {
            return {
                ...system,
                symptoms: [...system.symptoms, ...customSymptoms.map(cs => cs.value)]
            };
        }
        return system;
    });

    return (
        <div className="space-y-3">
            <div className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
                <div className="flex justify-between items-center">
                    <h2 className="text-xl font-bold flex items-center space-x-2">
                        <SystemIcon type="symptoms" size="medium" />
                        <span>Symptoms</span>
                    </h2>
                </div>
                <div className="grid grid-cols-3 gap-3 mt-2">
                    {systems.map(system => (
                        <button key={system.name}
                            onClick={() => toggleSystemVisibility(system.name)}
                            type="button"
                            className={`flex items-center space-x-1 rounded-lg border p-3 ${
                                visibleSystems[system.name] ? 'border-purple-500 bg-purple-100' : 'border-gray-300 bg-white'
                            }`}>
                            <SystemIcon system={system.name} isActive={visibleSystems[system.name]} size="small" />
                            <span className="text-sm font-medium">{system.name}</span>
                        </button>
                    ))}
                </div>
            </div>
            {systems.map(system => (
                visibleSystems[system.name] && 
                <SystemSymptoms 
                    key={system.name} 
                    system={system} 
                    visible={visibleSystems[system.name]} 
                    setIsFormDirty={setIsFormDirty} 
                    onOpenModal={system.name === 'Other' ? handleOpenModal : undefined}
                />
            ))}
            <ManageValuesModal
                type="symptoms"
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onValueAdded={handleValueAdded}
                onValueArchived={handleValueArchived}
            />
        </div>
    );
}

export default SymptomEntry;