import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

const AddEntryButton = ({ highlight = false, onClick }) => {
    const [showTooltip, setShowTooltip] = useState(highlight);

    useEffect(() => {
        if (highlight) {
            setShowTooltip(true);
            const timer = setTimeout(() => {
                setShowTooltip(false);
            }, 10000); // Show tooltip for 10 seconds

            return () => clearTimeout(timer);
        }
    }, [highlight]);

    return (
        <div className="fixed bottom-20 sm:bottom-32 left-0 right-0 z-20 pointer-events-none">
            <div className="max-w-screen-md mx-auto px-4 relative">
                <div className="absolute right-4 bottom-0 pointer-events-auto">
                    {showTooltip && (
                        <div className="absolute -top-14 right-0 bg-purple-600 text-white px-4 py-2 rounded-md shadow-lg whitespace-nowrap font-bold text-sm">
                            Begin tracking your symptoms and much more!
                            <div className="absolute bottom-0 right-4 transform translate-y-1/2 rotate-45 w-3 h-3 bg-purple-600"></div>
                        </div>
                    )}
                    <button
                        onClick={onClick}
                        className="bg-purple-600 hover:bg-purple-700 text-white p-4 rounded-full shadow-lg transition-colors duration-200"
                        aria-label="Add new entry"
                    >
                        <PlusIcon className="h-8 w-8" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddEntryButton;