// QuestEntries.js
import React from 'react';
import { format } from 'date-fns';

const QuestEntries = ({ entries }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow mb-6">
      <h2 className="text-lg font-semibold mb-4">Quest Entries</h2>
      {entries.length === 0 ? (
        <p>No entries found for this quest.</p>
      ) : (
        <ul className="space-y-2">
          {entries.map(entry => (
            <li key={entry.id} className="border-b pb-2">
              <p><strong>Date:</strong> {format(entry.createdAt, 'PPP')}</p>
              <p><strong>Type:</strong> {entry.type}</p>
              {entry.amount && <p><strong>Amount:</strong> {entry.amount}</p>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default QuestEntries;
