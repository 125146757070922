import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../components/hooks/useAuthContext';
import useFetchUserEntries from '../components/hooks/useFetchUserEntries';
import { calculateWindowedCorrelations } from '../components/utils/windowedCorrelationAnalysis';
import CorrelationAnalysis from '../components/patterns/CorrelationAnalysis';
import Modal from '../components/patterns/Modal';
import EntryVisualization from '../components/patterns/EntryVisualization';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import useScrollDirection from '../components/hooks/useScrollDirection';

const Match = () => {
  const { user } = useAuthContext();
  const { entries, loading, error } = useFetchUserEntries(user ? user.uid : null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [correlationData, setCorrelationData] = useState(null);
  const [correlationScrollDirection, setCorrelationScrollDirection] = useState('up');

  const pageScrollDirection = useScrollDirection();

  const combinedScrollDirection = correlationScrollDirection === 'down' || pageScrollDirection === 'down' ? 'down' : 'up';

  useEffect(() => {
    if (entries && entries.length > 0) {
      console.log('Calculating correlations with entries:', entries);
      const correlations = calculateWindowedCorrelations(entries, 7, 24);
      console.log('Correlation calculation complete:', correlations);
      setCorrelationData(correlations);
    }
  }, [entries]);

  const handleCellClick = (symptom, trigger, correlationInfo) => {
    setSelectedCell({ symptom, trigger, correlationInfo });
    setShowModal(true);
  };

  const handleCorrelationScroll = (direction) => {
    setCorrelationScrollDirection(direction);
  };

  if (loading) return <div className="p-4 text-center">Loading...</div>;
  if (error) return <div className="p-4 text-center text-red-500">Error: {error.message}</div>;

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <div className="hidden sm:block">
        <Navbar />
      </div>
      <main className="flex-grow p-4 w-full flex flex-col pb-16 sm:pb-4">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">Correlation Analysis</h1>
        <div className="bg-white rounded-lg shadow-sm mb-4 flex-grow overflow-x-auto">
          {correlationData && (
            <CorrelationAnalysis 
              correlationData={correlationData}
              onCellClick={handleCellClick}
              onScrollDirectionChange={handleCorrelationScroll}
            />
          )}
        </div>
        <div className="text-sm text-gray-700 mt-2 mb-4 space-y-2">
          <p>
            The table above shows correlations between symptoms and potential triggers. 
            Darker shades indicate stronger correlations. Tap a cell for detailed analysis.
          </p>
          <p className="font-medium">
            Note: Correlation does not imply causation. These relationships are observational 
            and may be influenced by various factors not captured in this analysis.
          </p>
        </div>
      </main>
      <MobileNav scrollDirection={combinedScrollDirection} />
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div className="p-4">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">
              {selectedCell.symptom} vs {selectedCell.trigger}
            </h2>
            <EntryVisualization 
              entries={entries}
              symptom={selectedCell.symptom} 
              trigger={selectedCell.trigger}
              correlationInfo={selectedCell.correlationInfo}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Match;