import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import SystemIcon from '../SystemIcon';
import entryData from '../../entry-inputs-two.json';

function JournalEntryCard({ entry }) {
  const navigate = useNavigate();

  if (!entry || entry.type === 'environmental') {
    return null;
  }

  const entryDate = entry.createdAt?.toDate ? entry.createdAt.toDate() : new Date(entry.createdAt);

  const typeConfig = {
    symptom: { bgColor: 'bg-purple-100', textColor: 'text-purple-800', label: 'Symptom' },
    foods: { bgColor: 'bg-green-100', textColor: 'text-green-800', label: 'Food' },
    activities: { bgColor: 'bg-blue-100', textColor: 'text-blue-800', label: 'Activity' },
    medications: { bgColor: 'bg-yellow-100', textColor: 'text-yellow-800', label: 'Medication' },
    moods: { bgColor: 'bg-gray-100', textColor: 'text-gray-800', label: 'Mood' },
    quest: { bgColor: 'bg-purple-100', textColor: 'text-purple-800', label: 'Quest' },
    default: { bgColor: 'bg-gray-100', textColor: 'text-gray-800', label: 'Entry' }
  };

  const getTypeConfig = (type) => {
    return typeConfig[type] || typeConfig.default;
  };

  const handleClick = () => {
    navigate(`/entry/${entry.id}`, { state: { entry } });
  };

  const capitalizeFirstLetter = (string) => {
    return string && typeof string === 'string' ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  };

  const renderEntryValues = () => {
    if (entry.type === 'quest') {
      return (
        <div className="text-sm">
          <div className="font-semibold">{entry.goal || 'No goal specified'}</div>
          <div className="text-gray-600">
            {entry.modification || 'No modification'} {entry.something?.value || 'No value'} ({entry.something?.type || 'No type'})
          </div>
          {entry.selectedSymptoms && entry.selectedSymptoms.length > 0 && (
            <div className="text-gray-500 text-xs mt-1">
              Related to: {entry.selectedSymptoms.join(', ')}
            </div>
          )}
        </div>
      );
    }
    if (entry.type === 'symptom') {
      return (
        <div className="text-sm">
          <span className="font-semibold">{entry.system || 'Unknown system'}: </span>
          {Array.isArray(entry.values) 
            ? entry.values.map((symptom, index) => (
                <span key={index}>
                  {capitalizeFirstLetter(symptom.value || 'Unknown')}
                  {symptom.amount && ` (${symptom.amount})`}
                  {index < entry.values.length - 1 ? ', ' : ''}
                </span>
              ))
            : 'No symptoms recorded'}
        </div>
      );
    }
    return (
      <div className="text-sm">
        {Array.isArray(entry.values) 
          ? entry.values.map((entryValue, index) => (
              <span key={index} className="font-semibold">
                {capitalizeFirstLetter(entryValue.value || 'Unknown')}
                {entryValue.amount && ` (${entryValue.amount})`}
                {index < entry.values.length - 1 ? ', ' : ''}
              </span>
            ))
          : 'No values recorded'}
      </div>
    );
  };

  const getFeelingEmoji = (feeling) => {
    const feelingLevel = entryData.feelingLevels.find(f => f.value === feeling);
    return feelingLevel ? feelingLevel.emoji : '';
  };

  const renderTag = () => {
    const config = getTypeConfig(entry.type);
    return (
      <div className="flex flex-col items-end">
        <span className={`text-xs font-semibold px-2 py-1 rounded ${config.bgColor} ${config.textColor}`}>
          {config.label}
        </span>
        <span className="text-xs text-gray-500 mt-1">
          {formatDistanceToNow(entryDate, { addSuffix: true })}
        </span>
      </div>
    );
  };

  return (
    <div onClick={handleClick} className="block p-3 bg-white hover:bg-gray-50 cursor-pointer border-b last:border-b-0">
      <div className="flex items-start space-x-3">
        <SystemIcon type={entry.type} system={entry.system} isActive={true} size="medium" />
        <div className="flex-1 min-w-0">
          {renderEntryValues()}
          {entry.type !== 'quest' && entry.feeling && (
            <div className="flex items-center mt-1">
              <span className="text-xl mr-2">{getFeelingEmoji(entry.feeling)}</span>
            </div>
          )}
        </div>
        {renderTag()}
      </div>
    </div>
  );
}

export default JournalEntryCard;