import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase/config';
import { collection, addDoc, updateDoc, query, doc, getDocs, Timestamp, where } from 'firebase/firestore';
import { useAuthContext } from '../hooks/useAuthContext';
import { XMarkIcon } from '@heroicons/react/24/outline';

function ManageValuesModal({ type, isOpen, onClose, onValueAdded, onValueArchived }) {
    const { user } = useAuthContext();
    const [values, setValues] = useState([]);
    const [newValue, setNewValue] = useState("");
    const [error, setError] = useState(null);
    const modalRef = useRef(null);

    useEffect(() => {
        if (user && isOpen) {
            fetchValues();
        }
    }, [user, type, isOpen]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isOpen, onClose]);

    const fetchValues = async () => {
        if (!type) {
            console.error('Type parameter not provided');
            return;
        }
        try {
            const q = query(
                collection(db, `users/${user.uid}/${type}`),
                where("isArchived", "==", false)
            );
            const querySnapshot = await getDocs(q);
            const fetchedValues = querySnapshot.docs.map(doc => ({
                id: doc.id,
                value: doc.data().value
            }));
            setValues(fetchedValues);
        } catch (error) {
            console.error('Error fetching values:', error);
        }
    };

    const handleAddValue = async (e) => {
        e.preventDefault();

        if (newValue.trim() === "") {
            setError("Value cannot be blank.");
            return;
        }

        if (newValue.trim().length > 20) {
            setError("Value cannot be more than 20 characters.");
            return;
        }

        if (values.some(item => item.value.toLowerCase() === newValue.trim().toLowerCase())) {
            setError("Value already exists.");
            return;
        }

        try {
            const docRef = await addDoc(collection(db, `users/${user.uid}/${type}`), { 
                value: newValue.trim(), 
                createdAt: Timestamp.now(),
                isArchived: false
            });
            const newItem = { id: docRef.id, value: newValue.trim() };
            setValues([...values, newItem]);
            setNewValue("");
            setError(null);
            onValueAdded(newItem);
        } catch (error) {
            console.error('Error adding value:', error);
            setError('An error occurred while adding the value.');
        }
    };

    const handleArchiveValue = async (id, value, e) => {
        e.preventDefault();
        try {
            await updateDoc(doc(db, `users/${user.uid}/${type}`, id), {
                isArchived: true,
                archivedAt: Timestamp.now()
            });
            setValues(values.filter(item => item.id !== id));
            onValueArchived(value);
        } catch (error) {
            console.error('Error archiving value:', error);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddValue(e);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div ref={modalRef} className="bg-white rounded-lg p-6 w-full max-w-md relative">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                    <XMarkIcon className="h-6 w-6" />
                </button>
                <h2 className="text-2xl font-bold mb-4">Custom {type}</h2>
                <div className="mb-4">
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            value={newValue}
                            onChange={(e) => {
                                setNewValue(e.target.value);
                                setError(null);
                            }}
                            onKeyPress={handleKeyPress}
                            placeholder={`Add new ${type}`}
                            className="flex-grow p-2 text-sm border rounded-md focus:ring-purple-500 focus:border-purple-500"
                        />
                        <button
                            onClick={handleAddValue}
                            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Add
                        </button>
                    </div>
                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                </div>
                <ul className="max-h-60 overflow-y-auto space-y-2">
                    {values.map(item => (
                        <li key={item.id} className="flex justify-between items-center bg-gray-100 p-2 rounded">
                            <span>{item.value}</span>
                            <button
                                onClick={(e) => handleArchiveValue(item.id, item.value, e)}
                                className="text-red-500 hover:text-red-700 ml-2"
                            >
                                Archive
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ManageValuesModal;