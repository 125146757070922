import React, { useEffect, useState } from 'react';
import { doc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuthContext } from '../../components/hooks/useAuthContext';
import entryData from '../../entry-inputs-two.json';
import AmountSlider from '../form/AmountSlider';
import AmountIndicator from './AmountIndicator';
import SystemIcon from '../SystemIcon';

const ValuesEditor = ({ entry, onUpdate }) => {
    const { user } = useAuthContext();
    const [values, setValues] = useState(entry.values || []);
    const [allValues, setAllValues] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const fetchAllValues = async () => {
            let combinedValues = [];
            if (entry.type === 'symptom') {
                const system = entryData.systems.find(s => s.name === entry.system);
                if (system) {
                    combinedValues = system.symptoms.map(symptom => ({ value: symptom, isDefault: true }));
                }
            } else {
                combinedValues = (entryData[entry.type] || []).map(item => ({
                    value: item.value || item,
                    isDefault: true
                }));
            }

            const q = query(collection(db, `users/${user.uid}/${entry.type}`), where("isArchived", "==", false));
            const querySnapshot = await getDocs(q);
            const customValues = querySnapshot.docs.map(doc => ({
                value: doc.data().value,
                isDefault: false
            }));

            setAllValues([...combinedValues, ...customValues]);
        };

        if (entry.type) {
            fetchAllValues();
        }
    }, [entry.type, entry.system, user.uid]);

    const toggleItem = async (item) => {
        let newValues;
        const existingValueIndex = values.findIndex(v => v.value === item.value);
        if (existingValueIndex !== -1) {
            newValues = values.filter(v => v.value !== item.value);
            setSelectedItem(newValues.length > 0 ? newValues[newValues.length - 1] : null);
        } else {
            const newItem = { value: item.value, amount: 1 };
            newValues = [...values, newItem];
            setSelectedItem(newItem);
        }
        setValues(newValues);
        updateEntry(newValues);
    };

    const handleAmountChange = (newAmount) => {
        if (selectedItem) {
            const updatedValues = values.map(v =>
                v.value === selectedItem.value ? { ...v, amount: newAmount } : v
            );
            setValues(updatedValues);
            setSelectedItem({ ...selectedItem, amount: newAmount });
            updateEntry(updatedValues);
        }
    };

    const updateEntry = async (newValues) => {
        try {
            await updateDoc(doc(db, `users/${user.uid}/entries/${entry.id}`), { values: newValues });
            if (onUpdate) {
                onUpdate(newValues);
            }
        } catch (error) {
            console.error('Error updating entry:', error);
        }
    };

    const getPillClass = (item) => {
        const isSelected = values.some(v => v.value === item.value);
        return `py-2 px-4 rounded-full text-sm transition duration-150 ease-in-out border flex items-center justify-between
            ${isSelected ? 'bg-purple-100 text-purple-800 border-purple-300' : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'}`;
    };

    return (
        <div className="mt-4">
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-bold flex items-center space-x-2">
                    <SystemIcon type={entry.type} system={entry.system} size="medium" />
                    <span>{entry.type === 'symptom' ? 'Symptoms' : `${entry.type.charAt(0).toUpperCase() + entry.type.slice(1)}`}</span>
                </h2>
                {selectedItem && (
                    <AmountSlider
                        value={selectedItem.amount}
                        onChange={handleAmountChange}
                    />
                )}
            </div>
            <div className="flex flex-wrap gap-2 mt-4">
                {allValues.map((item, index) => {
                    const selectedValue = values.find(v => v.value === item.value);
                    return (
                        <button
                            key={index}
                            type="button"
                            className={getPillClass(item)}
                            onClick={() => toggleItem(item)}
                        >
                            <span>{item.value}</span>
                            {selectedValue && <AmountIndicator amount={selectedValue.amount} />}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default ValuesEditor;