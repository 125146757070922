import React from 'react';
import BeeIcon from '../../images/ReeBee'; // Ensure this path is correct
import ProgressBar from './ProgressBar';
import { motion } from 'framer-motion';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const CardContainer = ({ step, totalSteps, children, onNext, onBack, isLastStep }) => {
    const buttonText = () => {
        if (isLastStep) return 'Finish';
        if (step === 0) return "Let's Start";
        return 'Continue';
    };

    const promptText = () => {
        switch(step) {
            case 0: return "Welcome to Reebee, your personal allergy guide!";
            case 1: return "Have you noticed your symptoms change?";
            case 2: return "It get's confusing! What really works for you?";
            case 3: return "Reebee is here to help!";
            case 4: return "Let's get started! What are your typical symptoms?";
            case 5: return "How do you manage your symptoms?";
            case 6: return "Nice, having tools to help can make all the difference!"
            case 7: return "One tool Reebee provides is a daily check-ins. Over time, this daily practice can bring you clarity to how you react";
            case 8: return "Consist tracking is key to clarity, could we help you remember?";
            case 9: return "Your data is personal and powerful!";
            default: return "Let's get started!";
        }
    };

    return (
        <div className="flex flex-col justify-between h-screen bg-transparent text-gray-700">
            <div className="fixed top-10 w-full z-100">
                {step > 0 && (
                    <button onClick={onBack} className="absolute top-4 left-4 text-gray-800">
                            <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    )}
                <ProgressBar step={step} totalSteps={totalSteps} />

                <div className="flex justify-between items-center p-4">
                    <BeeIcon />
                    <motion.p
                        variants={{
                            hidden: { x: '100vw', opacity: 0 },
                            visible: { x: 0, opacity: 1, transition: { type: 'tween', ease: "easeOut", duration: 0.5 } },
                            exit: { x: '-100vw', opacity: 0, transition: { ease: 'easeInOut', duration: 0.5 } }
                        }}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="text-lg flex-1 color-gray-900 bg-gray-200 opacity-75 border-2 rounded-md ml-2 p-2 border-purple-500"
                    >
                        {promptText()}
                    </motion.p>
                </div>
            </div>
            <div className="flex-grow flex items-center justify-center overflow-auto pt-24 pb-24">
                {children}
            </div>
            <div className="fixed bottom-10 w-full z-10 bg-gradient-to-br from-blue-400 to-green-300">
                <button
                    className="w-full px-6 py-3 bg-purple-500 text-white rounded-full shadow-md"
                    onClick={onNext}
                    style={{ marginBottom: 'env(safe-area-inset-bottom)' }}
                >
                    {buttonText()}
                </button>
            </div>
        </div>
    );
};

export default CardContainer;
