import React from 'react';
import BeeIcon from '../../images/ReeBee';

const BeeMessageComponent = ({ message }) => {
  return (
    <div className="flex items-center bg-white-100 p-4 rounded-lg mb-4">
      <BeeIcon className="w-12 h-12 mr-4" />
      {/* <p className="text-sm">{message}</p> */}
    </div>
  );
};

export default BeeMessageComponent;