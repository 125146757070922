import { startOfDay, endOfDay, eachDayOfInterval, subWeeks, subMonths, subYears } from 'date-fns';

export const calculateCorrelations = (entries, timeRange) => {
  console.log('Calculating correlations for entries:', entries);
  console.log('Time Range:', timeRange);

  const { start, end } = parseTimeRange(timeRange);
  console.log('Parsed time range - Start:', start, 'End:', end);

  const dailyData = aggregateDataByDay(entries, start, end);
  console.log('Aggregated daily data:', dailyData);
  
  const symptoms = getAllSymptoms(entries);
  const triggers = getAllTriggers(entries);
  const envFactors = getEnvironmentalFactors();
  console.log('Symptoms:', symptoms);
  console.log('Triggers:', triggers);
  console.log('Environmental Factors:', envFactors);

  const correlations = {};
  symptoms.forEach(symptom => {
    correlations[symptom] = {};
    [...triggers, ...envFactors].forEach(factor => {
      const symptomData = dailyData.map(day => day.symptoms[symptom] || 0);
      const factorData = dailyData.map(day => day.triggers[factor] || day.environmental[factor] || 0);
      const { correlation, pValue, sampleSize } = calculateCorrelationWithStats(symptomData, factorData);
      correlations[symptom][factor] = { correlation, pValue, sampleSize };
    });
  });

  console.log('Calculated correlations:', correlations);
  return correlations;
};

const aggregateDataByDay = (entries, start, end) => {
  console.log('Aggregating data by day');
  console.log('Start:', start, 'End:', end);

  const days = eachDayOfInterval({ start, end });

  return days.map(day => {
    console.log('Processing day:', day);
    const dayStart = startOfDay(day);
    const dayEnd = endOfDay(day);
    const dayEntries = entries.filter(entry => {
      const entryDate = new Date(entry.setAt);
      return entryDate >= dayStart && entryDate <= dayEnd;
    });

    console.log('Entries for day:', dayEntries);

    const aggregatedData = { symptoms: {}, triggers: {}, environmental: {} };
    dayEntries.forEach(entry => {
      if (entry.type === 'symptom') {
        entry.values.forEach(value => {
          aggregatedData.symptoms[value.value] = (aggregatedData.symptoms[value.value] || 0) + value.amount;
        });
      } else if (entry.type === 'environmental') {
        if (entry.weather && entry.weather.main) {
          aggregatedData.environmental['temperature'] = entry.weather.main.temp;
          aggregatedData.environmental['humidity'] = entry.weather.main.humidity;
        }
        if (entry.aqi && entry.aqi.indexes && entry.aqi.indexes.length > 0) {
          const aqiIndex = entry.aqi.indexes.find(index => index.code === 'uaqi');
          if (aqiIndex) {
            aggregatedData.environmental['aqi'] = aqiIndex.aqi;
          }
        }
      } else {
        entry.values.forEach(value => {
          aggregatedData.triggers[value.value] = (aggregatedData.triggers[value.value] || 0) + value.amount;
        });
      }
    });

    console.log('Aggregated data for day:', aggregatedData);
    return aggregatedData;
  });
};

const calculateCorrelationWithStats = (x, y) => {
  const n = x.length;
  let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0, sumY2 = 0;

  for (let i = 0; i < n; i++) {
    sumX += x[i];
    sumY += y[i];
    sumXY += x[i] * y[i];
    sumX2 += x[i] * x[i];
    sumY2 += y[i] * y[i];
  }

  const numerator = n * sumXY - sumX * sumY;
  const denominator = Math.sqrt((n * sumX2 - sumX * sumX) * (n * sumY2 - sumY * sumY));

  const correlation = denominator === 0 ? 0 : numerator / denominator;

  // Calculate t-statistic
  const t = correlation * Math.sqrt((n - 2) / (1 - correlation * correlation));

  // Calculate p-value (two-tailed test)
  const pValue = 2 * (1 - tDistribution(Math.abs(t), n - 2));

  return { correlation, pValue, sampleSize: n };
};

// t-distribution function (using Student's t-distribution approximation)
const tDistribution = (t, df) => {
  const x = df / (t * t + df);
  let result = 1;
  for (let j = 3; j <= df; j += 2) {
    result += (1 - x) ** ((j - 1) / 2);
  }
  result *= Math.sqrt(x) / 2;
  return 1 - result;
};

const parseTimeRange = (timeRange) => {
  console.log('Parsing time range:', timeRange);
  const now = new Date();
  let start;
  switch (timeRange) {
    case '1d': start = subWeeks(now, 1); break;
    case '1w': start = subWeeks(now, 1); break;
    case '1m': start = subMonths(now, 1); break;
    case '6m': start = subMonths(now, 6); break;
    case '1y': start = subYears(now, 1); break;
    default: start = subWeeks(now, 1);
  }
  return { start, end: now };
};

const getAllSymptoms = (entries) => {
  const symptoms = new Set();
  entries.forEach(entry => {
    if (entry.type === 'symptom') {
      entry.values.forEach(value => symptoms.add(value.value));
    }
  });
  return Array.from(symptoms);
};

const getAllTriggers = (entries) => {
  const triggers = new Set();
  entries.forEach(entry => {
    if (entry.type !== 'symptom' && entry.type !== 'environmental') {
      entry.values.forEach(value => triggers.add(value.value));
    }
  });
  return Array.from(triggers);
};

const getEnvironmentalFactors = () => {
  return ['temperature', 'humidity', 'aqi'];
};

export const filterEntriesForSymptomAndTrigger = (entries, symptom, trigger, timeRange) => {
  console.log('Filtering entries for symptom:', symptom, 'and trigger:', trigger);
  const { start, end } = parseTimeRange(timeRange);
  
  return entries.filter(entry => {
    if (!entry || !entry.setAt) {
      console.warn('Invalid entry:', entry);
      return false;
    }

    const entryDate = new Date(entry.setAt);
    if (entryDate < start || entryDate > end) {
      return false;
    }

    if (entry.type === 'symptom') {
      return Array.isArray(entry.values) && entry.values.some(v => v.value === symptom);
    } else if (entry.type === 'environmental') {
      return true;
    } else if (entry.type === 'foods' || entry.type === 'activities' || entry.type === 'medications' || entry.type === 'moods') {
      return Array.isArray(entry.values) && entry.values.some(v => v.value === trigger);
    }

    return false;
  });
};

export const prepareScatterData = (filteredEntries, symptom, trigger) => {
  const data = filteredEntries.map(entry => {
    let symptomValue = 0;
    let triggerValue = 0;

    if (entry.type === 'symptom') {
      symptomValue = entry.values?.find(v => v.value === symptom)?.amount || 0;
    } else if (entry.type === 'environmental') {
      if (trigger === 'temperature' && entry.weather?.main?.temp) {
        triggerValue = entry.weather.main.temp;
      } else if (trigger === 'humidity' && entry.weather?.main?.humidity) {
        triggerValue = entry.weather.main.humidity;
      } else if (trigger === 'aqi' && entry.aqi?.indexes) {
        const aqiIndex = entry.aqi.indexes.find(index => index.code === 'uaqi');
        triggerValue = aqiIndex ? aqiIndex.aqi : 0;
      }
    } else {
      triggerValue = entry.values?.find(v => v.value === trigger)?.amount || 0;
    }

    return { x: triggerValue, y: symptomValue, date: new Date(entry.setAt) };
  }).filter(point => point.x !== 0 || point.y !== 0);

  return data;
};