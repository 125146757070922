import { useState, useEffect } from 'react';

const useScrollDirection = (ref = null) => {
  const [scrollDirection, setScrollDirection] = useState("up");
  const [prevOffset, setPrevOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = ref ? ref.current?.scrollTop : window.pageYOffset;
      if (currentOffset === undefined) return;

      const direction = currentOffset > prevOffset ? "down" : "up";

      if (direction !== scrollDirection && (Math.abs(currentOffset - prevOffset) > 10)) {
        setScrollDirection(direction);
      }

      setPrevOffset(currentOffset);
    };

    const targetElement = ref ? ref.current : window;
    
    if (targetElement) {
      targetElement.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        targetElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollDirection, prevOffset, ref]);

  return scrollDirection;
};

export default useScrollDirection;