import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';

const ProfileIcon = () => {
  const { user } = useAuthContext();

  return (
    user && (
      <NavLink to="/profile" className="flex items-center">
        <img
          src={user.photoURL || "https://via.placeholder.com/40"}
          alt="Profile"
          className="h-10 w-10 rounded-full"
        />
      </NavLink>
    )
  );
};

export default ProfileIcon;
