import { useState, useEffect } from 'react';

const useGeolocation = () => {
    const [location, setLocation] = useState(null);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        setIsFetching(true);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
                setError(null);
                setIsFetching(false);
            },
            (e) => {
                setError(e.message);
                setIsFetching(false);
            }
        );
    }, []);

    return { location, error, isFetching };
};

export default useGeolocation;
