import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuthContext } from '../../components/hooks/useAuthContext';
import ValuesEditor from './ValuesEditor';
import useFetchSingleEntry from '../hooks/useFetchSingleEntry';

const EntryPage = () => {
    const { entryId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { entry, loading, error } = useFetchSingleEntry(user?.uid, entryId);

    const [editedDate, setEditedDate] = useState('');
    const [editedTime, setEditedTime] = useState('');

    useEffect(() => {
        if (entry?.setAt) {
            setEditedDate(entry.setAt.toISOString().split('T')[0]);
            setEditedTime(entry.setAt.toTimeString().slice(0, 5));
        }
    }, [entry]);

    const updateEntry = async (updatedFields) => {
        if (!entry || !entry.id) {
            console.error('No entry to update');
            return;
        }
        try {
            await updateDoc(doc(db, `users/${user.uid}/entries/${entry.id}`), updatedFields);
        } catch (error) {
            console.error('Error updating entry:', error);
        }
    };

    const handleDateChange = (event) => {
        setEditedDate(event.target.value);
        updateSetAt(event.target.value, editedTime);
    };

    const handleTimeChange = (event) => {
        setEditedTime(event.target.value);
        updateSetAt(editedDate, event.target.value);
    };

    const updateSetAt = (date, time) => {
        const newSetAt = new Date(`${date}T${time}`);
        const myTimestamp = Timestamp.fromDate(newSetAt);
        updateEntry({ setAt: myTimestamp });
    };

    const handleDelete = async () => {
        if (!entry || !entry.id) {
            console.error('No entry to delete');
            return;
        }
        if (window.confirm('Are you sure you want to delete this entry?')) {
            try {
                await deleteDoc(doc(db, `users/${user.uid}/entries/${entry.id}`));
                navigate(-1);
            } catch (error) {
                console.error('Error deleting entry:', error);
                alert('Failed to delete entry.');
            }
        }
    };

    if (loading) {
        return <div className="text-center text-gray-800">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-red-600">Error: {error.message}</div>;
    }

    if (!entry) {
        return <div className="text-center text-gray-800">No entry found.</div>;
    }

    return (
        <div className="p-4 max-w-4xl mx-auto">
            <div className="flex justify-between items-center mb-4">
                <button onClick={() => navigate(-1)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Back
                </button>
                <button onClick={handleDelete} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    Delete
                </button>
            </div>
            <div className="bg-white rounded-lg shadow px-5 py-4">
                <h1 className="text-xl font-bold">{entry.type.toUpperCase()} Entry Details</h1>
                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Date:
                        <input
                            type="date"
                            value={editedDate}
                            onChange={handleDateChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </label>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Time:
                        <input
                            type="time"
                            value={editedTime}
                            onChange={handleTimeChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </label>
                </div>
                <ValuesEditor entry={entry} onUpdate={(newValues) => updateEntry({ values: newValues })} />
            </div>
        </div>
    );
};

export default EntryPage;