import React from 'react';

const SymptomCount = ({ totalCount }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h2 className="text-lg font-semibold mb-2">Symptom Occurrences</h2>
      <p className="text-3xl font-bold text-red-600">{totalCount}</p>
    </div>
  );
};

export default SymptomCount;