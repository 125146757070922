import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, TimeSeriesScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { eachDayOfInterval, startOfDay, endOfDay, subDays, format } from 'date-fns';

// Register the required controllers
ChartJS.register(TimeSeriesScale, LinearScale, BarElement, Title, Tooltip, Legend, LineController);

const SymptomHistogram = ({ entries }) => {
    const endDate = useMemo(() => endOfDay(new Date()), []);
    const startDate = useMemo(() => startOfDay(subDays(endDate, 29)), [endDate]);

    const histogramData = useMemo(() => {
        const symptomEntries = entries.filter(entry => entry.type === 'symptom');

        const buckets = {};
        symptomEntries.forEach(entry => {
            const entryDate = new Date(entry.setAt);
            if (entryDate < startDate || entryDate > endDate) return;

            const dayKey = startOfDay(entryDate).toISOString();
            if (!buckets[dayKey]) {
                buckets[dayKey] = { count: 0, totalValue: 0, symptoms: 0 };
            }

            if (Array.isArray(entry.values)) {
                entry.values.forEach(symptom => {
                    buckets[dayKey].count += 1;
                    buckets[dayKey].totalValue += symptom.amount || 0;
                    buckets[dayKey].symptoms += 1;
                });
            } else {
                buckets[dayKey].count += 1;
                buckets[dayKey].totalValue += entry.amount || 0;
                buckets[dayKey].symptoms += 1;
            }
        });

        const allDays = eachDayOfInterval({ start: startDate, end: endDate });
        return allDays.map(day => {
            const dayKey = day.toISOString();
            const bucket = buckets[dayKey] || { count: 0, totalValue: 0, symptoms: 0 };
            return {
                x: day,
                y: bucket.count,
                averageValue: bucket.symptoms > 0 ? bucket.totalValue / bucket.symptoms : 0
            };
        });
    }, [entries, startDate, endDate]);

    const data = {
        datasets: [
            {
                label: 'Symptom Count',
                data: histogramData,
                backgroundColor: 'rgba(70, 130, 180, 0.6)', // Steel blue, more muted
                borderColor: 'rgba(70, 130, 180, 1)',
                borderWidth: 1,
                yAxisID: 'y'
            },
            {
                label: 'Average Symptom Value',
                data: histogramData.map(item => ({ x: item.x, y: item.averageValue })),
                type: 'line',
                borderColor: 'rgba(60, 60, 60, 0.8)', // Dark gray, less prominent
                borderWidth: 2,
                pointRadius: 0,
                yAxisID: 'y1'
            }
        ]
    };

    const maxCount = Math.max(...histogramData.map(item => item.y));
    const maxAverage = Math.max(...histogramData.map(item => item.averageValue));

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM d'
                    }
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                min: startDate,
                max: endDate,
                ticks: {
                    source: 'data'
                },
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Symptom Count'
                },
                min: 0,
                max: Math.max(maxCount, 10), // Minimum y-axis max of 10
                ticks: {
                    stepSize: 1
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            },
            y1: {
                position: 'right',
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Average Symptom Value'
                },
                min: 0,
                max: Math.max(maxAverage, 5), // Minimum y-axis max of 5
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: (context) => format(context[0].parsed.x, 'MMM d, yyyy'),
                    label: (context) => {
                        const dataIndex = context.dataIndex;
                        const datasetIndex = context.datasetIndex;
                        if (datasetIndex === 0) {
                            return `Symptom Count: ${histogramData[dataIndex].y}`;
                        } else {
                            return `Average Symptom Value: ${histogramData[dataIndex].averageValue.toFixed(2)}`;
                        }
                    }
                }
            },
            legend: {
                display: true,
                position: 'top'
            }
        }
    };

    return (
        <div className="h-64 w-full">
            <Bar data={data} options={options} />
        </div>
    );
};

export default SymptomHistogram;
