import React from 'react';

const ContactUs = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Contact Us</h1>
        
        <section className="mb-8">
          <p className="mb-4">We're here to help! If you have any questions, concerns, or feedback about Reebee, please don't hesitate to reach out to us.</p>
          
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Information</h2>
          <address className="not-italic">
            <p><strong>Epigenesis LLC</strong></p>
            <p>Richmond, Virginia, USA</p>
            <p>Email: <a href="mailto:grey@epigenesis.us" className="text-purple-600 underline">grey@epigenesis.us</a></p>
          </address>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Support Hours</h2>
          <p>Our support team is available Monday through Friday, 9:00 AM to 5:00 PM Eastern Time.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Feedback</h2>
          <p>We value your feedback! If you have suggestions for improving Reebee, <a href="mailto:grey@epigenesis.us" className="text-purple-600 underline">email me</a>! Your input helps us make Reebee better for everyone.</p>
        </section>
      </div>
    </div>
  );
};

export default ContactUs;