import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SystemIcon from '../SystemIcon';
import AmountSlider from './AmountSlider';
import entryData from '../../entry-inputs-two.json';

function SystemSymptoms({ system, visible, setIsFormDirty, onOpenModal }) {
    const { setValue, watch } = useFormContext();
    const currentSymptoms = watch(`symptoms.${system.name}`) || [];
    const [selectedSymptom, setSelectedSymptom] = useState(null);

    const getValueSelector = (symptomValue) => {
        const symptom = entryData.symptoms.find(s => s.name === symptomValue && s.system === system.name);
        return symptom ? symptom.valueSelector : null;
    };

    const toggleSymptom = (symptom) => {
        const updatedSymptoms = [...currentSymptoms];
        const symptomValue = typeof symptom === 'string' ? symptom : symptom.value;
        const index = updatedSymptoms.findIndex(s => s.value === symptomValue);
        if (index > -1) {
            updatedSymptoms.splice(index, 1);
            setSelectedSymptom(updatedSymptoms.length > 0 ? updatedSymptoms[updatedSymptoms.length - 1] : null);
        } else {
            const newSymptom = { value: symptomValue, amount: 1 };
            updatedSymptoms.push(newSymptom);
            setSelectedSymptom(newSymptom);
        }
        setValue(`symptoms.${system.name}`, updatedSymptoms);
        setIsFormDirty(true);
    };

    const handleAmountChange = (amount) => {
        if (selectedSymptom) {
            const updatedSymptoms = currentSymptoms.map(symptom => 
                symptom.value === selectedSymptom.value ? { ...symptom, amount } : symptom
            );
            setValue(`symptoms.${system.name}`, updatedSymptoms);
            setSelectedSymptom({ ...selectedSymptom, amount });
            setIsFormDirty(true);
        }
    };

    const getAmountSignal = (amount) => {
        return (
            <div className="flex items-end h-3 ml-2">
                {[1, 2, 3, 4, 5].map((value) => (
                    <div key={value} className={`w-1 bg-purple-500 rounded-sm ${value <= amount ? `h-${value}` : 'h-1'} ${value > 1 ? 'ml-px' : ''}`}></div>
                ))}
            </div>
        );
    };

    const getPillClass = (symptom) => {
        const isSelected = currentSymptoms.some(s => s.value === symptom);
        return `py-2 px-4 rounded-full text-sm transition duration-150 ease-in-out border flex items-center justify-between
                ${isSelected ? 'bg-purple-100 text-purple-800 border-purple-300' : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'}`;
    };

    return (
        <div className={`system-symptoms ${visible ? 'visible' : 'hidden'}`}>
            <div className="flex items-center justify-between mb-4">
                <h4 className="flex items-center space-x-2">
                    <SystemIcon systemName={system.name} isActive={visible} />
                    <span>{system.name}</span>
                </h4>
                {selectedSymptom && (
                    <div className="w-40"> {/* Adjusted width for mobile */}
                        <AmountSlider
                            value={selectedSymptom.amount}
                            onChange={handleAmountChange}
                            valueSelector={getValueSelector(selectedSymptom.value)}
                        />
                    </div>
                )}
            </div>
            <div className="flex flex-wrap gap-2 mt-4">
                {system.symptoms.map((symptom, index) => {
                    const symptomValue = typeof symptom === 'string' ? symptom : symptom.value;
                    const isSelected = currentSymptoms.some(s => s.value === symptomValue);
                    const symptomData = currentSymptoms.find(s => s.value === symptomValue);
                    return (
                        <button
                            key={index}
                            type="button"
                            className={getPillClass(symptomValue)}
                            onClick={() => toggleSymptom(symptom)}
                        >
                            <span>{symptomValue}</span>
                            {isSelected && getAmountSignal(symptomData.amount)}
                        </button>
                    );
                })}
                {onOpenModal && (
                    <button
                        type="button"
                        onClick={onOpenModal}
                        className="py-2 px-4 rounded-full border border-purple-500 text-purple-500 text-sm font-medium flex items-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                        Add
                    </button>
                )}
            </div>
        </div>
    );
}

export default SystemSymptoms;