import React, { useMemo } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { startOfDay, endOfDay, eachDayOfInterval, format, isValid } from 'date-fns';

const EnvironmentalFactors = ({ entries, timeRange }) => {
  const environmentalData = useMemo(() => {
    const now = new Date();
    let startDate;
    switch (timeRange) {
      case '1d': startDate = startOfDay(now); break;
      case '1w': startDate = startOfDay(new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)); break;
      case '1m': startDate = startOfDay(new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000)); break;
      case '6m': startDate = startOfDay(new Date(now.getTime() - 180 * 24 * 60 * 60 * 1000)); break;
      case '1y': startDate = startOfDay(new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000)); break;
      default: startDate = startOfDay(new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000));
    }

    const dateRange = eachDayOfInterval({ start: startDate, end: now });

    const data = dateRange.reduce((acc, date) => {
      acc[format(date, 'yyyy-MM-dd')] = { date: format(date, 'yyyy-MM-dd') };
      return acc;
    }, {});

    entries.forEach(entry => {
      if (entry.type === 'environmental' && entry.setAt && isValid(entry.setAt)) {
        const date = format(entry.setAt, 'yyyy-MM-dd');
        if (data[date]) {
          if (entry.weather && entry.weather.main) {
            data[date].temperature = entry.weather.main.temp;
            data[date].humidity = entry.weather.main.humidity;
          }
          if (entry.aqi && entry.aqi.indexes && entry.aqi.indexes.length > 0) {
            const aqiIndex = entry.aqi.indexes.find(index => index.code === 'uaqi');
            if (aqiIndex) {
              data[date].aqi = aqiIndex.aqi;
            }
          }
        }
      }
    });

    return Object.values(data).sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [entries, timeRange]);

  const formatXAxis = (dateStr) => {
    const date = new Date(dateStr);
    switch (timeRange) {
      case '1d': return format(date, 'HH:mm');
      case '1w': return format(date, 'EEE');
      case '1m': return format(date, 'dd MMM');
      case '6m':
      case '1y': return format(date, 'MMM yyyy');
      default: return format(date, 'dd MMM');
    }
  };

  if (environmentalData.length === 0) {
    return (
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Environmental Factors</h2>
        <p>No environmental data available for the selected time range.</p>
      </section>
    );
  }

  return (
    <section className="mb-8 bg-white p-4 rounded-lg shadow">
      <h2 className="text-2xl font-semibold mb-4">Environmental Factors</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={environmentalData}>
          <XAxis 
            dataKey="date" 
            tickFormatter={formatXAxis}
            interval={'preserveStartEnd'}
          />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip 
            labelFormatter={(label) => format(new Date(label), 'PPP')}
          />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="temperature" stroke="#8884d8" name="Temperature (°C)" connectNulls />
          <Line yAxisId="left" type="monotone" dataKey="humidity" stroke="#82ca9d" name="Humidity (%)" connectNulls />
          <Line yAxisId="right" type="monotone" dataKey="aqi" stroke="#ffc658" name="AQI" connectNulls />
        </LineChart>
      </ResponsiveContainer>
    </section>
  );
};

export default EnvironmentalFactors;