import React from 'react';

const ActionCount = ({ totalCount }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h2 className="text-lg font-semibold mb-2">Total Actions</h2>
      <p className="text-3xl font-bold text-purple-600">{totalCount}</p>
    </div>
  );
};

export default ActionCount;