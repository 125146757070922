import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { subDays, format, isAfter, isSameDay, startOfDay } from 'date-fns';

const QuestSymptomChart = ({ quest, entries }) => {
  const chartData = useMemo(() => {
    const today = new Date();
    const sixDaysAgo = startOfDay(subDays(today, 6));

    // Initialize data for the last 7 days
    const data = Array.from({ length: 7 }, (_, index) => {
      const date = subDays(today, 6 - index);
      return {
        date: format(date, 'MMM dd'),
        fullDate: date,
        ...quest.selectedSymptoms.reduce((acc, symptom) => {
          acc[symptom.toLowerCase()] = 0;
          return acc;
        }, {}),
      };
    });

    // Filter and count symptom entries
    entries.forEach(entry => {
      const entryDate = new Date(entry.setAt);
      if (entry.type === 'symptom' && isAfter(entryDate, sixDaysAgo)) {
        const dayIndex = data.findIndex(day => isSameDay(day.fullDate, entryDate));
        if (dayIndex !== -1) {
          entry.values.forEach(symptomObj => {
            const symptom = symptomObj.value.toLowerCase();
            if (quest.selectedSymptoms.some(s => s.toLowerCase() === symptom)) {
              data[dayIndex][symptom]++;
            }
          });
        }
      }
    });

    return data;
  }, [quest, entries]);

  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE', '#00C49F'];

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={chartData}>
        <XAxis dataKey="date" tick={{ fontSize: 10 }} />
        <YAxis tick={{ fontSize: 10 }} />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: 10 }} />
        {quest.selectedSymptoms.map((symptom, index) => (
          <Bar 
            key={symptom} 
            dataKey={symptom.toLowerCase()} 
            name={symptom}
            stackId="a" 
            fill={colors[index % colors.length]} 
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default QuestSymptomChart;