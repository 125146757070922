// hooks/useFetchUserCustomSymptoms.js
import { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuthContext } from './useAuthContext';

export const useFetchUserCustomSymptoms = () => {
  const [customSymptoms, setCustomSymptoms] = useState([]);
  const { user } = useAuthContext();

  useEffect(() => {
    const fetchCustomSymptoms = async () => {
      if (!user) return;
      const q = query(
        collection(db, `users/${user.uid}/symptoms`),
        where("isArchived", "==", false)
      );
      const querySnapshot = await getDocs(q);
      const fetchedSymptoms = querySnapshot.docs.map(doc => ({
        id: doc.id,
        value: doc.data().value
      }));
      setCustomSymptoms(fetchedSymptoms);
    };

    fetchCustomSymptoms();
  }, [user]);

  return { customSymptoms, setCustomSymptoms };
};

export default useFetchUserCustomSymptoms;