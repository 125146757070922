import React from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const MyResponsiveLine = ({ data, lines, step }) => {
  return (
    <div style={{ position: 'relative' }}>
      <ResponsiveContainer width={500} height={300}>
        <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="Uv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#f7d455" stopOpacity={0.8}/>  // Bright Yellow
              <stop offset="95%" stopColor="#f7d455" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>  // Purple
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="uv" stroke="#f7d455" fillOpacity={1} fill="url(#Uv)" name="Baseline" />
          {step >= 2 && (
            <Area type="monotone" dataKey="pv" stroke="#8884d8" fillOpacity={1} fill="url(#colorPv)" name="w/ Immunotherapy" />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MyResponsiveLine;
