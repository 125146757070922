import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay, isToday, subMonths, addMonths } from 'date-fns';

function MonthlyCalendar({ selectedDate, onDateChange, entries = [], onDayClick }) {
    const startDate = startOfMonth(selectedDate);
    const endDate = endOfMonth(selectedDate);
    const daysInMonth = eachDayOfInterval({ start: startDate, end: endDate });

    const goToPreviousMonth = () => {
        onDateChange(subMonths(selectedDate, 1));
    };

    const goToNextMonth = () => {
        onDateChange(addMonths(selectedDate, 1));
    };

    const hasEntries = (date) => {
        return entries.some(entry => isSameDay(new Date(entry.setAt), date));
    };

    const hasSymptoms = (date) => {
        return entries.some(entry => isSameDay(new Date(entry.setAt), date) && entry.type === 'symptom');
    };

    return (
        <div className="bg-white shadow rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
                <button 
                    onClick={goToPreviousMonth}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    aria-label="Previous month"
                >
                    <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
                </button>
                <h2 className="text-lg font-semibold text-gray-800">
                    {format(startDate, 'MMMM yyyy')}
                </h2>
                <button 
                    onClick={goToNextMonth}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    aria-label="Next month"
                >
                    <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                </button>
            </div>
            <div className="grid grid-cols-7 gap-2">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                    <div key={day} className="text-center font-medium text-gray-500">
                        {day}
                    </div>
                ))}
                {daysInMonth.map((date, index) => {
                    const isSelected = isSameDay(date, selectedDate);
                    const isTodayDate = isToday(date);
                    const isCurrentMonth = isSameMonth(date, selectedDate);
                    const hasEntriesForDay = hasEntries(date);
                    const hasSymptomsForDay = hasSymptoms(date);

                    return (
                        <div 
                            key={index}
                            className={`relative p-2 text-center cursor-pointer ${
                                !isCurrentMonth ? 'text-gray-400' :
                                isSelected ? 'bg-purple-100 text-purple-800' :
                                isTodayDate ? 'bg-blue-50 text-blue-600' : 'text-gray-800'
                            } hover:bg-gray-100 rounded-lg`}
                            onClick={() => onDayClick(date)}
                        >
                            {hasEntriesForDay && (
                                <CheckCircleIcon className="absolute top-0 right-0 h-4 w-4 text-purple-500 m-1" />
                            )}
                            <span className={isSelected ? 'font-bold' : ''}>{format(date, 'd')}</span>
                            {hasSymptomsForDay && (
                                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-red-500 rounded-full"></div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default MonthlyCalendar;