import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebase/config';

const useFetchUserQuests = (userId) => {
  const [quests, setQuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const manipulateDate = (timestamp) => {
    if (!timestamp) {
      return null;
    }
    if (timestamp instanceof Date) {
      return timestamp;
    }
    const unixTimestamp = (timestamp.seconds + timestamp.nanoseconds * 10**-9) * 1000;
    return new Date(unixTimestamp);
  };

  useEffect(() => {
    async function fetchQuests() {
      if (!userId) {
        setError(new Error("No user ID provided"));
        setLoading(false);
        return;
      }
      const q = query(
        collection(db, `users/${userId}/quests`), 
        where("active", "==", true),
        orderBy("createdAt", "desc")
      );
      try {
        const querySnapshot = await getDocs(q);
        const fetchedQuests = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: manipulateDate(data.createdAt),
          };
        });
        setQuests(fetchedQuests);
      } catch (err) {
        console.error("Error fetching quests:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchQuests();
  }, [userId]);

  return { quests, loading, error };
};

export default useFetchUserQuests;