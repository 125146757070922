// SystemSymptoms.js
import React from 'react';
import SystemIcon from '../../SystemIcon';

function SystemSymptoms({ system, visible, onOpenModal, selectedSymptoms, setSelectedSymptoms }) {
    const toggleSymptom = (symptom) => {
        const symptomValue = typeof symptom === 'string' ? symptom : symptom.value;
        setSelectedSymptoms(prevSymptoms => {
            if (prevSymptoms.includes(symptomValue)) {
                return prevSymptoms.filter(s => s !== symptomValue);
            } else {
                return [...prevSymptoms, symptomValue];
            }
        });
    };

    const getPillClass = (isSelected) => {
        return `py-2 px-4 rounded-full text-sm transition duration-150 ease-in-out border
                ${isSelected ? 'bg-purple-300 text-black border-purple-700' : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'}`;
    };

    return (
        <div className={`system-symptoms ${visible ? 'visible' : 'hidden'}`}>
            <div className="flex items-center justify-between mb-4">
                <h4 className="flex items-center space-x-2 text-lg font-semibold">
                    <SystemIcon systemName={system.name} isActive={true} />
                    <span>{system.name} Symptoms</span>
                </h4>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
                {system.symptoms.map((symptom, index) => {
                    const symptomValue = typeof symptom === 'string' ? symptom : symptom.value;
                    return (
                        <button key={typeof symptom === 'string' ? symptom : symptom.id}
                                onClick={() => toggleSymptom(symptom)}
                                type="button"
                                className={getPillClass(selectedSymptoms.includes(symptomValue))}>
                            {symptomValue}
                        </button>
                    );
                })}
                {onOpenModal && (
                    <button
                        type="button"
                        onClick={onOpenModal}
                        className="py-2 px-4 rounded-full border border-purple-500 text-purple-500 text-sm font-medium flex items-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                        Add Custom Symptom
                    </button>
                )}
            </div>
        </div>
    );
}

export default SystemSymptoms;