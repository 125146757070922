import React, { useMemo } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { format, subDays, isValid } from 'date-fns';

const TopSymptoms = ({ entries }) => {
  const topSymptoms = useMemo(() => {
    const symptomCounts = {};
    const last30Days = subDays(new Date(), 30);

    entries.forEach(entry => {
      if (entry.type === 'symptom' && entry.setAt && isValid(entry.setAt)) {
        if (entry.setAt >= last30Days) {
          entry.values.forEach(symptom => {
            symptomCounts[symptom.value] = (symptomCounts[symptom.value] || 0) + 1;
          });
        }
      }
    });

    return Object.entries(symptomCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([name, count]) => ({ name, count, trend: generateTrendData(entries, name, last30Days) }));
  }, [entries]);

  return (
    <section className="mb-8 bg-white p-4 rounded-lg shadow">
      <h2 className="text-2xl font-semibold mb-4">Top Symptoms</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {topSymptoms.map((symptom) => (
          <div key={symptom.name} className="border p-4 rounded-lg">
            <h3 className="text-lg font-medium">{symptom.name}</h3>
            <p>Count: {symptom.count}</p>
            <ResponsiveContainer width="100%" height={50}>
              <LineChart data={symptom.trend}>
                <XAxis dataKey="date" hide />
                <YAxis hide />
                <Tooltip />
                <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} dot={false} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ))}
      </div>
    </section>
  );
};

const generateTrendData = (entries, symptomName, last30Days) => {
  const trendData = {};
  entries.forEach(entry => {
    if (entry.type === 'symptom' && entry.setAt && isValid(entry.setAt) && entry.setAt >= last30Days) {
      const date = format(entry.setAt, 'yyyy-MM-dd');
      const symptom = entry.values.find(s => s.value === symptomName);
      if (symptom) {
        trendData[date] = (trendData[date] || 0) + symptom.amount;
      }
    }
  });

  return Object.entries(trendData).map(([date, value]) => ({ date, value }));
};

export default TopSymptoms;