import React, { useState } from 'react';
import SkinActive from '../../images/icons/Skin_Active';
import SkinInactive from '../../images/icons/Skin_Inactive';
import MouthThroatActive from '../../images/icons/MouthThroat_Active';
import MouthThroatInactive from '../../images/icons/MouthThroat_Inactive';
import HeartActive from '../../images/icons/Heart_Active';
import HeartInactive from '../../images/icons/Heart_Inactive';
import GutActive from '../../images/icons/Gut_Active';
import GutInactive from '../../images/icons/Gut_Inactive';
import BrainActive from '../../images/icons/Brain_Active';
import BrainInactive from '../../images/icons/Brain_Inactive';
import AirwaysActive from '../../images/icons/Airways_Active';
import AirwaysInactive from '../../images/icons/Airways_Inactive';

const ButtonSelection = ({ options, onUpdate }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const getIcon = (optionName, isActive) => {
        switch(optionName) {
            case 'Skin Symptoms':
                return isActive ? <SkinActive className="w-6 h-6" /> : <SkinInactive className="w-6 h-6" />;
            case 'Mouth/Throat Symptoms':
                return isActive ? <MouthThroatActive className="w-6 h-6" /> : <MouthThroatInactive className="w-6 h-6" />;
            case 'Heart Symptoms':
                return isActive ? <HeartActive className="w-6 h-6" /> : <HeartInactive className="w-6 h-6" />;
            case 'Gut Symptoms':
                return isActive ? <GutActive className="w-6 h-6" /> : <GutInactive className="w-6 h-6" />;
            case 'Brain Symptoms':
                return isActive ? <BrainActive className="w-6 h-6" /> : <BrainInactive className="w-6 h-6" />;
            case 'Airways Symptoms':
                return isActive ? <AirwaysActive className="w-6 h-6" /> : <AirwaysInactive className="w-6 h-6" />;
            default:
                return <SkinInactive className="w-6 h-6" />;
        }
    };

    const handleSelection = (option) => {
        const newSelectedOptions = selectedOptions.includes(option.name)
            ? selectedOptions.filter(o => o !== option.name)
            : [...selectedOptions, option.name];
        setSelectedOptions(newSelectedOptions);
        onUpdate({ selectedOptions: newSelectedOptions });
    };

    return (
        <div className="flex flex-wrap justify-center items-center gap-2 p-4">
            {options.map(option => (
                <div
                    key={option.name}
                    onClick={() => handleSelection(option)}
                    className={`w-full sm:w-auto flex items-center px-6 py-3 mb-2 rounded-lg font-semibold transition-all duration-300
                                ${selectedOptions.includes(option.name) ? 'bg-gray-100 border-2 border-purple-500' : 'bg-gray-100 hover:bg-gray-200'}`}
                >
                    {getIcon(option.name, selectedOptions.includes(option.name))}
                    <span className="ml-4">{option.name}</span>
                </div>
            ))}
        </div>
    );
};

export default ButtonSelection;
