import React, { useMemo } from 'react';

const MedicationsAndTreatments = ({ entries }) => {
  const medicationsAndTreatments = useMemo(() => {
    const medicationCounts = {};
    entries.forEach(entry => {
      if (entry.type === 'medications') {
        entry.values.forEach(medication => {
          if (!medicationCounts[medication.value]) {
            medicationCounts[medication.value] = { count: 0, totalAmount: 0 };
          }
          medicationCounts[medication.value].count += 1;
          medicationCounts[medication.value].totalAmount += medication.amount;
        });
      }
    });
    return Object.entries(medicationCounts).map(([name, data]) => ({
      name,
      count: data.count,
      averageAmount: data.totalAmount / data.count
    }));
  }, [entries]);

  return (
    <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Medications and Treatments</h2>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="border p-2">Medication/Treatment</th>
              <th className="border p-2">Frequency</th>
              <th className="border p-2">Average Amount</th>
            </tr>
          </thead>
          <tbody>
            {medicationsAndTreatments.map(item => (
              <tr key={item.name}>
                <td className="border p-2">{item.name}</td>
                <td className="border p-2">{item.count}</td>
                <td className="border p-2">{item.averageAmount.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default MedicationsAndTreatments;