import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import WeeklyCalendar from '../components/journal/WeeklyCalendar';
import MonthlyCalendar from '../components/journal/MonthlyCalendar';
import DayCalendar from '../components/journal/DayCalendar';
import FormComponent from '../components/form/FormComponent';
import { useAuthContext } from '../components/hooks/useAuthContext';
import useFetchUserEntries from '../components/hooks/useFetchUserEntries';
import ProfileIcon from '../components/ProfileIcon';
import AddEntryButton from '../components/journal/AddEntryButton';

export default function JournalTwo() {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(() => new Date());
    const [viewMode, setViewMode] = useState('weekly'); // 'weekly' or 'monthly'
    const { entries, loading, error, refetchEntries } = useFetchUserEntries(user ? user.uid : null);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [highlightAddEntry, setHighlightAddEntry] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const filterEntriesForSelectedDate = useCallback((allEntries, date) => {
        const startOfDay = new Date(date);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(startOfDay.getTime() + 86400000 - 1);
        return allEntries.filter(entry => {
            return entry.setAt >= startOfDay && entry.setAt <= endOfDay;
        }).sort((a, b) => a.setAt - b.setAt);
    }, []);

    useEffect(() => {
        if (!user) navigate('/login');
    }, [user, navigate]);

    useEffect(() => {
        setFilteredEntries(filterEntriesForSelectedDate(entries, selectedDate));
    }, [entries, selectedDate, filterEntriesForSelectedDate]);

    useEffect(() => {
        const handlePopState = (event) => {
            if (isModalOpen) {
                setShowForm(false);
                setIsModalOpen(false);
                event.preventDefault();
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isModalOpen]);

    const handleSelectEntry = entryId => {
        navigate(`/edit-entry/${entryId}`);
    };

    const handleNewEntry = () => {
        setShowForm(true);
        setIsModalOpen(true);
        window.history.pushState({ modal: true }, '');
    };

    const handleDateChange = newDate => {
        setSelectedDate(newDate);
    };

    const handleFormClose = async () => {
        setShowForm(false);
        setIsModalOpen(false);
        window.history.back();
        await refetchEntries();
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="min-h-screen flex flex-col">
            <div className="hidden sm:block">
                <Navbar />
            </div>

            <div className="flex-grow p-4 pb-24 overflow-y-auto">
                <div className="mb-4 flex justify-between items-center">
                    <h1 className="text-2xl font-bold">Journal</h1>
                    <div className="flex items-center bg-gray-100 rounded-full p-1">
                        <button
                            onClick={() => setViewMode('weekly')}
                            className={`p-1 rounded-full transition-colors duration-200 ${
                                viewMode === 'weekly' ? 'bg-white shadow' : 'text-gray-500 hover:text-gray-700'
                            }`}
                            aria-label="Weekly view"
                        >
                            <CalendarDaysIcon className="h-6 w-6" />
                        </button>
                        <button
                            onClick={() => setViewMode('monthly')}
                            className={`p-1 rounded-full transition-colors duration-200 ${
                                viewMode === 'monthly' ? 'bg-white shadow' : 'text-gray-500 hover:text-gray-700'
                            }`}
                            aria-label="Monthly view"
                        >
                            <CalendarIcon className="h-6 w-6" />
                        </button>
                    </div>
                </div>
                {viewMode === 'weekly' ? (
                    <WeeklyCalendar 
                        selectedDate={selectedDate} 
                        onDateChange={handleDateChange}
                        entries={entries}
                    />
                ) : (
                    <MonthlyCalendar
                        selectedDate={selectedDate}
                        onDateChange={handleDateChange}
                        entries={entries}
                        onDayClick={handleDateChange}
                    />
                )}
                <DayCalendar 
                    entries={filteredEntries} 
                    onSelectEntry={handleSelectEntry} 
                />
            </div>
            <div className="max-w-screen-md mx-auto px-4 pb-16">
                <AddEntryButton 
                    highlight={highlightAddEntry} 
                    onClick={handleNewEntry}
                />
            </div>
            <MobileNav />
            {showForm && (
                <FormComponent 
                    initialDate={selectedDate}
                    onClose={handleFormClose}
                />
            )}
        </div>
    );
}