import React, { useMemo } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Scatter } from 'recharts';
import { format, isValid } from 'date-fns';

const SymptomTrendChart = ({ entries }) => {
  console.log('Entries received in SymptomTrendChart:', entries);

  const getTopItems = (entries, types) => {
    const counts = {};
    types.forEach(type => {
      counts[type] = {};
    });

    entries.forEach(entry => {
      if (counts[entry.type]) {
        entry.values.forEach(value => {
          counts[entry.type][value.value] = (counts[entry.type][value.value] || 0) + 1;
        });
      }
    });

    const topItems = {};
    Object.keys(counts).forEach(type => {
      topItems[type] = Object.entries(counts[type])
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(([item]) => item);
    });

    console.log('Top items:', topItems);
    return topItems;
  };

  const chartData = useMemo(() => {
    console.log('Processing entries in chartData useMemo');
    const typeColors = {
      symptom: '#9c27b0',
      foods: '#2196f3',
      activities: '#ff9800',
      medications: '#f44336',
      moods: '#4caf50'
    };
  
    const topItems = getTopItems(entries, ['symptom', 'foods', 'activities', 'medications', 'moods']);
    const data = [];
  
    entries.forEach(entry => {
      if (entry.setAt && isValid(entry.setAt)) {
        const timestamp = entry.setAt.getTime();
        const dataPoint = { timestamp };
  
        if (topItems[entry.type]) {
          entry.values.forEach(value => {
            if (topItems[entry.type].includes(value.value)) {
              dataPoint[`${entry.type}_${value.value}`] = value.amount;
            }
          });
        }
  
        // Only add the dataPoint if it has any values besides timestamp
        if (Object.keys(dataPoint).length > 1) {
          data.push(dataPoint);
        }
      }
    });
  
    console.log('Processed chartData:', data);
    return data.sort((a, b) => a.timestamp - b.timestamp);
  }, [entries]);

  const formatXAxis = (timestamp) => {
    return format(new Date(timestamp), 'MMM d, HH:mm');
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-3 border border-gray-300 rounded shadow">
          <p className="label">{formatXAxis(payload[0].payload.timestamp || label)}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const typeColors = {
    symptom: '#9c27b0',
    foods: '#2196f3',
    activities: '#ff9800',
    medications: '#f44336',
    moods: '#4caf50'
  };

  const jitter = (value, amount = 0.3) => {
    return value + (Math.random() - 0.5) * amount;
  };

  const CustomizedShape = (props) => {
    const { cx, cy, fill } = props;
    return (
      <path
        d="M -4 0 L 0 4 L 4 0 L 0 -4 Z"
        stroke={fill}
        strokeWidth={2}
        fill={fill}
        fillOpacity={0.6}
        transform={`translate(${cx}, ${cy})`}
      />
    );
  };

  const renderDataPoints = useMemo(() => {
    console.log('Rendering data points');
    if (chartData.length === 0) return [];
    const allKeys = chartData.reduce((keys, dataPoint) => {
      Object.keys(dataPoint).forEach(key => {
        if (key !== 'timestamp' && !keys.includes(key)) {
          keys.push(key);
        }
      });
      return keys;
    }, []);
    console.log('All keys:', allKeys);
    const elements = [];
  
    allKeys.forEach(key => {
      const [type, name] = key.split('_');
      console.log(`Creating element for ${type}: ${name}`);
      if (type === 'symptom') {
        elements.push(
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={typeColors[type]}
            name={`${type}: ${name}`}
            dot={true}
            activeDot={{ r: 8 }}
          />
        );
      } else {
        elements.push(
          <Scatter
            key={key}
            name={`${type}: ${name}`}
            data={chartData.filter(entry => entry[key] !== undefined).map(entry => ({
              x: jitter(entry.timestamp),
              y: jitter(entry[key]),
              value: entry[key],
              timestamp: entry.timestamp
            }))}
            fill={typeColors[type]}
          >
            <CustomizedShape color={typeColors[type]} />
          </Scatter>
        );
      }
    });
  
    console.log('Rendered elements:', elements);
    return elements;
  }, [chartData]);

  if (chartData.length === 0) {
    return <div>No data available for the chart.</div>;
  }

  return (
    <div className="h-96 w-full bg-white">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={formatXAxis} 
            tick={{ fontSize: 12 }}
            tickMargin={5}
          />
          <YAxis 
            domain={[0, 'dataMax + 1']}
            tick={{ fontSize: 12 }}
            tickMargin={5}
            width={30}
            label={{ value: 'Intensity/Amount', angle: -90, position: 'insideLeft' }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {renderDataPoints}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SymptomTrendChart;