import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../components/hooks/useAuthContext';
import useFetchUserEntries from '../components/hooks/useFetchUserEntries';
import useFetchUserQuests from '../components/hooks/useFetchUserQuests';
import BeeMessageComponent from '../components/home/BeeMessage';
import CompactEnvironmentalData from '../components/home/CompactEnvironmentalData';
import List from '../components/home/List';
import AddEntryButton from '../components/journal/AddEntryButton';
import FormComponent from '../components/form/FormComponent';
import MobileNav from '../components/MobileNav';
import Navbar from '../components/Navbar';
import Metrics from '../components/home/Metrics';

const HomeScreen = () => {
  const { user } = useAuthContext();
  const [showForm, setShowForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [beeMessage, setBeeMessage] = useState('');
  const [keyDriverSymptom, setKeyDriverSymptom] = useState('');
  const { entries, loading: entriesLoading, refetchEntries } = useFetchUserEntries(user ? user.uid : null);
  const { quests, loading: questsLoading } = useFetchUserQuests(user ? user.uid : null);

  useEffect(() => {
    if (entries.length > 0) {
      const lastDayEntries = entries.filter(entry => {
        const entryDate = new Date(entry.setAt);
        const oneDayAgo = new Date();
        oneDayAgo.setDate(oneDayAgo.getDate() - 1);
        return entryDate >= oneDayAgo && entry.type === 'symptom';
      });

      const symptomSums = {};
      lastDayEntries.forEach(entry => {
        if (entry.values && Array.isArray(entry.values)) {
          entry.values.forEach(symptom => {
            if (!symptomSums[symptom.value]) {
              symptomSums[symptom.value] = 0;
            }
            symptomSums[symptom.value] += symptom.amount;
          });
        }
      });

      const keyDriver = Object.keys(symptomSums).reduce((a, b) => symptomSums[a] > symptomSums[b] ? a : b, '');
      setKeyDriverSymptom(keyDriver);

      if (keyDriver) {
        setBeeMessage(`Looks like ${keyDriver} is your main symptom today. To clarify add detail.`);
      } else {
        setBeeMessage("No symptoms recorded recently, a clear day!");
      }
    }
  }, [entries]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (isModalOpen) {
        setShowForm(false);
        setIsModalOpen(false);
        event.preventDefault();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isModalOpen]);

  const handleNewEntry = () => {
    setShowForm(true);
    setIsModalOpen(true);
    window.history.pushState({ modal: true }, '');
  };

  const handleFormClose = async () => {
    setShowForm(false);
    setIsModalOpen(false);
    window.history.back();
    await refetchEntries();
  };

  if (entriesLoading || questsLoading) return <div>Loading...</div>;

  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <div className="hidden sm:block">
        <Navbar />
      </div>
      <div className="p-4 max-w-3xl mx-auto">
        <h1 className="text-2xl font-bold mb-4 text-left">Welcome, {user?.displayName || 'User'}!</h1>
        
        <div className="flex flex-col items-center mb-6">
          <BeeMessageComponent message={beeMessage} />
          <div className="p-2 rounded bg-gray-200 text-gray-800">
            Key Driver: {keyDriverSymptom || 'None'}
          </div>
        </div>
        <Metrics entries={entries} />

        <CompactEnvironmentalData />
        
        <List
          quests={quests || []}
          onQuestCompletion={() => {}}
          onEntryCompletion={() => {}}
        />
      </div>
      
      <div className="fixed bottom-20 right-4 sm:right-8 z-50">
        <AddEntryButton onClick={handleNewEntry} />
      </div>
      
      <MobileNav />
      
      {showForm && (
        <FormComponent
          initialDate={new Date()}
          onClose={handleFormClose}
        />
      )}
    </div>
  );
};

export default HomeScreen;