import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useAuthContext } from '../components/hooks/useAuthContext';

function FinalizeLogin() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext(); // Get dispatch from AuthContext

  useEffect(() => {
    const completeSignIn = async () => {
      console.log("FinalizeLogin useEffect triggered");

      if (isSignInWithEmailLink(auth, window.location.href)) {
        console.log("Valid sign-in link detected");
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          console.log("Email not found in localStorage, prompting user");
          email = window.prompt('Please provide your email for confirmation');
        }

        try {
          console.log("Attempting to sign in with email link");
          const result = await signInWithEmailLink(auth, email, window.location.href);
          console.log("Sign-in successful", result);
          window.localStorage.removeItem('emailForSignIn');
          dispatch({ type: 'LOGIN', payload: result.user }); // Update the context with the user
          navigate('/home');
        } catch (error) {
          console.error("Error signing in with email link", error);
          setError(error.message);
          // Instead of immediately navigating, we'll let the user see the error
          setTimeout(() => navigate('/login'), 10000);
        }
      } else {
        console.log("No valid sign-in link detected, redirecting to login");
        navigate('/');
      }
    };

    completeSignIn();
  }, [auth, navigate, dispatch]);

  if (error) {
    return <div>Error: {error}. Redirecting to login page...</div>;
  }

  return <div>Finalizing your login...</div>;
}

export default FinalizeLogin;