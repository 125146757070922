import React from 'react';
import correlationImage from '../../images/home1.png';

const CorrelationExplanation = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-slate-900 mb-8">Understanding Our Correlation Analysis</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">How We Calculate Correlations</h3>
            <p className="text-gray-600 mb-4">
            Reebee's correlation analysis helps you spot connections between your symptoms and daily life factors. 
            </p>
            <p className="text-gray-600 mb-4">
            A correlation is a representation for how strongly two things are related, like how your asthma might improve with exercise or how your eczema might flare up with certain foods. 
            </p>

            <p className="text-gray-600 mb-4">
            By tracking your symptoms, activities, and environment, Reebee calculates these relationships.
            </p>

            <p className="text-gray-600 mb-4">
            The Steps:
            </p>
            <ol className="list-decimal list-inside space-y-2 text-gray-600">
              <li>You add data on your symptoms, activities, diet, and environmental factors over time.</li>
              <li>For each pair of factors (e.g., a symptom and an activity, food ect.), we calculate how they change together.</li>
              <li>We use a statistical method called Pearson correlation to measure the strength and direction of these relationships.</li>
                  <ul className="list-disc list-inside ml-4 mt-2">
                  <li className="font-mono text-sm">r = Σ((X - X̄)(Y - Ȳ)) / √(Σ(X - X̄)² * Σ(Y - Ȳ)²)</li>
                  <li>X and Y are the daily values for a symptom and a potential trigger.</li>
                  <li>X̄ and Ȳ are the mean values over the analyzed period.</li>
                </ul>
              <li>The result is a correlation coefficient between -1 and 1, where:
                <ul className="list-disc list-inside ml-4 mt-2">
                  <li>1 indicates a strong positive correlation</li>
                  <li>0 indicates no correlation</li>
                  <li>-1 indicates a strong negative correlation</li>
                </ul>
              </li>
            </ol>
          </div>
          <div>
            <div className="mt-8 lg:mt-0">
        <img src={correlationImage} alt="Reebee App" className="w-full max-w-md mx-auto rounded-xl shadow-lg" />
      </div>
            <p className="text-gray-500 mt-4">
             Correlation does not mean causation. Our analysis is a starting point for understanding your health patterns, not a definitive diagnosis. Always consult with a healthcare professional for medical advice.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CorrelationExplanation;