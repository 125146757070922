import React, { useState } from 'react';

const AmountSlider = ({ value, onChange, valueSelector }) => {
    const [isSliding, setIsSliding] = useState(false);

    const getAmountLabel = (amount) => {
        if (valueSelector) {
            if (amount <= 2) return valueSelector["1"].name;
            if (amount <= 4) return valueSelector["2"].name;
            return valueSelector["3"].name;
        }
        
        if (amount <= 2) return "Little";
        if (amount <= 4) return "Some";
        return "Lots";
    };

    const getDescription = (amount) => {
        if (valueSelector) {
            if (amount <= 2) return valueSelector["1"].description;
            if (amount <= 4) return valueSelector["2"].description;
            return valueSelector["3"].description;
        }
        return null;
    };

    const label = getAmountLabel(value);
    const description = getDescription(value);

    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full mb-1">
                <span className="text-xs font-medium text-gray-700">{label}</span>
                <span className="text-xs font-semibold text-purple-600">{value.toFixed(1)}</span>
            </div>
            <div className="relative">
                <input
                    type="range"
                    min="1"
                    max="5"
                    step="0.1"
                    value={value}
                    onChange={(e) => onChange(Number(e.target.value))}
                    onMouseDown={() => setIsSliding(true)}
                    onMouseUp={() => setIsSliding(false)}
                    onTouchStart={() => setIsSliding(true)}
                    onTouchEnd={() => setIsSliding(false)}
                    className="w-full h-2 bg-purple-200 rounded-lg appearance-none cursor-pointer"
                    style={{
                        background: `linear-gradient(to right, #8B5CF6 0%, #8B5CF6 ${(value - 1) * 25}%, #E9D5FF ${(value - 1) * 25}%, #E9D5FF 100%)`,
                    }}
                />
                {isSliding && (
                    <div 
                        className="absolute -top-8 left-0 bg-purple-600 text-white px-2 py-1 rounded text-xs transform -translate-x-1/2"
                        style={{ left: `${(value - 1) / 4 * 100}%` }}
                    >
                        {value.toFixed(1)}
                    </div>
                )}
            </div>
            {description && (
                <p className="text-xs text-gray-600 text-center mt-1 h-8 overflow-hidden">
                    {description}
                </p>
            )}
            <style jsx>{`
                input[type="range"]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%; 
                    background: #8B5CF6;
                    cursor: pointer;
                    border: 2px solid white;
                    box-shadow: 0 0 2px rgba(0,0,0,0.3);
                }
                input[type="range"]::-moz-range-thumb {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #8B5CF6;
                    cursor: pointer;
                    border: 2px solid white;
                    box-shadow: 0 0 2px rgba(0,0,0,0.3);
                }
            `}</style>
        </div>
    );
};

export default AmountSlider;