import React from 'react';

const Quest_Active = () => (
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#F0F0F0"/>
<path d="M18.2617 7.45918C18.3218 7.32337 18.424 7.20719 18.5555 7.12543C18.687 7.04366 18.8417 7 19 7C19.1583 7 19.313 7.04366 19.4445 7.12543C19.576 7.20719 19.6782 7.32337 19.7383 7.45918L22.7553 14.1871C22.8118 14.313 22.9046 14.422 23.0236 14.5021C23.1426 14.5822 23.2831 14.6304 23.4297 14.6412L31.264 15.223C31.9724 15.2757 32.2592 16.0958 31.7197 16.5236L25.751 21.2651C25.6395 21.3536 25.5564 21.4688 25.5108 21.5981C25.4652 21.7274 25.4589 21.8659 25.4926 21.9983L27.317 29.0869C27.3537 29.2291 27.3441 29.3782 27.2894 29.5154C27.2347 29.6525 27.1374 29.7716 27.0098 29.8575C26.8822 29.9434 26.73 29.9923 26.5724 29.9981C26.4148 30.0039 26.2589 29.9662 26.1244 29.8899L19.416 26.0922C19.2907 26.0212 19.1468 25.9837 19 25.9837C18.8532 25.9837 18.7093 26.0212 18.584 26.0922L11.8756 29.8912C11.7411 29.9675 11.5852 30.0052 11.4276 29.9994C11.27 29.9937 11.1178 29.9447 10.9902 29.8588C10.8626 29.7729 10.7653 29.6538 10.7106 29.5167C10.6559 29.3795 10.6463 29.2304 10.683 29.0882L12.5074 21.9983C12.5412 21.8659 12.535 21.7274 12.4894 21.598C12.4438 21.4687 12.3606 21.3535 12.249 21.2651L6.28028 16.5236C6.16009 16.4286 6.07301 16.3027 6.03006 16.1618C5.98712 16.0208 5.99025 15.8713 6.03905 15.732C6.08785 15.5927 6.18013 15.47 6.30421 15.3794C6.42828 15.2888 6.57857 15.2344 6.73603 15.223L14.5703 14.6412C14.7169 14.6304 14.8574 14.5822 14.9764 14.5021C15.0954 14.422 15.1882 14.313 15.2447 14.1871L18.2617 7.45918Z" stroke="#835FA5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M29 22.7744L31.3978 24.5947L33.7955 26.4149" stroke="#835FA5" strokeWidth="2" strokeLinecap="round"/>
<path d="M32 20.7744L34.3978 22.5947L36.7955 24.4149" stroke="#835FA5" strokeWidth="2" strokeLinecap="round"/>
</svg>



);

export default Quest_Active;
