import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../components/hooks/useAuthContext';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import { useLogout } from '../components/hooks/useLogout';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import DataSharingManagement from '../components/profile/DataSharingManagement';

function UserProfile() {
    const { user } = useAuthContext();
    const { logout } = useLogout();
    const [emailStatus, setEmailStatus] = useState('');
    const [emailPreference, setEmailPreference] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [premiumStatus, setPremiumStatus] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setEmailPreference(userData.questEmailPreference || false);
                    setIsPremium(userData.isPremium || false);
                }
            }
        };
        fetchUserData();
    }, [user]);

    const handleEmailPreferenceChange = async () => {
        if (user) {
            const userDocRef = doc(db, 'users', user.uid);
            await updateDoc(userDocRef, {
                questEmailPreference: !emailPreference
            });
            setEmailPreference(!emailPreference);
        }
    };

    const handleSendEmail = async (emailType) => {
        setEmailStatus(`Sending ${emailType} email...`);
        const functions = getFunctions();
        let sendEmail;

        try {
            switch (emailType) {
                case 'test':
                    sendEmail = httpsCallable(functions, 'sendTestEmail');
                    break;
                case 'quest':
                    sendEmail = httpsCallable(functions, 'sendQuestEmailToUser');
                    break;
                case 'welcome':
                    sendEmail = httpsCallable(functions, 'sendWelcomeEmail');
                    break;
                default:
                    throw new Error('Invalid email type');
            }

            const result = await sendEmail();
            console.log(`${emailType} email result:`, result.data);
            setEmailStatus(`${emailType} email sent successfully!`);
        } catch (error) {
            console.error(`Error sending ${emailType} email:`, error);
            setEmailStatus(`Error: ${error.message}`);
        }
    };


    const handleUpgrade = async () => {
        try {
          const functions = getFunctions();
          const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
          
          const data = {
            successUrl: `${window.location.origin}/upgrade-success`,
            cancelUrl: `${window.location.origin}/profile`,
          };
          
          console.log('Calling createCheckoutSession with:', data);
          
          const result = await createCheckoutSession(data);
          
          console.log('Checkout session result:', result);
          
          if (result.data && result.data.url) {
            window.location.href = result.data.url;
          } else {
            console.error('Unexpected result structure:', result);
            throw new Error('No URL returned from checkout session');
          }
        } catch (error) {
          console.error('Error creating checkout session:', error);
          if (error.details) {
            console.error('Error details:', error.details);
          }
          setPremiumStatus('Error initiating upgrade. Please try again.');
        }
      };

    const checkPremiumStatus = async () => {
        setPremiumStatus('Checking status...');
        try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                setPremiumStatus('User not authenticated');
                return;
            }
    
            const idToken = await user.getIdToken();
            const response = await fetch('https://us-central1-reebe-69943.cloudfunctions.net/verifyPremiumStatus', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
    
            if (result.isPremium !== isPremium) {
                setIsPremium(result.isPremium);
                const userRef = doc(db, 'users', user.uid);
                await updateDoc(userRef, { isPremium: result.isPremium });
            }
    
            setPremiumStatus(result.isPremium ? 'Your account is Premium!' : 'Your account is not Premium.');
        } catch (error) {
            console.error('Error checking premium status:', error);
            setPremiumStatus('Error checking status. Please try again.');
        }
    };
    return (
        <div>
            <div className="hidden sm:block">
                <Navbar/>
            </div>
            <div className="container mx-auto p-4">
                <h1 className="text-2xl font-semibold text-gray-800 mb-4">User Profile</h1>
                
                {/* User Information */}
                <div className="bg-purple-100 rounded-lg p-6 shadow mb-4">
                    <h2 className="text-lg font-semibold text-gray-700">Your Information</h2>
                    <div className="mt-4">
                        <p className="text-md text-gray-600"><strong>Name:</strong> {user?.displayName || 'N/A'}</p>
                        <p className="text-md text-gray-600"><strong>Email:</strong> {user?.email || 'N/A'}</p>
                        <p className="text-md text-gray-600"><strong>Account Type:</strong> {isPremium ? 'Premium' : 'Standard'}</p>
                    </div>
                </div>

                {/* Premium Management */}
                <div className="bg-purple-100 rounded-lg p-6 shadow mb-4">
                    <h2 className="text-lg font-semibold text-gray-700">Premium Management</h2>
                    <div className="mt-4">
                        {!isPremium && (
                            <button
                                onClick={handleUpgrade}
                                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md text-sm font-medium mr-2"
                            >
                                Upgrade to Premium
                            </button>
                        )}
                        <button
                            onClick={checkPremiumStatus}
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium"
                        >
                            Check Premium Status
                        </button>
                        {premiumStatus && <p className="mt-2 text-sm text-gray-600">{premiumStatus}</p>}
                    </div>
                </div>

                {/* Notifications */}
                <div className="bg-purple-100 rounded-lg p-6 shadow mb-4">
                    <h2 className="text-lg font-semibold text-gray-700">Notifications</h2>
                    <div className="mt-4 flex items-center">
                        <label htmlFor="questEmailToggle" className="mr-3">Receive Quest Email Reminders:</label>
                        <input
                            id="questEmailToggle"
                            type="checkbox"
                            checked={emailPreference}
                            onChange={handleEmailPreferenceChange}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                    </div>
                </div>


                {/* DataSharingManagement */}
                <div className="bg-purple-100 rounded-lg p-6 shadow mb-4">
                    <h2 className="text-lg font-semibold text-gray-700">Data Sharing Management</h2>
                    <div className="mt-4 flex items-center">
                    <DataSharingManagement userId={user.uid} />

                    </div>
                </div>

                {/* Test Email Functionality */}
                <div className="bg-purple-100 rounded-lg p-6 shadow mb-4">
                    <h2 className="text-lg font-semibold text-gray-700">Test Email Functionality</h2>
                    <div className="flex flex-col space-y-2 mt-2">
                        <button
                            onClick={() => handleSendEmail('test')}
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium"
                        >
                            Send Test Email
                        </button>
                        <button
                            onClick={() => handleSendEmail('quest')}
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md text-sm font-medium"
                        >
                            Send Quest Email
                        </button>
                        <button
                            onClick={() => handleSendEmail('welcome')}
                            className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-md text-sm font-medium"
                        >
                            Send Welcome Email
                        </button>
                    </div>
                    {emailStatus && <p className="mt-2 text-sm text-gray-600">{emailStatus}</p>}
                </div>

                {/* Logout */}
                <div className="mt-4 mb-20">
                    <button
                        onClick={logout}
                        className="bg-red-600 hover:bg-red-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                        Logout
                    </button>
                </div>
            </div>
            <MobileNav />
        </div>
    );
}

export default UserProfile;