import React, { useState, useEffect } from 'react';
import { Sun, Cloud, CloudRain, Wind, Droplets, MapPin, Edit2, X } from 'lucide-react';
import { useAuthContext } from '../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { collection, addDoc, query, orderBy, limit, getDocs } from 'firebase/firestore';
import useGeolocation from '../hooks/useGeolocation';
import useFetchWeatherData from '../hooks/useFetchWeatherData';
import useFetchAQIData from '../hooks/useFetchAQIData';
import useFetchPollenData from '../hooks/useFetchPollenData';

const CompactEnvironmentalData = () => {
  const { user } = useAuthContext();
  const [expanded, setExpanded] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [showZipForm, setShowZipForm] = useState(false);
  const [manualLocation, setManualLocation] = useState(null);
  const [locationName, setLocationName] = useState('');
  const { location, error: geoError, isFetching: geoFetching } = useGeolocation();
  const [currentLocation, setCurrentLocation] = useState(null);

  const { weatherData, loading: weatherLoading, error: weatherError } = useFetchWeatherData(currentLocation?.latitude, currentLocation?.longitude);
  const { aqiData, loading: aqiLoading, error: aqiError } = useFetchAQIData(currentLocation?.latitude, currentLocation?.longitude);
  const { pollenData, loading: pollenLoading, error: pollenError } = useFetchPollenData(currentLocation?.latitude, currentLocation?.longitude);

  useEffect(() => {
    const initializeLocation = async () => {
      if (location) {
        await setAndStoreLocation(location);
      } else if (!geoFetching && (geoError || !location)) {
        const lastLocation = await getLastStoredLocation();
        if (lastLocation) {
          setCurrentLocation(lastLocation);
        } else {
          setShowZipForm(true);
        }
      }
    };

    initializeLocation();
  }, [location, geoError, geoFetching, user]);

  useEffect(() => {
    if (currentLocation) {
      fetchLocationName(currentLocation.latitude, currentLocation.longitude);
    }
  }, [currentLocation]);

  const setAndStoreLocation = async (locationData) => {
    setCurrentLocation(locationData);
    if (user) {
      try {
        await addDoc(collection(db, `users/${user.uid}/locations`), {
          ...locationData,
          timestamp: new Date()
        });
      } catch (error) {
        console.error("Error storing location:", error);
      }
    }
  };

  const getLastStoredLocation = async () => {
    if (!user) return null;
    try {
      const locationsRef = collection(db, `users/${user.uid}/locations`);
      const q = query(locationsRef, orderBy('timestamp', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const lastLocation = querySnapshot.docs[0].data();
        return { latitude: lastLocation.latitude, longitude: lastLocation.longitude };
      }
    } catch (error) {
      console.error("Error fetching last location:", error);
    }
    return null;
  };

  const fetchLocationName = async (lat, lon) => {
    try {
      const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`);
      const data = await response.json();
      const city = data.city || data.locality || '';
      const state = data.principalSubdivision || '';
      setLocationName(`${city}${state ? `, ${state}` : ''}`);
    } catch (error) {
      console.error('Error fetching location name:', error);
      setLocationName('Unknown Location');
    }
  };

  const handleZipCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
      if (!response.ok) throw new Error('Invalid zipcode');
      const data = await response.json();
      const newLocation = {
        latitude: parseFloat(data.places[0].latitude),
        longitude: parseFloat(data.places[0].longitude)
      };
      await setAndStoreLocation(newLocation);
      setShowZipForm(false);
    } catch (error) {
      console.error('Error setting manual location:', error);
      alert('Invalid ZIP code. Please try again.');
    }
  };

  const handleCancel = () => {
    setShowZipForm(false);
    setZipCode('');
  };

  const WeatherIcon = ({ condition }) => {
    switch (condition?.toLowerCase()) {
      case 'clear': return <Sun className="w-6 h-6" />;
      case 'clouds': return <Cloud className="w-6 h-6" />;
      case 'rain': return <CloudRain className="w-6 h-6" />;
      default: return <Wind className="w-6 h-6" />;
    }
  };

  const renderZipForm = () => (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <p className="mb-2">Please enter your ZIP code to get weather information:</p>
      <form onSubmit={handleZipCodeSubmit} className="flex items-center">
        <input
          type="text"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          placeholder="Enter ZIP code"
          className="p-2 border rounded mr-2 flex-grow"
        />
        <button type="submit" className="bg-blue-500 text-white p-2 rounded mr-2">Submit</button>
        <button type="button" onClick={handleCancel} className="bg-gray-300 text-gray-700 p-2 rounded">
          <X className="w-4 h-4" />
        </button>
      </form>
    </div>
  );

  if (geoFetching) {
    return <div className="bg-white p-4 rounded-lg shadow mb-4">Acquiring location...</div>;
  }

  if (showZipForm) {
    return renderZipForm();
  }

  if (!currentLocation) {
    return null; // Don't render anything while waiting for location
  }

  if (weatherLoading || aqiLoading || pollenLoading) {
    return <div className="bg-white p-4 rounded-lg shadow mb-4">Loading environmental data...</div>;
  }

  if (weatherError || aqiError || pollenError) {
    console.error('Error loading data:', weatherError || aqiError || pollenError);
    return <div className="bg-white p-4 rounded-lg shadow mb-4">Error loading environmental data. Please try again later.</div>;
  }

  const temp = weatherData?.main?.temp;
  const condition = weatherData?.weather?.[0]?.main;
  const aqi = aqiData?.indexes?.find(index => index.code === 'uaqi')?.aqi || 'N/A';
  const pollenLevels = pollenData?.dailyInfo?.[0]?.pollenTypeInfo || [];

  return (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <MapPin className="w-4 h-4 mr-2" />
          <span className="text-sm">{locationName}</span>
        </div>
        <button onClick={() => setShowZipForm(true)} className="text-blue-500">
          <Edit2 className="w-4 h-4" />
        </button>
      </div>
      <div className="flex justify-between items-center" onClick={() => setExpanded(!expanded)}>
        <div className="flex items-center">
          <WeatherIcon condition={condition} />
          <span className="ml-2 text-lg font-semibold">
            {temp ? `${Math.round(temp)}°C` : 'N/A'}
          </span>
        </div>
        <div>
          <span className="text-sm">AQI: {aqi}</span>
        </div>
      </div>
      {expanded && (
        <div className="mt-4">
          <h3 className="font-semibold mb-2">Weather Details</h3>
          <p>Condition: {condition || 'N/A'}</p>
          <p>Humidity: {weatherData?.main?.humidity || 'N/A'}%</p>
          <p>Wind Speed: {weatherData?.wind?.speed || 'N/A'} m/s</p>
          
          <h3 className="font-semibold mt-4 mb-2">Pollen Levels</h3>
          {pollenLevels.map((pollen, index) => (
            <div key={index} className="flex items-center mb-1">
              <Droplets className="w-4 h-4 mr-2" />
              <span>{pollen.displayName}: {pollen.indexInfo?.category || 'N/A'}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompactEnvironmentalData;