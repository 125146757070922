import React, { useState, useEffect, useRef } from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { useAuthContext } from '../hooks/useAuthContext';
import useFetchUserQuests from '../hooks/useFetchUserQuests';
import useFetchUserCustomSymptoms from '../hooks/useFetchUserCustomSymptoms';
import { db } from '../../firebase/config';
import { collection, addDoc } from 'firebase/firestore';
import SymptomEntry from './symptom/SymptomEntry';
import SomethingComponent from './SomethingComponent';
import StepCard from './StepCard';
import { useNavigate } from 'react-router-dom';

const ExplanationScreen = () => {
  const { user } = useAuthContext();
  const { quests, loading: questsLoading } = useFetchUserQuests(user?.uid);
  const { customSymptoms, setCustomSymptoms } = useFetchUserCustomSymptoms();
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [goal, setGoal] = useState('Identify my triggers');
  const [something, setSomething] = useState({ type: '', value: '', modification: 'Add' });
  const [duration, setDuration] = useState({ value: 1, unit: 'week' });
  const [hasCreatedQuest, setHasCreatedQuest] = useState(false);

  const [presetGoals] = useState([
    "Identify my triggers",
    "Track a possible trigger",
    "Monitor a therapeutic approach"
  ]);

  const getStepThreeTitle = () => {
    switch (goal) {
      case 'Track a possible trigger':
        return 'What is your potential trigger?';
      case 'Monitor a therapeutic approach':
        return 'What is your approach?';
      default:
        return 'Observe';
    }
  };
  
  const getStepThreeDescription = () => {
    switch (goal) {
      case 'Identify my triggers':
        return "Observe your current routine without making changes. This helps establish a baseline for your symptoms.";
      case 'Track a possible trigger':
        return "Select something that you suspect may trigger or amplify your reaction. This could be a food, activity, or environmental factor.";
      case 'Monitor a therapeutic approach':
        return "Choose one thing you do that helps calm your reaction or manage your symptoms. This could be a relaxation technique, a medication/supplement, or an activity.";
      default:
        return "Select something to track or modify in your routine.";
    }
  };

  const symptomRef = useRef(null);
  const goalRef = useRef(null);
  const somethingRef = useRef(null);
  const durationRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!questsLoading && quests.length > 0) {
      setHasCreatedQuest(true);
    }
  }, [quests, questsLoading]);
  
  useEffect(() => {
    if (goal === 'Identify my triggers') {
      setSomething({ type: '', value: '', modification: 'None' });
    }
  }, [goal]);

  const handleCreateQuest = async () => {
    if (!user) return;

    const questData = {
      active: true,
      createdAt: new Date(),
      goal,
      modification: something.modification,
      selectedSymptoms,
      selectedSystem,
      something: {
        type: something.type,
        value: something.value
      },
      duration: duration,
      userId: user.uid
    };

    try {
      const docRef = await addDoc(collection(db, `users/${user.uid}/quests`), questData);
      setHasCreatedQuest(true);
      // Reset the form
      setSelectedSymptoms([]);
      setSelectedSystem(null);
      setGoal('Identify my triggers');
      setSomething({ type: '', value: '', modification: 'Add' });
      setDuration({ value: 1, unit: 'week' });
      
      // Redirect to the newly created quest page with a query parameter
      navigate(`/quests?newQuest=${docRef.id}`);
    } catch (error) {
      console.error('Error creating quest:', error);
    }
  };


  const getGoalButtonClass = (isSelected) => {
    return `w-full py-2 px-4 rounded-md text-left transition-colors mb-2 ${
      isSelected 
        ? 'bg-purple-300 text-black border-2 border-purple-700' 
        : 'bg-white text-gray-800 border border-gray-300 hover:bg-gray-100'
    }`;
  };

  const handleGoalChange = (newGoal) => {
    setGoal(newGoal);
    if (newGoal === 'Identify my triggers') {
      setSomething({ type: '', value: '', modification: 'None' });
    }
  };

  const isModificationLocked = goal === 'Identify my triggers';

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const isQuestValid = () => {
    if (selectedSymptoms.length === 0 || !selectedSystem) return false;
    if (goal !== 'Identify my triggers' && (!something.value || !something.type)) return false;
    if (duration.value <= 0) return false;
    return true;
  };

  if (questsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <div className="flex-grow p-4">
        <div className="mb-6">
          <h1 className="text-2xl font-bold mb-2">Create Your Quest</h1>
          <p className="text-gray-600">Follow these steps to create a personalized quest for your immune system wellness.</p>
        </div>

        <StepCard
          number="1"
          title="What are your symptoms?"
          description="Select the one system and respective symptoms you most want to resolve."
          completed={selectedSymptoms.length > 0 && selectedSystem}
        >
          <div ref={symptomRef}>
            <SymptomEntry 
              customSymptoms={customSymptoms}
              selectedSystem={selectedSystem}
              setSelectedSystem={setSelectedSystem}
              selectedSymptoms={selectedSymptoms}
              setSelectedSymptoms={setSelectedSymptoms}
            />
          </div>
        </StepCard>

        <StepCard
          number="2"
          title="What is your goal?"
          description="Choose what you want to achieve with this quest."
          completed={goal !== ''}
        >
          <div ref={goalRef} className="space-y-2">
            {presetGoals.map((presetGoal) => (
              <button
                key={presetGoal}
                onClick={() => handleGoalChange(presetGoal)}
                className={getGoalButtonClass(goal === presetGoal)}
              >
                {presetGoal}
              </button>
            ))}
            {/* {hasCreatedQuest && (
              <input
                type="text"
                value={goal !== presetGoals[0] ? goal : ''}
                onChange={(e) => handleGoalChange(e.target.value)}
                placeholder="Or enter a custom goal..."
                className="w-full p-2 border rounded-md"
              />
            )} */}
          </div>
        </StepCard>

        <StepCard
            number="3"
            title={getStepThreeTitle()}
            description={getStepThreeDescription()}
            completed={something.value !== '' && !isModificationLocked}
            locked={isModificationLocked}
          >
          <div ref={somethingRef}>
            <SomethingComponent 
              something={something} 
              setSomething={setSomething} 
              isLocked={isModificationLocked}
              goal={goal}
              includesMoods={goal === 'Identify my triggers' || goal === 'Track a possible trigger'}
            />
          </div>
        </StepCard>



        <StepCard
          number="4"
          title="Set Duration"
          description="How long do you want to track this quest?"
          completed={duration.value > 0}
        >
          <div ref={durationRef} className="flex items-center space-x-2">
            <input
              type="number"
              value={duration.value}
              onChange={(e) => setDuration({ ...duration, value: parseInt(e.target.value) })}
              className="w-20 p-2 border rounded-md"
              min="1"
            />
            <select
              value={duration.unit}
              onChange={(e) => setDuration({ ...duration, unit: e.target.value })}
              className="p-2 border rounded-md"
            >
              <option value="day">Day(s)</option>
              <option value="week">Week(s)</option>
              <option value="month">Month(s)</option>
            </select>
          </div>
        </StepCard>

        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
          <div className="flex items-center mb-2">
            <SparklesIcon className="h-6 w-6 text-purple-500 mr-2" />
            <h3 className="text-lg font-semibold">
              Your Quest: <span className="text-purple-600">{goal}</span>{' '}
              {something.value ? `(${something.value})` : ''} for{' '}
              <span 
                className="text-purple-600 cursor-pointer hover:underline"
                onClick={() => scrollToRef(symptomRef)}
              >
                {selectedSymptoms.length > 0 ? selectedSymptoms.join(', ') : '[symptoms]'}
              </span>
              {' '}for{' '}
              <span 
                className="text-purple-600 cursor-pointer hover:underline"
                onClick={() => scrollToRef(durationRef)}
              >
                {duration.value} {duration.unit}(s)
              </span>
            </h3>
          </div>
          <p className="text-gray-600">
            Reebee will guide you through each step and help you track important factors like pollen, humidity, and more!
          </p>
        </div>

        <div className="mt-6 flex justify-center">
          <button 
            className="bg-purple-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-600 transition-colors disabled:opacity-100 disabled:cursor-not-allowed"
            onClick={handleCreateQuest}
            disabled={!isQuestValid()}
          >
            Create Quest
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExplanationScreen;