import React from 'react';
import EntryCard from './EntryCard';
import QuestCard from './QuestCard';

const getCurrentDayIndex = (schedule) => {
  const todayStr = new Date().toISOString().split('T')[0];
  return schedule.findIndex(day => day.date === todayStr);
};

const List = ({ quests, onQuestCompletion, onEntryCompletion, hasCompletedEntry }) => {
  return (
    <div className="flex flex-col pb-2 bg-white p-6">
      <h3 className="text-lg font-semibold text-gray-700 mb-4">Today's Steps</h3>
     
      <div className="space-y-4">
        <div className="mb-8">
          <EntryCard isCompleted={hasCompletedEntry} onEntryCompletion={onEntryCompletion} />
        </div>
        
        {quests.map((quest) => (
          <div key={quest.id} className="relative flex items-center w-full bg-white border rounded-lg shadow-sm p-2">
            <QuestCard
              quest={quest}
              dayIndex={getCurrentDayIndex(quest.schedule)}
              onQuestCompletion={onQuestCompletion}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default List;